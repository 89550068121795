import type * as React from 'react'
import type { EarnedItemStaticProperties } from './EarnedItem.types'
import EarnedItemPoint from './EarnedItemPoint'

const EarnedItem: React.FC & EarnedItemStaticProperties = () => {
    return null
}

EarnedItem.Point = EarnedItemPoint

export default EarnedItem
