import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderLocation, Row } from '@components'
import type { DeliveryLocationSectionProps } from './DeliverySummary.types'
import { getOrderLocationProps } from './DeliverySummary.utils'

const DeliveryLocationSection: React.FC<DeliveryLocationSectionProps> = ({
    userAddress,
    branch,
    leaveOrderAtDoor,
    ringDoorbell,
    deliveryType,
    company,
    onChangeState,
}) => {
    const { t } = useTranslation()

    return (
        <Row className="space-y-3">
            <Row.Title
                title={t(
                    '@order.DeliverySummary.delivery_method_section_title',
                )}
            />

            <OrderLocation
                {...getOrderLocationProps({
                    userAddress,
                    branch,
                    leaveOrderAtDoor,
                    ringDoorbell,
                    deliveryType,
                    company,
                    onChangeState,
                })}
            />
        </Row>
    )
}
export default DeliveryLocationSection
