import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { HistoryItem } from '@components'
import type { OrderRefundSectionItemProps } from './OrderDetail.types'
import OrderRefundSectionItemInfo from './OrderRefundSectionItemInfo'

const OrderRefundSectionItem: React.FC<OrderRefundSectionItemProps> = ({
    status,
    referenceCode,
    amount,
    currency,
    date,
    reason,
    userComment,
    companyComment,
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-3">
            <HistoryItem
                type={status === 'refund' ? 'refund' : 'approve'}
                subtitle={`${t(
                    '@wallet.AccountActivityDetail.reference_number',
                )}:${referenceCode}`}
                amount={amount}
                currency={currency}
                datetime={dayjs(date).toDate()}
                className="px-0 hover:bg-transparent"
                contentStyle={{ border: 'none' }}
            />
            <div className="rounded-[20px] bg-semantic-background-primary p-4">
                <OrderRefundSectionItemInfo
                    title={`${t(
                        '@wallet.AccountActivityDetail.refund_reason',
                    )}: `}
                    comment={reason}
                />
                {userComment ? (
                    <OrderRefundSectionItemInfo
                        title={`${t(
                            '@wallet.AccountActivityDetail.customer_comment',
                        )}: `}
                        comment={userComment}
                    />
                ) : null}
                {companyComment ? (
                    <OrderRefundSectionItemInfo
                        title={`${t(
                            '@wallet.AccountActivityDetail.company_comment',
                        )}: `}
                        comment={companyComment}
                    />
                ) : null}
            </div>
        </div>
    )
}
export default OrderRefundSectionItem
