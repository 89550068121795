import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge } from '@components'
import i18n from '@core/translations'
import type { OrderItemProps } from './OrderItem.types'
import { cn } from '@core/utils'

const OrderItem: React.FC<OrderItemProps> = ({
    image,
    title,
    price,
    currency,
    amount,
    className,
    ...props
}) => {
    const formattedPrice = React.useMemo(() => {
        return CurrencyFormatter.format(price, i18n.getLocale(), currency)
    }, [currency, price])

    return (
        <div
            className={cn(
                'flex w-full items-center justify-between gap-4',
                className,
            )}
            {...props}>
            <div className="flex items-center gap-4">
                <img
                    src={image}
                    alt={title}
                    className="h-[66px] w-12 shrink-0 rounded-[9px] object-cover"
                />
                <div className="space-y-1">
                    <h3 className="text-body-lg-b text-semantic-content-ink">
                        {title}
                    </h3>
                    <p className="text-body-md-r text-semantic-content-ink">
                        {formattedPrice}
                    </p>
                </div>
            </div>

            <Badge
                as="div"
                variant="ink-inverse"
                size="medium"
                className="text-body-md-b"
                children={amount}
            />
        </div>
    )
}
export default OrderItem
