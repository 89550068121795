import type { RouteObject } from 'react-router'
import { DeliverySummary, MyOrders, Products } from '@order/views'

export const OrderRouter: RouteObject[] = [
    {
        path: '/my-orders',
        element: <MyOrders />,
    },
    {
        path: '/products',
        element: <Products />,
    },
    {
        path: '/delivery-summary',
        element: <DeliverySummary />,
    },
]
