import * as React from 'react'
import { Map } from '@components'
import type { OrderLocationMapProps } from './OrderLocation.types'
import { cn } from '@core/utils'

const OrderLocationMap: React.FC<OrderLocationMapProps> = ({
    latitude,
    longitude,
    showMap,
    className,
    ...props
}) => {
    const marker = React.useMemo(() => {
        return {
            position: [latitude, longitude],
        }
    }, [latitude, longitude])

    if (!showMap || !latitude || !longitude) return null

    return (
        <Map
            center={[latitude, longitude]}
            markers={[marker] as never}
            preview
            className={cn('h-[104px] w-full', className)}
            {...props}
        />
    )
}
export default OrderLocationMap
