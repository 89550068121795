import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Toast, type ToastType } from '@components'
import baseApi, { type QueryTagTypes } from '@core/api'
import { useAppDispatch } from '@core/store'
import { useTopUp } from '@deposit/hooks'
import { MasterPassOTP } from '@deposit/views'
import type { DepositToastProps } from './DeliverySummary.types'
import { emitter } from '@core/utils'

const DepositToast: React.FC<DepositToastProps> = ({
    walletId,
    depositAmount,
    params,
    ...props
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [topUpSuccess, setTopUpSuccess] = React.useState<boolean>(false)

    const handleCompletePurchase = React.useCallback(
        (success: boolean) => {
            setTopUpSuccess(success)
            if (!success) return

            dispatch(baseApi.util.invalidateTags(['WalletDetail']))
            dispatch(baseApi.util.invalidateTags(['OrderSummary']))

            emitter.emit('TOPUP_UPDATE', [
                'Wallets',
                'BalanceHistoryList',
                'DepositCampaignDetail',
            ] as QueryTagTypes)
        },
        [dispatch],
    )

    const topUp = useTopUp(
        {
            walletId: walletId,
            amount: depositAmount,
            installmentCount: null,
            redirect: {
                path: '/delivery-summary',
                ...params,
            },
        },
        handleCompletePurchase,
    )

    const ToastRightComponent = React.useCallback(
        (type: ToastType) => {
            if (type !== 'error') return <React.Fragment />
            return (
                <Toast.Action
                    variant={type}
                    loading={topUp.loading}
                    children={t('@payment.Capture.toast_chip_label')}
                    onClick={topUp.init}
                />
            )
        },
        [t, topUp.init, topUp.loading],
    )

    if (!topUpSuccess && depositAmount === 0) return null

    return (
        <React.Fragment>
            <Toast
                as="div"
                variant={
                    topUpSuccess && depositAmount === 0 ? 'success' : 'error'
                }
                icon={
                    topUpSuccess && depositAmount === 0
                        ? 'check-circle'
                        : 'warning'
                }
                message={
                    topUpSuccess
                        ? t('@order.DeliverySummary.toast_success_message')
                        : t('@order.DeliverySummary.toast_error_message')
                }
                LayoutRightComponent={ToastRightComponent}
                {...props}
            />
            <MasterPassOTP update={handleCompletePurchase} />
        </React.Fragment>
    )
}

export default DepositToast
