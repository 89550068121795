import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, WalletCard } from '@components'
import type { WalletSectionProps } from './DeliverySummary.types'
import { useNotification } from '@core/hooks'

const WalletSection: React.FC<WalletSectionProps> = ({
    name,
    logo,
    currency,
    balance,
    color,
    points,
    value,
    onChangeValue,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()

    const [disabled, setDisabled] = React.useState(false)

    const handleRedeemPointsChange = React.useCallback(
        (currentValue: boolean) => {
            if (points <= 0) {
                toast({
                    variant: 'suggestion',
                    icon: 'warning',
                    message: t(
                        '@order.DeliverySummary.toast_point_usage_sufficient_message',
                    ),
                    duration: 5000,
                })
                setDisabled(true)
                return
            }

            onChangeValue({ type: 'SET_USING_POINTS', payload: currentValue })
        },
        [onChangeValue, points, t, toast],
    )

    return (
        <Row className="space-y-3">
            <Row.Title
                title={t('@order.DeliverySummary.wallet_section_title')}
            />

            <WalletCard.Redeem
                title={name}
                logo={logo}
                currency={currency}
                points={points}
                amount={balance}
                color={color}
                showPoints
                redeemPoints={value}
                pointSwitchProps={{ disabled }}
                onRedeemPointsChange={handleRedeemPointsChange}
            />
        </Row>
    )
}
export default WalletSection
