import * as React from 'react'
import { useNavigate } from 'react-router'
import { find } from 'lodash'
import { MasterPassSDK } from '@macellan/masterpass-sdk'
import type { RedirectProps } from '@core/context'
import i18n from '@core/translations'
import depositApi, { type CardType } from '@deposit/api'
import { DepositMethodsService, MasterPassService } from '@deposit/services'
import walletApi from '@wallet/api'
import { useNotification } from '@core/hooks'
import { emitter } from '@core/utils'

export interface UseTopUpData {
    walletId: number
    amount: number
    installmentCount: number | null
    redirect?: RedirectProps
}

const useTopUp = (
    data: UseTopUpData,
    // eslint-disable-next-line no-unused-vars
    onComplete: (success: boolean, depositMethod: CardType) => void,
) => {
    const navigate = useNavigate()
    const { toast } = useNotification()

    const [depositMethod, setDepositMethod] = React.useState<CardType>()

    const [loading, setLoading] = React.useState<boolean>(false)

    const cardListQuery = depositApi.useUserCardListQuery(undefined)
    const walletDetailsQuery = walletApi.useWalletDetailsQuery({
        wallet_id: data.walletId,
    })

    const depositMethods = React.useMemo(() => {
        return walletDetailsQuery.data?.data.company.deposit_methods
    }, [walletDetailsQuery.data?.data.company.deposit_methods])

    const handleLastDepositMethod = React.useCallback(() => {
        if (!depositMethods) return

        DepositMethodsService.getLastDepositMethod(
            data.walletId,
            depositMethods,
        ).then(result => {
            setDepositMethod(result as CardType)
        })
    }, [data.walletId, depositMethods])

    React.useEffect(() => {
        handleLastDepositMethod()
    }, [handleLastDepositMethod])

    const selectedCard = React.useMemo(() => {
        if (!cardListQuery.data?.data || !depositMethod) return

        return DepositMethodsService.getSelectedCard(
            cardListQuery.data?.data,
            depositMethod,
        )
    }, [cardListQuery.data?.data, depositMethod])

    const handleMasterPassPurchase = React.useCallback(async () => {
        try {
            if (!selectedCard)
                return toast({
                    variant: 'error',
                    icon: 'warning',
                    message: i18n.t('hooks.useTopUp.message'),
                    duration: 5000,
                })

            const mpCardList = await MasterPassService.listCards()

            const card = find(
                mpCardList,
                mpCard => mpCard.Name === selectedCard.name,
            )

            if (!card) return

            const purchaseResult = await MasterPassService.purchase({
                walletId: data.walletId,
                amount: data.amount,
                card: card,
                installmentCount: data.installmentCount,
            })

            if (
                purchaseResult.sdkResult.responseCode ===
                    MasterPassSDK.Constants.ResponseCodes.SUCCESS ||
                purchaseResult.sdkResult.responseCode ===
                    MasterPassSDK.Constants.ResponseCodes.SUCCESS_EMPTY
            ) {
                const mpMakeDepositResult = await MasterPassService.makeDeposit(
                    {
                        depositId: purchaseResult.initResult.data.deposit_id,
                        token: purchaseResult.sdkResult.token,
                    },
                )

                onComplete(true, 'masterpass')
                return mpMakeDepositResult
            }

            if (
                purchaseResult.sdkResult.responseCode ===
                MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP
            ) {
                await MasterPassService.markOTP(
                    purchaseResult.initResult.data.deposit_id,
                )

                emitter.emit('MASTERPASS_OTP', {
                    redirect: data?.redirect,
                    otpType: 'purchase',
                    depositId: purchaseResult.initResult.data.deposit_id,
                })
            }

            if (
                purchaseResult.sdkResult.responseCode ===
                MasterPassSDK.Constants.ResponseCodes.VALIDATE_3D_SECURE
            ) {
                navigate('/masterpass-3d', {
                    state: {
                        redirect: data?.redirect,
                        depositId: purchaseResult.initResult.data.deposit_id,
                        threeDUrl:
                            purchaseResult.sdkResult.url3D +
                            '&returnUrl=' +
                            purchaseResult.initResult.data.callback_url,
                    },
                })
            }
        } catch (
            // eslint-disable-next-line
            error: any
        ) {
            toast({
                variant: 'error',
                icon: 'warning',
                message: error.data.message,
                duration: 5000,
            })
        }
    }, [
        selectedCard,
        toast,
        data.walletId,
        data.amount,
        data.installmentCount,
        data?.redirect,
        onComplete,
        navigate,
    ])

    const handleInit = React.useCallback(async () => {
        setLoading(true)

        try {
            if (depositMethod === 'masterpass')
                return await handleMasterPassPurchase()
        } catch (error) {
            onComplete(false, depositMethod!)
            return Promise.reject(error)
        } finally {
            setLoading(false)
        }
    }, [handleMasterPassPurchase, onComplete, depositMethod])

    return {
        loading: loading,
        init: handleInit,
    }
}

export default useTopUp
