import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { MasterPassSDK } from '@macellan/masterpass-sdk'
import { Button, CheckBox, Input, Sheet } from '@components'
import { useAppSelector } from '@core/store'
import { MasterPassService, type MPRegisterData } from '@deposit/services'
import type { MasterPassCardRegisterProps } from './MasterPassCardRegister.types'
import { useNotification } from '@core/hooks'
import { emitter, MaskUtils } from '@core/utils'
import { DepositMethodsConstants } from '@deposit/constants'
import { mpRegisterValidationSchema } from './MasterPassCardRegister.validations'

const MasterPassCardRegister: React.FC<MasterPassCardRegisterProps> = ({
    setOpen,
    setView,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()

    const { mpRegisterLoading } = useAppSelector(state => state.depositSlice)

    const handleSubmit = React.useCallback(
        async (values: MPRegisterData) => {
            const rtaPanRawValue = MaskUtils.getRawValue(
                'credit-card',
                values.rtaPan,
            )

            const expiryDateRawValue = MaskUtils.getRawValue(
                'date-time',
                values.expiryDate,
                'MM/YY',
            )

            const expiryYear = expiryDateRawValue
                .getFullYear()
                .toString()
                .slice(2, 4)

            const expiryMonth = (
                '0' + (expiryDateRawValue.getMonth() + 1).toString()
            ).slice(-2)

            const expiryDate = expiryYear + expiryMonth

            await MasterPassService.register({
                rtaPan: rtaPanRawValue,
                expiryDate: expiryDate,
                accountAliasName: values.accountAliasName,
            })
                .then(result => {
                    if (
                        result.responseCode ===
                        MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP
                    ) {
                        emitter.emit('MASTERPASS_OTP', {
                            otpType: 'register',
                        })

                        setOpen(false)
                    }
                })
                .catch(error => {
                    toast({
                        variant: 'error',
                        icon: 'warning',
                        message: error.data.message,
                        duration: 5000,
                    })
                })
        },
        [setOpen, toast],
    )

    React.useEffect(() => {
        return () => {
            setView('cards')
        }
    }, [setView])

    const formik = useFormik({
        initialValues: {
            rtaPan: '',
            expiryDate: '',
            accountAliasName: '',
            agreement: false,
        },
        validationSchema: mpRegisterValidationSchema,
        onSubmit: values => handleSubmit(values),
    })

    return (
        <React.Fragment>
            <Sheet.Header>
                <Sheet.Title>
                    {t('@deposit.MasterPassCardRegister.content_header_title')}
                </Sheet.Title>
                <Sheet.Description>
                    {t(
                        '@deposit.MasterPassCardRegister.content_header_description',
                    )}
                </Sheet.Description>
            </Sheet.Header>
            <form
                onSubmit={formik.handleSubmit}
                noValidate
                className="flex flex-col gap-6">
                <div className="space-y-8">
                    <div className="space-y-6">
                        <Input.Mask
                            label={t(
                                '@deposit.MasterPassCardRegister.form_label_card_number',
                            )}
                            error={
                                formik.touched.rtaPan && !!formik.errors.rtaPan
                            }
                            helperText={
                                formik.touched.rtaPan
                                    ? formik.errors.rtaPan
                                    : undefined
                            }
                            maskType="credit-card"
                            placeholder="•••• •••• •••• ••••"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={formik.values.rtaPan}
                            onChange={formik.handleChange('rtaPan')}
                        />
                        <Input.Mask
                            label={t(
                                '@deposit.MasterPassCardRegister.form_label_expiry_date',
                            )}
                            error={
                                formik.touched.expiryDate &&
                                !!formik.errors.expiryDate
                            }
                            helperText={
                                formik.touched.expiryDate
                                    ? formik.errors.expiryDate
                                    : undefined
                            }
                            placeholder={t(
                                '@deposit.MasterPassCardRegister.form_placeholder_expiry_date',
                            )}
                            maskType="date-time"
                            maskOptions={{ dateTimePattern: 'MM/YY' }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={formik.values.expiryDate}
                            onBlur={formik.handleBlur('expiryDate')}
                            onChange={formik.handleChange('expiryDate')}
                        />
                        <Input
                            label={t(
                                '@deposit.MasterPassCardRegister.form_label_account_alias_name',
                            )}
                            error={
                                formik.touched.accountAliasName &&
                                !!formik.errors.accountAliasName
                            }
                            helperText={
                                formik.touched.accountAliasName
                                    ? formik.errors.accountAliasName
                                    : undefined
                            }
                            type="text"
                            autoCapitalize="words"
                            placeholder={t(
                                '@deposit.MasterPassCardRegister.form_placeholder_account_alias_name',
                            )}
                            value={formik.values.accountAliasName}
                            onBlur={formik.handleBlur('accountAliasName')}
                            onChange={formik.handleChange('accountAliasName')}
                        />
                    </div>
                    <CheckBox
                        checked={formik.values.agreement}
                        onChange={() =>
                            formik.setFieldValue(
                                'agreement',
                                !formik.values.agreement,
                            )
                        }>
                        <Trans
                            i18nKey={
                                '@deposit.MasterPassCardRegister.form_agreement_checkbox_title'
                            }
                            components={[
                                <a
                                    key="link"
                                    className="text-link-sm text-semantic-content-link underline"
                                    target="_blank"
                                    href={t(
                                        '@deposit.MasterPassCardRegister.mp_user_agreement_url',
                                    )}
                                />,
                            ]}
                        />
                    </CheckBox>
                </div>

                <img
                    src={DepositMethodsConstants.Logos.masterpass}
                    className="w-[104px] self-center object-cover"
                    alt="MasterPass"
                />

                <Button
                    type="submit"
                    className="w-full"
                    children={t(
                        '@deposit.MasterPassCardRegister.form_submit_button',
                    )}
                    loading={mpRegisterLoading}
                    disabled={!formik.isValid}
                    onClick={() => formik.handleSubmit()}
                />
            </form>
        </React.Fragment>
    )
}
export default MasterPassCardRegister
