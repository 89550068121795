import * as React from 'react'
import { map } from 'lodash'
import { Icon } from '@components'
import type { OrderStatusMultipleProps } from './OrderStatus.types'
import { getOrderStatuses } from './OrderStatus.utils'
import OrderStatusSingle from './OrderStatusSingle'

const OrderStatusMultiple: React.FC<OrderStatusMultipleProps> = ({
    deliveryType,
    status,
}) => {
    const orderStatuses = React.useMemo(
        () => getOrderStatuses(deliveryType),
        [deliveryType],
    )

    const statusIndex = React.useMemo(
        () => orderStatuses.indexOf(status),
        [orderStatuses, status],
    )

    const getVariant = React.useCallback(
        (itemIndex: number) => {
            if (itemIndex === statusIndex) return 'active'
            if (itemIndex < statusIndex) return 'idle'
            return 'disabled'
        },
        [statusIndex],
    )

    return (
        <div className="flex items-center justify-around gap-[6px]">
            {map(orderStatuses, (orderStatus, index) => (
                <React.Fragment key={index}>
                    <OrderStatusSingle
                        customVariant={getVariant(index)}
                        status={orderStatus}
                    />

                    {index < orderStatuses.length - 1 && (
                        <Icon
                            name="dot"
                            color="var(--background-quaternary)"
                            size={12}
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}
export default OrderStatusMultiple
