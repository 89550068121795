import * as React from 'react'
import { Badge } from '@components'
import type { OrderStatusBadgeProps } from './OrderStatus.types'
import {
    getOrderStatusBadgeProperties,
    getOrderStatusLabel,
} from './OrderStatus.utils'
import { useTheme } from '@core/utils'

const OrderStatusBadge: React.FC<OrderStatusBadgeProps> = ({
    status,
    ...props
}) => {
    const theme = useTheme().colors

    const badgeProps = React.useMemo(
        () => getOrderStatusBadgeProperties(status, theme),
        [status, theme],
    )

    return (
        <Badge
            children={getOrderStatusLabel(status)}
            {...badgeProps}
            {...props}
        />
    )
}

OrderStatusBadge.displayName = 'OrderStatus.Badge'

export default OrderStatusBadge
