import * as React from 'react'
import type { OrderLocationProps } from './OrderLocation.types'
import { cn } from '@core/utils'
import OrderLocationMap from './OrderLocationMap'

const OrderLocation: React.FC<OrderLocationProps> = ({
    title,
    description,
    longitude,
    latitude,
    showMap,
    FooterComponentProps,
    FooterComponent,
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'rounded-[20px] bg-semantic-background-primary',
                className,
            )}
            {...props}>
            <div className="space-y-1 px-4 py-6">
                <h3 className="text-body-md-b text-semantic-content-ink">
                    {title}
                </h3>
                <p className="text-body-sm-r text-semantic-content-inkMedium">
                    {description}
                </p>
            </div>

            <OrderLocationMap
                longitude={longitude}
                latitude={latitude}
                showMap={showMap}
                className={cn(!FooterComponent && '!rounded-b-[20px]')}
            />

            {FooterComponent && (
                <div
                    {...FooterComponentProps}
                    className={cn(
                        'px-4 py-5 text-body-md-r text-semantic-content-ink',
                        FooterComponentProps?.className,
                    )}>
                    {FooterComponent}
                </div>
            )}
        </div>
    )
}
export default OrderLocation
