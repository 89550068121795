import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Sheet } from '@components'
import type { OrderDetailHeaderProps } from './OrderDetail.types'

const OrderDetailHeader: React.FC<OrderDetailHeaderProps> = ({
    stepper,
    onStepperChange,
}) => {
    const { t } = useTranslation()

    return (
        <Sheet.Header>
            <div className="flex items-center gap-2">
                {stepper === 'refund' && (
                    <IconButton
                        variant="ink-tonal"
                        name="arrow-left-bold"
                        size="small"
                        onClick={() => onStepperChange('details')}
                    />
                )}
                <Sheet.Title>
                    {stepper === 'details'
                        ? t('@order.OrderDetail.route_header_title')
                        : t('@order.OrderRefund.route_header_title')}
                </Sheet.Title>
            </div>
            <Sheet.Description>
                {t('@order.OrderDetail.route_header_description')}
            </Sheet.Description>
        </Sheet.Header>
    )
}
export default OrderDetailHeader
