import { Button, ModuleItem } from '@components'
import { useAppSelector } from '@core/store'
import type { WalletModuleItem, WalletModuleParams } from '@wallet/hooks'
import { useWindowSize } from '@core/hooks'

interface ModuleButtonProps {
    module: WalletModuleItem
    params: WalletModuleParams
}

export const ModuleButton = ({
    module,
    params,
    ...props
}: ModuleButtonProps) => {
    const ScreenWidth = useWindowSize().width

    const company = useAppSelector(state => state.baseSlice.company)

    if (ScreenWidth < 1024) {
        return (
            <ModuleItem.Button
                children={module.title}
                variant={params.index === 0 ? 'primary' : 'secondary'}
                icon={module.icon}
                onClick={module.callback}
                disabled={company.is_wallet_frozen}
                {...props}
            />
        )
    }

    return (
        <Button
            children={module.title}
            variant="ink-inverse"
            leftIcon={module.icon}
            className="pl-4 pr-6"
            onClick={module.callback}
            disabled={company.is_wallet_frozen}
            {...props}
        />
    )
}
