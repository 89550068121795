import baseApi from '@core/api'
import type {
    AddressClosestData,
    AddressClosestResponse,
    AddressCreateData,
    AddressCreateResponse,
    AddressDeleteData,
    AddressDeleteResponse,
    AddressListResponse,
} from './UserApi.types'

const userApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        addressClosest: builder.query<
            AddressClosestResponse,
            AddressClosestData
        >({
            query: data => ({
                url: '/user/address/closest',
                method: 'GET',
                params: data,
            }),
        }),
        addressList: builder.query<AddressListResponse, void>({
            query: () => ({
                url: '/user/address/list',
                method: 'GET',
            }),
            providesTags: ['AddressList'],
        }),
        addressCreate: builder.mutation<
            AddressCreateResponse,
            AddressCreateData
        >({
            query: data => ({
                url: '/user/address/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) => (error ? [] : ['AddressList']),
        }),
        addressDelete: builder.mutation<
            AddressDeleteResponse,
            AddressDeleteData
        >({
            query: data => ({
                url: `/user/address/delete/${data.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error) => (error ? [] : ['AddressList']),
        }),
    }),
    overrideExisting: true,
})

export default userApi
