import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Sheet } from '@components'
import type { DeliveryOptionHeaderProps } from './DeliveryOption.types'
import { cn } from '@core/utils'

const DeliveryOptionHeader: React.FC<DeliveryOptionHeaderProps> = ({
    stepper,
    onStepperChange,
}) => {
    const { t } = useTranslation()

    const title = React.useMemo(() => {
        if (stepper === 'address')
            return t('@order.OrderLocation.address_list_section_title')

        if (stepper === 'branch_map')
            return t('@order.OrderBranchMap.route_header_title')

        return t('@order.DeliveryOption.bottom_sheet_section_bar_title')
    }, [stepper, t])

    const description = React.useMemo(() => {
        if (stepper === 'address')
            return t(
                '@order.OrderLocation.address_list_section_bar_description',
            )

        if (stepper === 'branch_map')
            return t('@order.OrderBranchMap.route_header_description')

        return t('@order.DeliveryOption.bottom_sheet_section_bar_description')
    }, [stepper, t])

    return (
        <Sheet.Header
            className={cn(stepper === 'branch_map' && '!px-6 sm:px-10')}>
            <div className="flex items-center gap-2">
                {stepper !== 'option' && (
                    <IconButton
                        variant="ink-tonal"
                        name="arrow-left-bold"
                        size="small"
                        onClick={() => onStepperChange('option')}
                    />
                )}
                <Sheet.Title children={title} />
            </div>
            <Sheet.Description children={description} />
        </Sheet.Header>
    )
}
export default DeliveryOptionHeader
