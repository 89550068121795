import { createApi } from '@reduxjs/toolkit/query/react'
import type {
    AppInfoResponse,
    InitializeData,
    InitializeResponse,
    LocationCitiesResponse,
    LocationDistrictsData,
    LocationDistrictsResponse,
    LocationNeighborhoodsData,
    LocationNeighborhoodsResponse,
    ReverseLocationData,
    ReverseLocationResponse,
    UserMeResponse,
} from './BaseApi.types'
import BaseQuery from './BaseQuery'

const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: BaseQuery,
    tagTypes: [
        'AddressList',
        'Me',
        'WalletDetail',
        'BalanceHistoryList',
        'BalanceHistoryDetail',
        'DepositCampaignDetail',
        'CardList',
        'GiftCardMySent',
        'OrderHistory',
        'OrderHistoryDetail',
        'OrderSummary',
    ],
    endpoints: builder => ({
        appInfo: builder.query<AppInfoResponse, void>({
            query: () => ({
                url: 'waas/app-info',
                method: 'GET',
            }),
        }),
        initialize: builder.query<InitializeResponse, InitializeData>({
            query: data => {
                localStorage.clear()
                return {
                    url: `waas/token/validate/${data.token}`,
                    method: 'GET',
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(baseApi.endpoints.appInfo.initiate())
            },
        }),
        me: builder.query<UserMeResponse, void>({
            query: () => {
                return {
                    url: '/user/me',
                    method: 'GET',
                }
            },
            providesTags: ['Me'],
        }),
        reverseLocation: builder.query<
            ReverseLocationResponse,
            ReverseLocationData
        >({
            query: data => ({
                url: '/services/location/reverse-location',
                method: 'GET',
                params: data,
            }),
        }),
        locationCities: builder.query<LocationCitiesResponse, void>({
            query: () => ({
                url: '/services/location/cities',
                method: 'GET',
            }),
        }),
        locationDistricts: builder.query<
            LocationDistrictsResponse,
            LocationDistrictsData
        >({
            query: data => ({
                url: '/services/location/districts',
                method: 'GET',
                params: data,
            }),
        }),
        locationNeighborhoods: builder.query<
            LocationNeighborhoodsResponse,
            LocationNeighborhoodsData
        >({
            query: data => ({
                url: '/services/location/neighborhoods',
                method: 'GET',
                params: data,
            }),
        }),
    }),
})

export default baseApi
