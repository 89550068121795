import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import type {
    OrderStatusProps,
    OrderStatusStaticProperties,
} from './OrderStatus.types'
import {
    getOrderStatusLabel,
    getOrderStatusProperties,
} from './OrderStatus.utils'
import { cn, useTheme } from '@core/utils'
import OrderStatusBadge from './OrderStatusBadge'
import OrderStatusMultiple from './OrderStatusMultiple'
import OrderStatusSingle from './OrderStatusSingle'

const OrderStatus: React.FC<OrderStatusProps> & OrderStatusStaticProperties = ({
    deliveryType,
    status,
    buttonProps,
    className,
    ...props
}) => {
    const { t } = useTranslation()
    const theme = useTheme().colors

    const getOrderStatusLabelColor = React.useMemo(() => {
        return getOrderStatusProperties(status, theme).backgroundColor
    }, [status, theme])

    return (
        <div
            className={cn(
                'space-y-6 rounded-[20px] bg-semantic-background-primary p-6',
                className,
            )}
            {...props}>
            <OrderStatusMultiple deliveryType={deliveryType} status={status} />
            <div
                className="text-center !text-body-md-b"
                style={{ color: getOrderStatusLabelColor }}>
                {getOrderStatusLabel(status)}
            </div>
            {status === 'order_received' ? (
                <Button
                    variant="ink"
                    className="w-full"
                    children={t('content_config.OrderStatus.cancel_order')}
                    {...buttonProps}
                />
            ) : null}
        </div>
    )
}

OrderStatus.Badge = OrderStatusBadge
OrderStatus.Multiple = OrderStatusMultiple
OrderStatus.Single = OrderStatusSingle

export default OrderStatus
