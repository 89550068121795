import baseApi from '@core/api'
import type {
    DetailsData,
    DetailsResponse,
    MapBranchData,
    MapBranchResponse,
    MapListData,
    MapListResponse,
    WalletSubscribeData,
    WalletSubscribeResponse,
} from './CompanyApi.types'

const companyApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        details: builder.query<DetailsResponse, DetailsData>({
            query: data => ({
                url: `/company/${data.company_id}/details`,
                method: 'GET',
            }),
        }),
        walletSubscribe: builder.mutation<
            WalletSubscribeResponse,
            WalletSubscribeData
        >({
            query: data => ({
                url: '/wallet/subscribe',
                method: 'POST',
                body: data,
            }),
        }),
        mapBranch: builder.query<MapBranchResponse, MapBranchData>({
            query: data => ({
                url: `/company/map/branch/${data.id}`,
                method: 'GET',
            }),
        }),
        mapList: builder.query<MapListResponse, MapListData>({
            query: data => ({
                url: '/company/map/list',
                method: 'GET',
                params: data,
            }),
            serializeQueryArgs: ({
                endpointName,
                queryArgs: {
                    // eslint-disable-next-line
                    page,
                    ...queryArgs
                },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
    }),
    overrideExisting: true,
})

export default companyApi
