export default {
    CouponApply: {
        route_header_title: 'Kupon Ekle',
        content_header_description:
            'Kupon kodunu aşağı girerek puan kazanabilirsin',
        coupon_add_button: 'Kupon Ekle',
        coupon_toast_message:
            '{{amount}} puan değerindeki kupon başarıyla tanımlandı',
        coupon_input_placeholder: 'Kupon Kodu Giriniz',
    },
    AccountActivitySectionList: {
        empty_sheet_title: 'Hesap Haraketi Yok',
        empty_sheet_description: 'Herhangi bir hesap hareketi bulunamadı',
    },
    WalletDetail: {
        header_title: 'BAKİYE',
        header_point: 'Puan: {{point}}',
        help: 'Yardım',
        help_description: 'Bir sorun mu var?',
        read_qr: 'QR Öde',
        menu_earnings_title: 'Kazançlarım',
        menu_earnings_subtitle: "Macellan SuperApp'in bütçene katkısı",
        menu_brand_title: 'Marka Hakkında',
        menu_brand_subtitle: 'Menü, kampanyalar, şubeler ve daha fazlası',
        wallet_history_title: 'Cüzdan Hareketleri',
        wallet_history_subtitle: 'Gerçekleştirilen son 5 işlem',
        wallet_history_all: 'Tüm Hareketler',
        agreement_updated:
            '{{wallet}} cüzdanına ait sözleşme koşulları güncellenmiştir',
        passive_status_toast_message: '{{wallet}} cüzdanı pasife alınmıştır',
        wallet_benefits_title: 'Cüzdan avantajları nelerdir?',
        orders_title: 'Siparişler',
        orders_subtitle: 'Son verdiğin siparişlerini görüntüle',
        orders_badge_label_all: 'Tüm Siparişler',
        feature_title: 'Özellikler',
        settings_title: 'Ayarlar',
    },
    WalletModules: {
        route_header_title: 'Tüm İşlemler',
        payment: 'Ödeme Yap',
        deposit: 'Bakiye Yükle',
        coupon: 'Kupon Ekle',
        wallet_share: 'Cüzdan Paylaş',
        gift_card: 'Hediye Kartı',
        ticket_system: 'Biletlerim',
        branches: 'Şubeler',
        history: 'Hesap Hareketleri',
        help: 'Yardım',
        agreement: 'Cüzdan Sözleşmesi',
        wallet_visibility: 'Cüzdan Görünürlüğü',
        products: 'Ürünler',
    },
    AccountActivities: {
        content_header_title: 'Hesap Hareketleri',
        content_header_description:
            'Tüm hesap hareketlerine buradan kolayca ulaşabilirsin',
        search_field_placeholder: 'Filtrele',
    },
    AccountActivitiesFilter: {
        content_header_title: 'Hareketleri Filtrele',
        content_header_description:
            'Gerekli filtrelemeleri yap ve sonuçlara kolayca ulaş',
        categories: 'KATEGORİLER',
        filters: 'FİLTRELER',
        date: 'TARİH ARALIĞI',
        started_date: 'Başlangıç Tarihi',
        end_date: 'Bitiş Tarihi',
        submit: 'Sonuçları Göster',
        balance: 'Bakiye',
        refund: 'İade',
        payment_refund: 'Harcama İadesi',
        deposit_refund: 'Yükleme İadesi',
        cash_deposit_refund: 'Nakit Yükleme İadesi',
        capture: 'Bakiye Harcama',
        top_up: 'Bakiye Yükleme',
        date_time_place_holder: 'Örn. 07/07/2023',
    },
    AccountActivityDetail: {
        content_header_title: 'İşlem Detayı',
        content_header_description:
            'Tüm işlem detaylarına buradan kolayca ulaşabilirsin',
        refund_reason: 'İade Nedeni',
        customer_comment: 'Müşteri Açıklaması',
        company_comment: 'İşletme Açıklaması',
        reference_number: 'Talep No',
        refund_button_title: 'İade İste',
        branch_name: 'Şube Adı',
        cashier: 'Kasiyer',
        total_earned_points: 'Toplam Kazanılan Puan',
        point_group: 'Puan Grubu',
        earned_points: 'Kazanılan Puan',
        earned_wallet: 'Kazanılan Cüzdan',
        top_up: 'Bakiye Yükleme',
        capture: 'Bakiye Harcama',
        process_type: 'İşlem Tipi',
        spent_amount: 'Harcama Miktarı',
        payment_point: 'Ödeme Noktası',
        order_id: 'Sipariş Numarası',
        date: 'Tarih',
        discount_amount: 'İndirim Tutarı',
        amount_after_discount: 'İndirim Sonrası Tutar',
        spent_point: 'Harcanan Puan',
        spent_balance: 'Harcanan Bakiye',
        wallet_type: 'Cüzdan Tipi',
        shared_wallet: 'Paylaşılan Cüzdan',
        sharer: 'Paylaşan Kişi',
        shared: 'Paylaşılan  Kişi',
        expire_date: 'Bitiş Tarihi',
        description: 'Açıklama',
        gift_card: 'Hediye Kartı',
        undefined_user: 'Tanımlanamayan Kullanıcı',
        loaded_amount: 'Yükleme Miktarı',
        bank_amount: 'Karta İade Edilen Miktar',
        point_deducted_balance: 'Bakiyeden Düşülen Puan',
        total_revoked_point: 'İptal Edilen Puan',
        reference_code: 'Referans Kod',
        loaded_type: 'Yükleme Tipi',
        cash: 'Nakit Yükleme',
        dcb: 'Faturama Yansıt',
        card_number: 'Kart Numarası',
        payment_refund: 'Harcama İadesi',
        deposit_refund: 'Yükleme İadesi',
        cash_deposit_refund: 'Nakit Yükleme İadesi',
        refunded_amount: 'İade Edilen Tutar',
        refunded_point: 'İade Edilen Puan',
        transaction_details: 'İşlem Bilgileri',
        branch_details: 'Firma Bilgileri',
        additional_details: 'Detay Bilgiler',
        soft_play_details: 'Soft Play Bilgileri',
        bank_transfer: 'Banka Transferi',
        payment_refund_history: 'HARCAMA GEÇMİŞİ',
        payment_refund_history_spent_amount: 'Harcama Miktarı',
        soft_play_child: 'Çocuk',
        soft_play_duration: 'Süre',
        soft_play_price: 'Ücret',
        installment_count: 'Taksit Sayısı',
        total_installment_amount: 'Toplam Taksitli Tutar',
    },
    GiftCard: {
        route_header_title: 'Hediye Kartı',
        content_header_title: 'Hediye kartı gönderimi nasıl çalışır?',
    },
    GiftCardDetail: {
        section_bar_title: 'Paylaşım Detayı',
        section_bar_description:
            'Tüm işlem detaylarına buradan kolayca ulaşabilirsin',
        cancel_gift_card: 'İptal Et',
        detail_section_title: 'İŞLEM BİLGİLERİ',
        detail_section_name_surname: 'Ad Soyad',
        detail_section_anonymous: 'Bilinmeyen Kişi',
        detail_section_phone_number: 'Telefon Numarası',
        detail_section_deposit: 'Paylaşılan Bakiye',
        detail_section_limitless: 'Limitsiz',
        detail_section_description: 'Açıklama',
        detail_section_expire_date: 'Bitiş Tarihi',
        gift_card_cancel_toast_message: 'Hediye kartın iptal edildi',
        gift_card_cancel_alert_title: 'Hediye Kartı İptali',
        gift_card_cancel_alert_message:
            'Hediye kartını iptal etmek\nistiyor musun?',
        gift_card_cancel_alert_approval_button: 'İptal Et',
        gift_card_cancel_alert_reject_button: 'Vazgeç',
    },
    GiftCardMake: {
        route_header_title: 'Paylaş',
        alert_title: 'İşlem Bilgisi',
        balance_alert_message:
            'Hediye Kartı paylaşımı için belirlediğin toplam tutar, kullanılabilir cüzdan bakiyen üzerinde olamaz',
        balance_alert_top_up_button: 'Bakiye Yükle',
        balance_alert_back_button: 'Yeni Tutar Belirle',
        submit_alert_title: 'Hediye Kartı Gönder',
        submit_alert_message:
            'Gönderilen hediye kartına ait puanlar süresi dolduğunda harcanmazsa hesabına geri yüklenmez',
        submit_alert_approve_button: 'Gönder',
        submit_alert_approve_hidden_button: 'Gönder ve Bir Daha Gösterme',
        submit_alert_reject_button: 'Vazgeç',
        toast_success_message: 'Hediye kartı başarılıyla gönderildi',
        form_wallet_field_section_title: 'Cüzdan Seçimi',
        form_wallet_field_placeholder: 'Cüzdan Seç',
        form_balance_section_title: 'Paylaşılacak Bakiye Tutarı',
        form_date_picker_section_title: 'Son Paylaşım Tarihi',
        form_date_picker_placeholder: 'Tarih Seç',
        form_description_section_title: 'Açıklama',
        form_description_placeholder: 'Örn. İyi ki doğdun',
        form_person_field_section_title: 'Paylaşılacak Kişiler',
        form_person_field_footer: 'Kişi Ekle',
        form_person_field_unknown_user: 'Bilinmeyen Kişi',
        form_submit_button: 'Hediye Kartı Gönder',
        validation_phones_min: 'Paylaşılacak kişi sayısı en az 1 olmalıdır',
        click_to_add_person: 'Kişiyi eklemek için tıkla',
        add_person_description:
            'Hediye Kartı göndermek istediğin kişinin numarasını yaz',
        directory: 'REHBER',
    },
    GiftCardMySent: {
        route_header_title: 'Paylaştıklarım',
        empty_sheet_title: 'Paylaşım Bulunamadı',
        empty_sheet_description: 'Paylaştığın bir hediye kartı bulunamadı',
    },
    GiftCardSentToMe: {
        route_header_title: 'Benimle Paylaşılanlar',
        empty_sheet_title: 'Paylaşım Bulunamadı',
        empty_sheet_description:
            'Seninle paylaşılan bir hediye kartı bulunamadı',
    },
    GiftCardHistoryTabs: {
        route_header_title: 'Paylaşım Geçmişi',
    },
    GiftCardMySentHistory: {
        route_header_title: 'Paylaştıklarım',
        empty_sheet_title: 'Paylaşım Bulunamadı',
        empty_sheet_description: 'Paylaştığın bir hediye kartı bulunamadı',
    },
    GiftCardSentToMeHistory: {
        route_header_title: 'Benimle Paylaşılanlar',
        empty_sheet_title: 'Paylaşım Bulunamadı',
        empty_sheet_description:
            'Seninle paylaşılan bir hediye kartı bulunamadı',
    },
    GiftCardHistoryDetail: {
        section_bar_title: 'Paylaşım Detayı',
        section_bar_description:
            'Tüm işlem detaylarına buradan kolayca ulaşabilirsin',
        detail_section_title: 'İŞLEM BİLGİLERİ',
        detail_section_name_surname: 'Ad Soyad',
        detail_section_anonymous: 'Bilinmeyen Kişi',
        detail_section_phone_number: 'Telefon Numarası',
        detail_section_deposit: 'Paylaşılan Bakiye',
        detail_section_limitless: 'Limitsiz',
        detail_section_description: 'Açıklama',
        detail_section_status: 'Durum',
        detail_section_status_expired: 'Süresi Doldu',
        detail_section_status_cancel: 'İptal Edildi',
        detail_section_status_finished: 'Tamamlandı',
        detail_section_expire_date: 'Bitiş Tarihi',
    },
    GiftCardInfo: {
        info_first_description:
            'Dijital Hediye Kartı ile istediğin cüzdan için sevdiklerine puan gönderimi yapabilirsin. Aynı anda birden fazla kişiye hediye kartı gönderimi yapabilirsin.\nDijital Hediye Kartı gönderimi yapıldığı anda belirlediğin tutar bakiyenden düşecektir.',
        info_second_description:
            'Gönderim yapılan kişi 1 saat içerisinde herhangi bir harcama yapmazsa paylaşımı iptal edebilirsin.\nBelirlemiş olduğun paylaşım süresi sona erdiğinde eğer karşı tarafta puan kaldıysa o puanlar silinecektir ve kalan bakiye senin hesabına geri yüklenecektir.',
        footer_button_continue: 'Devam',
        footer_button_close: 'Kapat',
    },
    Earned: {
        route_header_title: 'Kazançlarım',
        right_info_title: 'Kazançlarım',
        right_info_description:
            'Kazançlarım sayfasında, şimdiye kadar kazandığınız tüm puanların geçmiş ve güncel detaylarını görüntüleyebilirsiniz. Puan kazanım türü (Kupon, Kampanya, Arkadaşını Davet vb.), son kullanım tarihi ve güncel durumu gibi bilgilere bu ekrandan ulaşabilirsiniz.',
        points_section_title: 'Puanlarım',
        points_section_balance_title: 'Kullanılabilir Puan',
        points_section_amount_title: 'Kazanılan Puan',
        points_section_spent_title: 'Harcanan Puan',
        gift_card_title: 'Hediye Kartı',
        empty_sheet_title: 'Kazancın Yok',
        empty_sheet_description:
            'Harcalamarında uygulamayı kullanarak\n daha fazla kazanç elde edebilirsin',
        earned_all: 'Tüm Kazançlarım',
    },
    EarnedPoints: {
        route_header_title: 'Puanlarım',
        list_header_title: 'KAZANÇ GEÇMİŞİM',
    },
}
