import * as React from 'react'
import type { GetDeliveryEventsFunction } from './DeliveryOption.types'
import { emitter } from '@core/utils'

export const useDeliveryEvents: GetDeliveryEventsFunction = (
    onOpenChange,
    onStepperChange,
) => {
    React.useEffect(
        () => {
            emitter.on('OPEN_DELIVERY_OPTION', () => onOpenChange(true))
            emitter.on('OPEN_ORDER_LOCATION', () => onStepperChange('address'))
            emitter.on('OPEN_BRANCH_MAP', () => onStepperChange('branch_map'))
            emitter.on('CLOSE_DELIVERY_OPTION', () => onOpenChange(false))
            emitter.on('OPEN_ORDER_LOCATION', () => {
                onStepperChange('address')
                onOpenChange(true)
            })
        },
        // eslint-disable-next-line
        [],
    )
}
