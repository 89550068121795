import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import type { OrderRefundSectionProps } from './OrderDetail.types'
import OrderRefundSectionItem from './OrderRefundSectionItem'

const OrderRefundSection: React.FC<OrderRefundSectionProps> = ({
    refundRequests,
    currency,
}) => {
    const { t } = useTranslation()

    if (!refundRequests.length) return null

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.refund_detail_section_title')}
            </h3>
            {map(refundRequests, item => (
                <OrderRefundSectionItem
                    key={item.id}
                    status={item.status}
                    amount={item.amount}
                    currency={currency}
                    date={item.updated_at}
                    referenceCode={item.reference_code}
                    reason={item.reason_translate}
                    companyComment={item.company_comment}
                    userComment={item.user_comment}
                />
            ))}
        </div>
    )
}
export default OrderRefundSection
