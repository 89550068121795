import * as React from 'react'
import { Icon } from '@components'
import type { InputProps, InputStaticProperties } from './Input.types'
import { cn } from '@core/utils'
import InputMask from './InputMask'
import './Input.css'

const Input: React.FC<InputProps> & InputStaticProperties = (
    {
        leftIconProps,
        leftIcon,
        rightIconProps,
        rightIcon,
        label,
        labelLeftIcon,
        labelRightIcon,
        helperText,
        error,
        LayoutRightComponent,
        labelClassName,
        inputClassName,
        className,
        containerProps,
        InputContainerAs,
        onBlur,
        ...props
    },
    ref,
) => {
    const [focused, setFocused] = React.useState<boolean>(false)
    const InputContainerComp = InputContainerAs || 'label'

    return (
        <div
            data-error={error}
            data-focused={focused}
            data-disabled={props.disabled}
            className={cn('form-input__container animate-enter', className)}
            {...containerProps}>
            {label && (
                <label htmlFor={props.id} className="form-input__label">
                    {labelLeftIcon && <Icon name={labelLeftIcon} size={16} />}
                    <span className="text-label-md">{label}</span>
                    {labelRightIcon && <Icon name={labelRightIcon} size={16} />}
                </label>
            )}
            <InputContainerComp
                htmlFor={props.id}
                className={cn('form-input__input-container', labelClassName)}>
                {leftIcon && (
                    <Icon name={leftIcon} size={24} {...leftIconProps} />
                )}
                <input
                    ref={ref}
                    type="text"
                    className={cn(inputClassName)}
                    {...props}
                    onFocus={() => setFocused(true)}
                    onBlur={e => {
                        setFocused(false)
                        onBlur && onBlur(e)
                    }}
                />
                {!LayoutRightComponent && rightIcon && (
                    <Icon name={rightIcon} size={24} {...rightIconProps} />
                )}
                {LayoutRightComponent && LayoutRightComponent}
            </InputContainerComp>
            {helperText && (
                <span className="form-input__helper">{helperText}</span>
            )}
        </div>
    )
}

Input.Mask = InputMask

export default Input
