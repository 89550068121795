import * as React from 'react'
import type { WalletCardStaticProperties } from './WalletCard.types'
import WalletCardPaying from './WalletCardPaying'
import WalletCardPayingLoader from './WalletCardPayingLoader'
import WalletCardRedeem from './WalletCardRedeem'

const WalletCard: React.FC & WalletCardStaticProperties = () => {
    return <React.Fragment>WalletCard</React.Fragment>
}

WalletCard.Paying = WalletCardPaying
WalletCard.PayingLoader = WalletCardPayingLoader
WalletCard.Redeem = WalletCardRedeem

export default WalletCard
