import type { Order, OrderHistoryProduct } from '@order/api'

export const prepareOrderImages = (products: OrderHistoryProduct[]) => {
    return products.map(product => product.product_img)
}

export const getAddressTitle = (order: Order) => {
    return order.user_address?.title ?? order.branch?.name
}

export const getFullAddress = (order: Order) => {
    return order.user_address?.full_address ?? order.branch?.full_address
}

export default {
    prepareOrderImages: prepareOrderImages,
    getAddressTitle: getAddressTitle,
    getFullAddress: getFullAddress,
}
