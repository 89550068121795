import * as React from 'react'
import type { AddressDropdownFieldsItemProps } from './AddressDropdownFieldsItem.types'
import { cn } from '@core/utils'

const AddressDropdownFieldsItem: React.FC<AddressDropdownFieldsItemProps> = ({
    className,
    onOpenChange,
    onClick,
    ...props
}) => {
    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            onClick?.(e)
            onOpenChange(false)
        },
        [onClick, onOpenChange],
    )

    return (
        <button
            type="button"
            className={cn(
                'w-full rounded-[13px] bg-semantic-background-primary p-4 text-left text-body-lg-r transition-colors duration-300 hover:cursor-pointer hover:bg-semantic-background-secondary',
                className,
            )}
            onClick={handleClick}
            {...props}
        />
    )
}
export default AddressDropdownFieldsItem
