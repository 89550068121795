import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { AddressItem } from '@components'
import orderApi from '@order/api'
import userApi, { type Address } from '@user/api'
import { useGeolocation, useNotification } from '@core/hooks'
import { emitter } from '@core/utils'

export const useOrderLocationHandler = (
    walletId: number,
    companyId: number,
) => {
    const t = useTranslation().t
    const navigate = useNavigate()

    const { alert } = useNotification()
    const { location: currentCoords } = useGeolocation()

    const [addressClosestQuery] = userApi.useLazyAddressClosestQuery()
    const [orderMenuQuery, orderMenuResult] = orderApi.useLazyOrderMenuQuery()

    const [selectedAddress, setSelectedAddress] = React.useState<Address>()

    const getClosestAddress = React.useCallback(
        async (lat: number, lng: number): Promise<Address> => {
            try {
                const addressClosestResult = await addressClosestQuery({
                    'location[lat]': lat,
                    'location[lng]': lng,
                    'wallet_id': walletId,
                }).unwrap()

                return addressClosestResult.data
            } catch (e: unknown) {
                return Promise.reject(e)
            }
        },
        [addressClosestQuery, walletId],
    )

    const handleEmitter = React.useCallback(async (): Promise<void> => {
        emitter.emit('CLOSE_DELIVERY_OPTION')
        emitter.emit('CLOSE_WALLET_MODULE')
    }, [])

    const handleNavigateProducts = React.useCallback(
        async (address: Address | null) => {
            if (!address) return

            try {
                const result = await orderMenuQuery({
                    wallet_id: walletId,
                    address_id: address.id,
                    delivery_type: 'address',
                }).unwrap()

                navigate('/products', {
                    state: {
                        uri: result.data.url,
                        branchId: result.data.branch_id,
                        companyId: companyId,
                        walletId: walletId,
                        addressId: address.id,
                        deliveryType: 'address',
                    },
                })
            } catch (e: unknown) {
                const error = e as Error

                if ('status' in error) {
                    if (error.status !== 404) return
                }

                await handleEmitter()

                alert({
                    title: t('@order.OrderLocation.alert_error_title'),
                    message: t('@order.OrderLocation.alert_error_message'),
                    actions: [
                        {
                            children: t(
                                '@order.OrderLocation.alert_error_action_button',
                            ),
                            onClick: () => emitter.emit('OPEN_ORDER_LOCATION'),
                        },
                        {
                            variant: 'secondary',
                            children: t(
                                '@order.OrderLocation.alert_error_action_reject_button',
                            ),
                        },
                    ],
                })
            }
        },
        [
            alert,
            companyId,
            handleEmitter,
            navigate,
            orderMenuQuery,
            t,
            walletId,
        ],
    )

    const handleNavigateClosestAddress = React.useCallback(
        async (closestAddress: Address) => {
            if (!selectedAddress) return

            await handleEmitter()

            return alert({
                icon: 'map-pin',
                iconColor: 'var(--content-ink)',
                title: t('@user.AddressClosest.info_sheet_title'),
                message: t('@user.AddressClosest.info_sheet_description', {
                    address: closestAddress.title,
                }),
                ContentComponent: (
                    <AddressItem
                        title={closestAddress.title}
                        description={closestAddress.full_address}
                    />
                ),
                actions: [
                    {
                        children: t(
                            '@user.AddressClosest.info_sheet_accept_button',
                        ),
                        onClick: () => handleNavigateProducts(closestAddress),
                    },
                    {
                        variant: 'secondary',
                        children: t(
                            '@order.OrderLocation.alert_error_action_reject_button',
                        ),
                        onClick: () =>
                            debounce(
                                () => handleNavigateProducts(selectedAddress),
                                100,
                            )(),
                    },
                ],
            })
        },
        [alert, handleEmitter, handleNavigateProducts, selectedAddress, t],
    )

    const handle = React.useCallback(async () => {
        if (!selectedAddress) return

        if (!currentCoords) {
            handleNavigateProducts(selectedAddress)
            return
        }

        const closestAddress = await getClosestAddress(
            currentCoords[0],
            currentCoords[1],
        )

        if (closestAddress && selectedAddress.id !== closestAddress.id) {
            handleNavigateClosestAddress(closestAddress)
            return
        }

        handleNavigateProducts(selectedAddress)
    }, [
        getClosestAddress,
        currentCoords,
        handleNavigateClosestAddress,
        handleNavigateProducts,
        selectedAddress,
    ])

    return {
        handle: handle,
        orderMenuResult: orderMenuResult,
        selected: selectedAddress,
        setSelected: setSelectedAddress,
    }
}
