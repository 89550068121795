import { reduce } from 'lodash'
import i18n from '@core/translations'
import type { Product } from '@order/api'
import type {
    GetOrderLocationFooterTextFunction,
    GetOrderLocationPropsFunction,
} from './OrderDetail.types'

export const getOrderLocationFooterText: GetOrderLocationFooterTextFunction =
    userAddress => {
        if (!userAddress) return ''

        // eslint-disable-next-line
        let informations = []

        if (userAddress.leave_at_door)
            informations.push(i18n.t('@order.OrderDetail.leave_at_door'))

        if (userAddress.do_not_ring_bell)
            informations.push(i18n.t('@order.OrderDetail.do_not_ring_bell'))

        const information = informations.join(', ')

        return information
    }

export const getOrderLocationProps: GetOrderLocationPropsFunction = order => {
    if (order.userAddress && order.deliveryType === 'address') {
        return {
            title: order.userAddress.title,
            description: order.userAddress.full_address,
            latitude: order.userAddress.lat,
            longitude: order.userAddress.lng,
            FooterComponent:
                order.userAddress.leave_at_door ||
                order.userAddress.do_not_ring_bell ? (
                    <p>{getOrderLocationFooterText(order.userAddress)}</p>
                ) : undefined,
        }
    }

    return {
        title: order.branch.name,
        description: order.branch.full_address || '',
    }
}

export const getProductPrice = (products: Product[]) => {
    return reduce(
        products,
        (productPrice, product) => productPrice + product.total_price,
        0,
    )
}
