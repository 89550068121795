import * as React from 'react'
import { map } from 'lodash'
import { AddressItem } from '@components'

const AddressListLoader: React.FC = () => {
    return (
        <div className="space-y-2">
            {map(new Array(3), (_, index) => (
                <AddressItem.Loader key={index} />
            ))}
        </div>
    )
}
export default AddressListLoader
