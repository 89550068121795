import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import { Icon, OrderStatus } from '@components'
import i18n from '@core/translations'
import type { OrderCardProps } from './OrderCard.types'
import { cn } from '@core/utils'
import OrderThumbnails from './OrderThumbails'

const OrderCard: React.FC<OrderCardProps> = ({
    status = 'order_received',
    title,
    logo,
    addressTitle,
    fullAddress,
    price,
    date,
    currency,
    images,
    className,
    ...props
}) => {
    const { t } = useTranslation()

    const formattedDate = React.useMemo(() => {
        return `${dayjs(date).format('DD MMM')} • ${dayjs(date).format(
            'HH:mm',
        )}`
    }, [date])

    return (
        <div
            className={cn(
                'rounded-[20px] border border-semantic-background-secondary bg-semantic-background-primary duration-200 hover:cursor-pointer hover:bg-semantic-background-secondary',
                className,
            )}
            {...props}>
            <div className="flex items-center justify-between border-b border-semantic-background-tertiary p-4">
                <div className="flex items-center gap-2">
                    <img
                        src={logo}
                        alt={title}
                        className="h-8 w-8 rounded-full border-[1.6px] border-semantic-background-tertiary object-cover"
                    />
                    <h3 className="text-body-md-b text-semantic-content-ink">
                        {title}
                    </h3>
                </div>

                <OrderStatus.Badge status={status} />
            </div>
            <div className="space-y-4 p-4">
                <div className="flex items-center justify-between gap-6">
                    <p className="line-clamp-1 space-x-1 text-semantic-content-ink">
                        <span className="text-body-sm-b">{addressTitle},</span>
                        <span className="text-body-sm-r">{fullAddress}</span>
                    </p>

                    <span className="shrink-0 text-body-sm-r text-semantic-content-inkLight">
                        {formattedDate}
                    </span>
                </div>
                <OrderThumbnails images={images} />
            </div>
            <div className="flex items-center justify-between px-4 pb-4 pt-2">
                <p className="text-body-md-r text-semantic-content-ink">
                    {t('content_config.OrderCard.price_label')}
                </p>
                <div className="flex items-center gap-1">
                    <p className="text-body-md-b text-semantic-content-ink">
                        {CurrencyFormatter.format(
                            price,
                            i18n.getLocale(),
                            currency,
                        )}
                    </p>
                    <Icon
                        name="caret-right"
                        className="text-semantic-content-ink"
                        size={18}
                    />
                </div>
            </div>
        </div>
    )
}
export default OrderCard
