import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import { AddressList } from '@user/components'
import type { OrderLocationProps } from './OrderLocation.types'
import { useOrderLocationHandler } from './OrderLocation.hooks.tsx'

const OrderLocation: React.FC<OrderLocationProps> = ({
    walletId,
    companyId,
    onStepperChange,
}) => {
    const { t } = useTranslation()

    const { handle, orderMenuResult, selected, setSelected } =
        useOrderLocationHandler(walletId, companyId)

    React.useEffect(
        () => {
            return () => onStepperChange('option')
        },
        // eslint-disable-next-line
        [],
    )

    return (
        <React.Fragment>
            <AddressList
                actionType="selectable"
                value={selected}
                onChangeValue={setSelected}
            />

            <Button
                loading={orderMenuResult.isFetching}
                disabled={!selected}
                className="mt-6 w-full"
                onClick={handle}>
                {t('@order.OrderLocation.footer_forward_button')}
            </Button>
        </React.Fragment>
    )
}
export default OrderLocation
