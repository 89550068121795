import React from 'react'
import { useNavigate } from 'react-router'
import type { IconsNameType } from '@components'
import i18n from '@core/translations'
import { type CompanyModules } from '@company/api'
import orderApi from '@order/api'
import type { WalletModuleName } from '@wallet/api'
import { emitter } from '@core/utils'

export interface DeliveryOptionItem {
    title: string
    icon: IconsNameType
    callback: () => void
}

export interface DeliveryOptionParams {
    walletId: number
    companyId: number
    name: WalletModuleName
}

const useDeliveryOption = (
    module: CompanyModules,
    params: DeliveryOptionParams,
): DeliveryOptionItem[] => {
    const navigate = useNavigate()

    const [orderMenuQuery] = orderApi.useLazyOrderMenuQuery()

    const handleNavigateProducts = React.useCallback(async () => {
        try {
            const result = await orderMenuQuery({
                wallet_id: params.walletId,
                delivery_type: undefined,
                branch_id: undefined,
            }).unwrap()

            navigate('/products', {
                state: {
                    uri: result.data.url,
                    branchId: result.data.branch_id,
                    companyId: params.companyId,
                    walletId: params.walletId,
                    addressId: undefined,
                    deliveryType: undefined,
                },
            })
        } catch (e: unknown) {
            const error = e as Error

            if ('status' in error) {
                if (error.status !== 404) return
            }
        }
    }, [navigate, orderMenuQuery, params.companyId, params.walletId])

    return React.useMemo<DeliveryOptionItem[]>(() => {
        const options: DeliveryOptionItem[] = []

        if (module?.enable_address_delivery) {
            options.push({
                title: i18n.t(
                    'hooks.useDeliveryOption.enable_address_delivery_title',
                ),
                icon: 'delivery-scooter',
                callback: () => emitter.emit('OPEN_ORDER_LOCATION'),
            })
        }
        if (module?.enable_takeaway_delivery) {
            options.push({
                title: i18n.t(
                    'hooks.useDeliveryOption.enable_takeaway_delivery_title',
                ),
                icon: 'delivery-people',
                callback: () => emitter.emit('OPEN_BRANCH_MAP'),
            })
        }

        if (params.name === 'products') {
            options.push({
                title: i18n.t('hooks.useDeliveryOption.enable_products_title'),
                icon: 'market',
                callback: () => handleNavigateProducts(),
            })
        }

        return options
    }, [handleNavigateProducts, module, params])
}

export default useDeliveryOption
