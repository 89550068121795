import * as React from 'react'
import { map, slice } from 'lodash'
import { Badge } from '@components'
import type { OrderThumbnailsProps } from './OrderCard.types'
import { ItemCount } from './OrderCard.constants'

const OrderThumbnails: React.FC<OrderThumbnailsProps> = ({ images }) => {
    const shouldShowRemainingImages = React.useMemo(
        () => images.length > ItemCount,
        [images],
    )

    const remainingImageCount = React.useMemo(
        () => images.length - (ItemCount - 1),
        [images],
    )

    const displayedImages = React.useMemo(() => {
        if (images.length === ItemCount) return images

        return slice(images, 0, ItemCount - 1)
    }, [images])

    return (
        <div className="flex items-center gap-2">
            {map(displayedImages, (image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={image}
                    className="h-16 w-12 rounded-[9px] object-cover"
                />
            ))}
            {shouldShowRemainingImages ? (
                <Badge
                    as="div"
                    variant="ink-inverse"
                    size="small"
                    children={`+${remainingImageCount}`}
                    className="ml-2 px-3"
                />
            ) : null}
        </div>
    )
}
export default OrderThumbnails
