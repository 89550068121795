import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { isString } from 'lodash'
import { Container } from '@components'
import type { PostMessageEvent, ProductsState } from './Products.types'

const Products: React.FC = () => {
    const { t } = useTranslation()

    const state = useLocation().state as ProductsState
    const navigate = useNavigate()

    const handleMessage = React.useCallback(
        (event: MessageEvent) => {
            if (!isString(event.data)) return null

            const result = JSON.parse(event.data) as PostMessageEvent

            if (result.event !== 'CHECKOUT') return null

            return navigate('/delivery-summary', {
                state: {
                    walletId: state.walletId,
                    addressId: state.addressId,
                    branchId: state.branchId,
                    payload: result.payload,
                    deliveryType: state.deliveryType,
                },
            })
        },
        [navigate, state],
    )

    React.useEffect(
        () => {
            window.addEventListener('message', handleMessage)

            return () => window.removeEventListener('message', handleMessage)
        },
        // eslint-disable-next-line
        [],
    )

    return (
        <Container
            title={t('@order.Products.route_header_title')}
            goBackProps={{
                onClick: () => navigate(-1),
            }}>
            <Container.Left
                name={t('@order.Products.route_header_title')}
                description={t('@order.Products.route_header_description')}
                LayoutRightComponent={<React.Fragment />}>
                <Container.Content className="lg:min-h-[720px] [&>*]:xl:w-[600px]">
                    <iframe
                        src={`${state.uri}&platform=waas`}
                        className="min-h-[675px] w-full rounded-3xl border border-semantic-background-primary"
                    />
                </Container.Content>
            </Container.Left>
            <Container.Right
                title={t('@order.Products.right_info_title')}
                description={t('@order.Products.right_info_description')}
            />
        </Container>
    )
}
export default Products
