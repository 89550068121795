export default {
    BranchMap: {
        route_header_title: 'Branches',
        branch_detail_action_button: 'Show All Branches',
        branch_detail_layout_right_button: 'Select Branch',
        branch_list_layout_right_button: 'Select Branch',
        branch_list_bottom_sheet_header: 'BRANCHES',
        branch_list_bottom_sheet_header_button: 'Show on Map',
        location_permission_toast_message:
            'Would you like to see nearby branches\nby granting location permission?',
        location_provider_error_toast_message:
            'Location setting on the device is turned off',
        location_permission_toast_chip_label: 'Allow',
    },
}
