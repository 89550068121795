import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import i18n from '@core/translations'
import type { WalletCardRedeemProps } from './WalletCard.types'
import { cn } from '@core/utils'
import WalletCardRedeemItem from './WalletCardRedeemItem'

const WalletCardRedeem: React.FC<WalletCardRedeemProps> = ({
    title,
    logo,
    amount,
    currency,
    points,
    showPoints,
    redeemPoints,
    pointSwitchProps,
    className,
    onRedeemPointsChange,
    ...props
}) => {
    const { t } = useTranslation()

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
    }, [amount, currency])

    return (
        <div
            className={cn(
                'min-w-[327px] rounded-[20px] bg-solid-brand-5 p-[3px]',
                className,
            )}
            {...props}>
            <div className="flex items-center justify-between p-[13px]">
                <div className="flex items-center gap-3">
                    <img
                        src={logo}
                        alt={title}
                        className="h-10 w-10 rounded-full"
                    />
                    <h3 className="text-body-lg-b text-semantic-background-inkInverse">
                        {title}
                    </h3>
                </div>

                <span className="text-display-md-b text-semantic-background-inkInverse">
                    {formattedAmount}
                </span>
            </div>

            <div className="rounded-b-[17px] rounded-t-[8px] bg-semantic-background-inkInverse px-[13px] py-4">
                {showPoints && (
                    <WalletCardRedeemItem
                        icon="point"
                        title={t(
                            'content_config.WalletCard.placeholder_gift_point',
                        )}
                        amount={points}
                        currency={currency}
                        value={redeemPoints || false}
                        pointSwitchProps={pointSwitchProps}
                        onChangeValue={onRedeemPointsChange}
                    />
                )}
            </div>
        </div>
    )
}
export default WalletCardRedeem
