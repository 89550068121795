import * as React from 'react'
import { debounce } from 'lodash'
import { Sheet, Stepper } from '@components'
import { useAppSelector } from '@core/store'
import { MasterPassHelp } from '@deposit/views'
import type {
    EmitterEventProps,
    MasterPassOTPProps,
} from './MasterPassOTP.types'
import { emitter } from '@core/utils'
import MasterPassOTPContent from './MasterPassOTPContent'

const MasterPassOTP: React.FC<MasterPassOTPProps> = ({ update }) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [view, setView] = React.useState<'otp' | 'help'>('otp')

    const [emitterEvent, setEmitterEvent] = React.useState<
        EmitterEventProps | undefined
    >(undefined)

    const { mpResendOTPLoading, mpMakeLoading, mpValidateTransactionLoading } =
        useAppSelector(state => state.depositSlice)

    React.useEffect(() => {
        emitter.on('MASTERPASS_OTP', event => {
            debounce(() => setOpen(true), 300)()
            setEmitterEvent(event)
        })

        return () => {
            emitter.off('MASTERPASS_OTP')
        }
    }, [setOpen])

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <Sheet.Content
                loading={
                    mpResendOTPLoading ||
                    mpMakeLoading ||
                    mpValidateTransactionLoading
                }>
                <Stepper activeStep={view}>
                    <Stepper.Step step="otp">
                        <MasterPassOTPContent
                            emitterEvent={emitterEvent}
                            setOpen={setOpen}
                            setView={setView}
                            update={update}
                        />
                    </Stepper.Step>
                    <Stepper.Step step="help">
                        <MasterPassHelp setView={setView} />
                    </Stepper.Step>
                </Stepper>
            </Sheet.Content>
        </Sheet>
    )
}
export default MasterPassOTP
