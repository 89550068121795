import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@components'
import companyApi from '@company/api'
import orderApi from '@order/api'
import type { DeliverySummaryParams } from './DeliverySummary.types'
import { useNotification } from '@core/hooks'
import { emitter } from '@core/utils'
import AddNoteSection from './AddNoteSection'
import AgreementSection from './AgreementSection'
import DeliveryLocationSection from './DeliveryLocationSection'
import DeliveryMethodSection from './DeliveryMethodSection'
import { deliverySummaryReducer, INITIAL_STATE } from './DeliverySummary.state'
import DeliverySummaryContainer from './DeliverySummaryContainer'
import DepositToast from './DepositToast'
import PaymentSummarySection from './PaymentSummarySection'
import ToolBarFooterSection from './ToolBarFooterSection'
import WalletSection from './WalletSection'

const DeliverySummary: React.FC = () => {
    const t = useTranslation().t
    const navigate = useNavigate()
    const params = useLocation().state as DeliverySummaryParams
    const toast = useNotification().toast

    const [state, dispatch] = React.useReducer(
        deliverySummaryReducer,
        INITIAL_STATE,
    )

    const [pay, payQuery] = orderApi.usePayMutation()
    const orderSummaryQuery = orderApi.useOrderSummaryQuery(
        {
            wallet_id: params.walletId,
            address_id: params.addressId,
            branch_id: params.branchId,
            delivery_type: params.deliveryType,
            products: params.payload,
            point_usage: state.isUsingPoints,
        },
        { refetchOnMountOrArgChange: true },
    )
    const mapBranchQuery = companyApi.useMapBranchQuery({
        id: params.branchId,
    })

    const orderSummary = React.useMemo(() => {
        return orderSummaryQuery.data?.data
    }, [orderSummaryQuery.data?.data])

    const wallet = React.useMemo(() => {
        return orderSummary?.wallet
    }, [orderSummary?.wallet])

    const mapBranch = React.useMemo(
        () => mapBranchQuery.data?.data,
        [mapBranchQuery.data],
    )

    const handlePayPress = React.useCallback(async () => {
        await pay({
            address_id: params.addressId,
            branch_id: params.branchId,
            delivery_type: params.deliveryType,
            do_not_ring_bell: state.isDontRingDoorbell,
            leave_at_door: state.isLeaveAtDoor,
            note: state.additionalNote,
            products: params.payload,
            wallet_id: params.walletId,
            point_usage: state.isUsingPoints,
            table_number: state.tableNumber,
        }).unwrap()

        toast({
            variant: 'success',
            icon: 'check-circle',
            message: t('@order.DeliverySummary.toast_payment_success_message'),
        })

        navigate('/', { replace: true })
    }, [navigate, params, pay, state, t, toast])

    React.useEffect(
        () => {
            emitter.on('ORDER_SUMMARY_ERROR', () => {
                navigate('/', { replace: true })
            })
        },
        // eslint-disable-next-line
        [],
    )

    if (!orderSummary || !wallet || !mapBranch)
        return (
            <div className="grid h-[calc(100vh-280px)] w-full place-items-center lg:h-[calc(100vh-80px)]">
                <Spinner size={36} />
            </div>
        )

    return (
        <DeliverySummaryContainer
            title={mapBranch.company.name}
            description={mapBranch.name}
            content={
                <div className="space-y-12 pb-[113px]">
                    <DeliveryMethodSection
                        type={params.deliveryType}
                        directions={mapBranch.address}
                    />
                    <DeliveryLocationSection
                        company={wallet.company}
                        leaveOrderAtDoor={state.isLeaveAtDoor}
                        ringDoorbell={state.isDontRingDoorbell}
                        deliveryType={params.deliveryType}
                        userAddress={orderSummary.user_address}
                        branch={orderSummary.branch}
                        onChangeState={dispatch}
                    />
                    <WalletSection
                        name={wallet.company.name}
                        logo={wallet.company.logo}
                        color={wallet.company.color}
                        currency={wallet.company.currency}
                        balance={wallet.balance}
                        points={wallet.point}
                        value={state.isUsingPoints}
                        onChangeValue={dispatch}
                    />
                    <PaymentSummarySection
                        products={orderSummary.products}
                        currency={wallet.company.currency}
                        totalPrice={orderSummary.final_total}
                        paidPoint={orderSummary.paid_point}
                        deliveryAmount={orderSummary.delivery_amount}
                        deliveryDiscount={orderSummary.delivery_discount}
                        deliveryType={params.deliveryType}
                        discountPoint={orderSummary.discount_point}
                        discountedTotalPrice={
                            orderSummary.discounted_total_amount
                        }
                    />
                    <AddNoteSection
                        note={state.additionalNote}
                        onChangeNote={dispatch}
                    />
                    <AgreementSection
                        checked={state.isTermsAccepted}
                        onChangeChecked={dispatch}
                    />
                    <DepositToast
                        walletId={wallet.id}
                        depositAmount={orderSummary.deposit_balance}
                        params={params}
                    />
                </div>
            }>
            <ToolBarFooterSection
                totalPrice={orderSummary.paid_price}
                discountedTotalPrice={orderSummary.discounted_total_amount}
                currency={wallet.company.currency}
                isTermsAccepted={state.isTermsAccepted}
                tableNumber={state.tableNumber}
                deliveryType={params.deliveryType}
                loading={payQuery.isLoading}
                onSubmit={handlePayPress}
            />
        </DeliverySummaryContainer>
    )
}
export default DeliverySummary
