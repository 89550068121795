import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Icon } from '@components'
import i18n from '@core/translations'
import type { EarnedPointItemProps } from './EarnedPointItem.types'
import { cn } from '@core/utils'

const EarnedPointItem: React.FC<EarnedPointItemProps> = ({
    availablePoints,
    earnedPoints,
    spentPoints,
    currency,
    className,
    ...props
}) => {
    const { t } = useTranslation()

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(
            availablePoints,
            i18n.getLocale(),
            currency,
        )
    }, [availablePoints, currency])

    const formattedBalance = React.useMemo(() => {
        return CurrencyFormatter.format(
            earnedPoints,
            i18n.getLocale(),
            currency,
        )
    }, [currency, earnedPoints])

    const formattedSpent = React.useMemo(() => {
        return CurrencyFormatter.format(spentPoints, i18n.getLocale(), currency)
    }, [currency, spentPoints])

    return (
        <div
            className={cn(
                'flex gap-4 divide-x rounded-[20px] bg-semantic-background-primary p-6 sm:gap-10',
                className,
            )}
            {...props}>
            <div className="flex-shrink-0 space-y-[2px]">
                <h4 className="text-body-sm-b text-semantic-content-ink">
                    {t('@wallet.Earned.points_section_balance_title')}
                </h4>
                <span className="text-display-hg text-semantic-content-success">
                    {formattedAmount}
                </span>
            </div>

            <div className="grid flex-1 items-center pl-4 sm:pl-10">
                <div className="space-y-3">
                    <div className="flex flex-grow items-center gap-2">
                        <Icon name="dot" size={8} color="var(--red-3)" />
                        <div className="flex flex-grow flex-wrap items-center justify-between gap-1 sm:gap-4">
                            <h4 className="text-body-sm-r text-semantic-content-ink">
                                {t('@wallet.Earned.points_section_spent_title')}
                            </h4>
                            <p className="text-body-sm-b text-semantic-content-ink">
                                {formattedSpent}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-grow items-center gap-2">
                        <Icon name="dot" size={8} color="var(--green-3)" />
                        <div className="flex flex-grow flex-wrap items-center justify-between sm:gap-4">
                            <h4 className="text-body-sm-r text-semantic-content-ink">
                                {t(
                                    '@wallet.Earned.points_section_amount_title',
                                )}
                            </h4>
                            <p className="text-body-sm-b text-semantic-content-ink">
                                {formattedBalance}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EarnedPointItem
