import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch } from '@components'
import type { DeliveryLocationSectionFooterProps } from './DeliverySummary.types'

const DeliveryLocationSectionFooter: React.FC<
    DeliveryLocationSectionFooterProps
> = ({ leaveOrderAtDoor, ringDoorbell, onChangeState }) => {
    const { t } = useTranslation()

    return (
        <div className="divide-y divide-semantic-background-tertiary text-body-md-b text-semantic-content-ink">
            <div className="flex h-14 items-center justify-between">
                <h4>{t('@order.DeliverySummary.leave_order_at_door_label')}</h4>
                <Switch
                    checked={leaveOrderAtDoor}
                    onCheckedChange={checked =>
                        onChangeState({
                            type: 'SET_LEAVE_AT_DOOR',
                            payload: checked,
                        })
                    }
                />
            </div>
            <div className="flex h-14 items-center justify-between py-3">
                <h4>
                    {t('@order.DeliverySummary.do_not_ring_doorbell_label')}
                </h4>
                <Switch
                    checked={ringDoorbell}
                    onCheckedChange={checked =>
                        onChangeState({
                            type: 'SET_DONT_RING_DOORBELL',
                            payload: checked,
                        })
                    }
                />
            </div>
        </div>
    )
}
export default DeliveryLocationSectionFooter
