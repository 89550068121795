import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Input, Sheet, Stepper } from '@components'
import baseApi from '@core/api'
import { AddressDropdownFieldsItem } from '@user/components'
import type {
    AddressCreateDropdownFieldsProps,
    AddressCreateDropdownFieldStepper,
} from './AddressCreate.types'
import {
    getAddressDropdownFieldSelectedItem,
    getAddressDropdownFieldStepperTitle,
} from './AddressCreate.utils'

const AddressDropdownFields: React.FC<AddressCreateDropdownFieldsProps> = ({
    cityId,
    districtId,
    neighborhoodId,
    onChangeValue,
}) => {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState<boolean>(false)
    const [stepper, setStepper] =
        React.useState<AddressCreateDropdownFieldStepper>('city')

    const citiesResult = baseApi.useLocationCitiesQuery()
    const districtsResult = baseApi.useLocationDistrictsQuery(
        { id: cityId as number },
        { skip: !cityId },
    )
    const neighborhoodsResult = baseApi.useLocationNeighborhoodsQuery(
        { id: districtId as number },
        { skip: !districtId },
    )

    const cities = React.useMemo(() => {
        return citiesResult.data?.data ?? []
    }, [citiesResult.data?.data])

    const districts = React.useMemo(() => {
        return districtsResult.data?.data ?? []
    }, [districtsResult.data?.data])

    const neighborhoods = React.useMemo(() => {
        return neighborhoodsResult.data?.data ?? []
    }, [neighborhoodsResult.data?.data])

    return (
        <React.Fragment>
            <Input
                readOnly
                label={t('@user.AddressCreate.dropdown_field_label')}
                labelClassName="cursor-pointer"
                inputClassName="cursor-pointer"
                rightIcon="caret-fill-down"
                rightIconProps={{ color: 'var(--content-ink)' }}
                value={
                    getAddressDropdownFieldSelectedItem(cities, cityId)
                        ?.title || ''
                }
                onClick={() => {
                    setStepper('city')
                    setOpen(true)
                }}
            />
            <Input
                readOnly
                disabled={!cityId || citiesResult.isLoading}
                label={t(
                    '@user.AddressCreate.dropdown_field_district_modal_header_title',
                )}
                labelClassName="cursor-pointer"
                inputClassName="cursor-pointer"
                rightIcon="caret-fill-down"
                rightIconProps={{ color: 'var(--content-ink)' }}
                value={
                    getAddressDropdownFieldSelectedItem(districts, districtId)
                        ?.title || ''
                }
                onClick={() => {
                    setStepper('districts')
                    setOpen(true)
                }}
            />
            <Input
                readOnly
                disabled={!districtId || districtsResult.isLoading}
                label={t(
                    '@user.AddressCreate.dropdown_field_neighborhoods_modal_header_title',
                )}
                labelClassName="cursor-pointer"
                inputClassName="cursor-pointer"
                rightIcon="caret-fill-down"
                rightIconProps={{ color: 'var(--content-ink)' }}
                value={
                    getAddressDropdownFieldSelectedItem(
                        neighborhoods,
                        neighborhoodId,
                    )?.title || ''
                }
                onClick={() => {
                    setStepper('neighborhoods')
                    setOpen(true)
                }}
            />

            <Sheet open={open} onOpenChange={open => setOpen(open)}>
                <Sheet.Content className="overflow-y-scroll">
                    <Sheet.Header>
                        <Sheet.Title
                            children={getAddressDropdownFieldStepperTitle(
                                stepper,
                            )}
                        />
                        <Sheet.Description
                            className="sr-only"
                            children={t(
                                '@user.AddressCreate.dropdown_field_city_description',
                            )}
                        />
                    </Sheet.Header>

                    <Stepper activeStep={stepper}>
                        <Stepper.Step step="city" className="space-y-2">
                            {map(cities, city => (
                                <AddressDropdownFieldsItem
                                    key={city.id}
                                    children={city.name}
                                    onOpenChange={setOpen}
                                    onClick={() =>
                                        onChangeValue([city.id, null, null])
                                    }
                                />
                            ))}
                        </Stepper.Step>

                        <Stepper.Step step="districts" className="space-y-2">
                            {map(districts, district => (
                                <AddressDropdownFieldsItem
                                    key={district.id}
                                    children={district.name}
                                    onOpenChange={setOpen}
                                    onClick={() =>
                                        onChangeValue([
                                            cityId,
                                            district.id,
                                            null,
                                        ])
                                    }
                                />
                            ))}
                        </Stepper.Step>

                        <Stepper.Step
                            step="neighborhoods"
                            className="space-y-2">
                            {map(neighborhoods, neighborhood => (
                                <AddressDropdownFieldsItem
                                    key={neighborhood.id}
                                    children={neighborhood.name}
                                    onOpenChange={setOpen}
                                    onClick={() =>
                                        onChangeValue([
                                            cityId,
                                            districtId,
                                            neighborhood.id,
                                        ])
                                    }
                                />
                            ))}
                        </Stepper.Step>
                    </Stepper>
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}
export default AddressDropdownFields
