import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { WalletCard } from '@components'
import type { BranchSectionProps } from './OrderDetail.types'

const BranchSection: React.FC<BranchSectionProps> = ({
    name,
    branch,
    color,
    currency,
    logo,
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.branch_section_title')}
            </h3>
            <WalletCard.Paying
                title={name}
                color={color}
                currency={currency}
                logo={logo}
                amount={0}
                isPointSwitch={false}
                pointBadgeProps={{
                    variant: 'ink-inverse-tonal',
                    leftIcon: 'map-pin',
                    children: branch,
                    as: 'div',
                    size: 'tiny',
                }}
            />
        </div>
    )
}
export default BranchSection
