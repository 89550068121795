import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { map } from 'lodash'
import type { DeliveryDetailSectionProps } from './OrderDetail.types'

const DeliveryDetailSection: React.FC<DeliveryDetailSectionProps> = ({
    deliveryType,
    orderNo,
    createdAt,
    deliveredAt,
}) => {
    const { t } = useTranslation()

    const formatDate = (date: Date) =>
        dayjs(date).format('DD MMMM YYYY / HH:mm')

    const deliveryDetails = React.useMemo(() => {
        const details = [
            {
                label: t('@order.OrderDetail.delivery_type_label'),
                value:
                    deliveryType === 'address'
                        ? t('@order.OrderDetail.delivery_type_address')
                        : deliveryType === 'table'
                          ? t('@order.OrderDetail.delivery_type_table')
                          : t('@order.OrderDetail.delivery_type_takeaway'),
            },
            {
                label: t('@order.OrderDetail.order_no_label'),
                value: `#${orderNo}`,
            },
            {
                label: t('@order.OrderDetail.order_date_label'),
                value: formatDate(createdAt),
            },
        ]

        if (deliveredAt)
            details.push({
                label: t('@order.OrderDetail.delivery_date_label'),
                value: formatDate(deliveredAt),
            })

        return details
    }, [t, deliveryType, orderNo, createdAt, deliveredAt])

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.delivery_address_section_title')}
            </h3>
            <div className="space-y-4 rounded-[20px] bg-semantic-background-primary px-4 py-6">
                {map(deliveryDetails, (detail, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-between">
                        <h4 className="text-body-md-r text-semantic-content-inkMedium">
                            {detail.label}
                        </h4>
                        <p className="text-body-md-b text-semantic-content-ink">
                            {detail.value}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default DeliveryDetailSection
