import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Icon } from '@components'
import i18n from '@core/translations'
import type { RewardChartPointsProps } from './RewardChart.types'

const RewardChartPoints: React.FC<RewardChartPointsProps> = ({
    availablePoints,
    earnedPoints,
    spentPoints,
    currency,
}) => {
    const { t } = useTranslation()

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.formatToDetails(
            availablePoints,
            i18n.getLocale(),
            currency,
        )
    }, [availablePoints, currency])

    const formattedBalance = React.useMemo(() => {
        return CurrencyFormatter.format(
            earnedPoints,
            i18n.getLocale(),
            currency,
        )
    }, [currency, earnedPoints])

    const formattedSpent = React.useMemo(() => {
        return CurrencyFormatter.format(spentPoints, i18n.getLocale(), currency)
    }, [currency, spentPoints])

    return (
        <div className="reward-chart__container">
            <div className="reward-chart__left-wrapper">
                <h6 className="reward-chart__left-wrapper-title uppercase">
                    {t('@wallet.Earned.points_section_balance_title')}
                </h6>
                <div className="reward-chart__left-wrapper-content">
                    <div className="reward-chart-progress__left-wrapper-amount">
                        <span className="!text-semantic-content-success">
                            {formattedAmount.symbol}
                        </span>
                        <span className="!text-semantic-content-success">
                            {formattedAmount.digid}
                        </span>
                    </div>
                    <p className="text-body-sm-r text-semantic-content-ink">
                        {t('@wallet.Earned.points_section_balance_title')}
                    </p>
                </div>
            </div>
            <div className="grid items-center justify-end lg:justify-center">
                <div className="space-y-3">
                    <div className="flex items-center gap-2">
                        <Icon name="dot" size={8} color="var(--red-3)" />
                        <div className="flex flex-wrap items-center gap-2 sm:gap-4">
                            <h4 className="text-body-sm-r text-semantic-content-ink md:text-body-lg-r">
                                {t('@wallet.Earned.points_section_spent_title')}
                            </h4>
                            <p className="text-body-sm-b text-semantic-content-ink md:text-body-lg-b">
                                {formattedSpent}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center gap-2">
                        <Icon name="dot" size={8} color="var(--green-3)" />
                        <div className="flex flex-wrap items-center gap-2 sm:gap-4">
                            <h4 className="text-body-sm-r text-semantic-content-ink md:text-body-lg-r">
                                {t(
                                    '@wallet.Earned.points_section_amount_title',
                                )}
                            </h4>
                            <p className="text-body-sm-b text-semantic-content-ink md:text-body-lg-b">
                                {formattedBalance}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RewardChartPoints
