import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Textarea } from '@components'
import type { AddNoteSectionProps } from './DeliverySummary.types'

const AddNoteSection: React.FC<AddNoteSectionProps> = ({
    note,
    onChangeNote,
}) => {
    const { t } = useTranslation()

    return (
        <Row className="space-y-3">
            <Row.Title
                title={t(
                    '@order.DeliverySummary.additional_note_section_title',
                )}
            />

            <Textarea
                value={note}
                placeholder={t(
                    '@order.DeliverySummary.additional_note_placeholder',
                )}
                onChange={event =>
                    onChangeNote({
                        type: 'SET_ADDITIONAL_NOTE',
                        payload: event.target.value,
                    })
                }
            />
        </Row>
    )
}
export default AddNoteSection
