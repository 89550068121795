import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, DepositCard, Row } from '@components'
import type { DeliveryMethodSectionProps } from './DeliverySummary.types'
import { getDeliveryDetails } from './DeliverySummary.utils'

const DeliveryMethodSection: React.FC<DeliveryMethodSectionProps> = ({
    type,
    directions,
}) => {
    const { t } = useTranslation()

    const routeDirection = React.useCallback(() => {
        if (!directions) return

        return window.open(
            `https://www.google.com/maps?q=${directions?.location.lat},${directions?.location.lng}`,
            '_blank',
        )
    }, [directions])

    return (
        <Row className="space-y-3">
            <Row.Title
                title={t(
                    '@order.DeliverySummary.delivery_method_section_title',
                )}
            />
            <DepositCard.Other
                avatarIcon={getDeliveryDetails(type).leftIcon}
                title={getDeliveryDetails(type).title}
                LayoutRightComponent={
                    type !== 'address' ? (
                        <Badge
                            as="div"
                            variant="ink-inverse"
                            size="small"
                            leftIcon="map-pin"
                            className="hover:cursor-pointer"
                            children={t(
                                '@order.DeliverySummary.direction_chip_title',
                            )}
                            onClick={routeDirection}
                        />
                    ) : (
                        <React.Fragment />
                    )
                }
                className="cursor-auto"
            />
        </Row>
    )
}
export default DeliveryMethodSection
