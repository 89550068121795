import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, Container } from '@components'
import { AmountSection } from '@deposit/components'
import { useCompletePurchase, useTopUp } from '@deposit/hooks'
import { DepositMethodsService } from '@deposit/services'
import { GoldFramerPaymentForm, MasterPassOTP } from '@deposit/views'
import walletApi from '@wallet/api'
import type { TopUpStateProps } from './TopUp.types'
import { useCheckAmount, useDefaultDepositAmount } from './TopUp.hooks'
import DepositMethodSection from './DepositMethodSection'
import InstallmentSection from './InstallmentSection'
import OtherDepositMethodSection from './OtherDepositMethodSection'

const TopUp: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const state = useLocation().state as TopUpStateProps

    const [installmentCount, setInstallmentCount] = React.useState<
        number | null
    >(null)
    const [amount, setAmount] = React.useState<number>(0)
    const [isCustomAmount, setIsCustomAmount] = React.useState<boolean>(false)

    const handleCompletePurchase = useCompletePurchase(amount)

    const walletDetailsQuery = walletApi.useWalletDetailsQuery(
        { wallet_id: Number(localStorage.getItem('user.wallet_id')) },
        { refetchOnMountOrArgChange: true },
    )

    const wallet = React.useMemo(() => {
        return walletDetailsQuery.data?.data
    }, [walletDetailsQuery.data?.data])

    const defaultAmount = useDefaultDepositAmount(
        wallet?.company.settings.default_deposit_amounts,
    )

    const isValid = React.useMemo(() => {
        if (!wallet) return false
        return (
            wallet.company.settings.minimum_deposit.amount &&
            amount >= wallet.company.settings.minimum_deposit.amount &&
            amount <= wallet.company.settings.maximum_deposit.amount
        )
    }, [amount, wallet])

    const isAvailableDepositMethods = React.useMemo(() => {
        const depositMethods =
            walletDetailsQuery.data?.data.company.deposit_methods

        if (!depositMethods) return false

        const availableMethods =
            DepositMethodsService.getAvailableDepositMethods(depositMethods)

        return !!availableMethods.length
    }, [walletDetailsQuery.data?.data.company.deposit_methods])

    const checkPaymentAmount = useCheckAmount({
        location: state,
        wallet,
        setIsCustomAmount,
        type: 'payment',
    })

    const checkAmount = useCheckAmount({
        location: state,
        wallet,
        setIsCustomAmount,
        type: 'deposit',
    })

    const purchase = useTopUp(
        {
            walletId: Number(localStorage.getItem('user.wallet_id')),
            amount: amount,
            installmentCount: installmentCount,
        },
        handleCompletePurchase,
    )

    React.useEffect(() => {
        const params = state?.trigger_data

        if (params?.payment_amount) return setAmount(checkPaymentAmount)

        if (params?.amount) return setAmount(checkAmount)

        setAmount(defaultAmount)
    }, [
        checkAmount,
        checkPaymentAmount,
        defaultAmount,
        state?.trigger_data,
        wallet,
    ])

    if (!wallet) return null

    return (
        <React.Fragment>
            <Container
                title={t('@deposit.TopUp.route_header_title')}
                goBackProps={{
                    onClick: () =>
                        !state?.go_home ? navigate(-1) : navigate('/'),
                }}>
                <Container.Left
                    name={wallet.company.name}
                    description={t('@deposit.TopUp.balance')}
                    currency={wallet.company.currency}
                    amount={wallet.balance}
                    point={wallet.point}>
                    <Container.Content>
                        {(wallet.company.deposit_methods.masterpass ||
                            wallet.company_id === 222) && (
                            <React.Fragment>
                                <AmountSection
                                    walletId={wallet.id}
                                    amount={amount}
                                    isVisibleCustomAmount={isCustomAmount}
                                    setCustomAmount={setIsCustomAmount}
                                    onChangeAmount={setAmount}
                                />
                                <DepositMethodSection
                                    visible={isAvailableDepositMethods}
                                    walletId={wallet.id}
                                    companyId={wallet.company_id}
                                />
                                <InstallmentSection
                                    amount={amount}
                                    walletId={wallet.id}
                                    companyId={wallet.company_id}
                                    currency={wallet.company.currency}
                                    onChangeCount={setInstallmentCount}
                                />
                            </React.Fragment>
                        )}
                        <OtherDepositMethodSection
                            walletId={wallet.id}
                            companyId={wallet.company_id}
                            amount={amount}
                            enabledMasterPass={
                                wallet.company.deposit_methods.masterpass
                            }
                            enabledMasterPassDP={
                                wallet.company.deposit_methods.masterpass_dp
                            }
                            isCustomAmount={isCustomAmount}
                        />
                        <GoldFramerPaymentForm
                            walletId={wallet.id}
                            amount={amount}
                            companyId={wallet.company_id}
                        />
                        {wallet.company.deposit_methods.masterpass &&
                            wallet.company_id !== 222 && (
                                <Button
                                    className="mt-10 w-full"
                                    children={t(
                                        '@deposit.TopUp.footer_submit_button',
                                    )}
                                    disabled={!isValid}
                                    loading={purchase.loading}
                                    onClick={purchase.init}
                                />
                            )}
                    </Container.Content>
                </Container.Left>
                <Container.Right
                    title={t('@deposit.TopUp.info_title')}
                    description={t('@deposit.TopUp.info_description')}
                />
            </Container>
            <MasterPassOTP />
        </React.Fragment>
    )
}

export default TopUp
