import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { RewardChart, SectionBar } from '@components'
import walletApi from '@wallet/api'
import type { EarnedSectionProps } from './WalletDetail.types'

const EarnedSection: React.FC<EarnedSectionProps> = ({
    walletId,
    currency,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const walletPointWonStatsQuery = walletApi.useWalletPointWonStatsQuery(
        { wallet_id: walletId },
        { refetchOnMountOrArgChange: true },
    )

    const pointWonStats = React.useMemo(() => {
        return walletPointWonStatsQuery.data?.data
    }, [walletPointWonStatsQuery.data?.data])

    if (!pointWonStats) return null

    return (
        <div className="container my-10 space-y-6">
            <SectionBar
                title={t('@wallet.Earned.route_header_title')}
                description={t(
                    '@campaign.CampaignRewardList.campaign_section_description',
                )}
                LayoutRightComponent
                LayoutRightComponentProps={{
                    children: t('@wallet.Earned.earned_all'),
                }}
                NavigateLink={() => navigate('/earned')}
            />

            <div className="grid grid-cols-1 gap-10 xl:grid-cols-2">
                <RewardChart.Points
                    availablePoints={pointWonStats.total_balance}
                    earnedPoints={pointWonStats.total_amounts}
                    spentPoints={pointWonStats.total_spents}
                    currency={currency}
                />
            </div>
        </div>
    )
}
export default EarnedSection
