import { useNavigate } from 'react-router'
import type { DeliveryType } from '@order/api'
import orderApi from '@order/api'

export const useNavigationMenuByDeliveryType = () => {
    const navigate = useNavigate()

    const [orderMenuQuery] = orderApi.useLazyOrderMenuQuery()

    const navigateToProducts = async (
        companyId: number,
        deliveryType: DeliveryType,
        walletId: number,
        branchId: number,
    ) => {
        if (deliveryType === 'address') return

        try {
            const result = await orderMenuQuery({
                wallet_id: walletId,
                delivery_type: deliveryType,
                branch_id: branchId,
            }).unwrap()

            navigate('/products', {
                state: {
                    uri: result.data.url,
                    branchId: result.data.branch_id,
                    companyId: companyId,
                    walletId: walletId,
                    addressId: undefined,
                    deliveryType: deliveryType,
                },
            })
        } catch (e: unknown) {
            const error = e as Error

            if ('status' in error) {
                if (error.status !== 404) return
            }
        }
    }

    return navigateToProducts
}
