import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Button, ToolBar } from '@components'
import i18n from '@core/translations'
import type { ToolBarFooterSectionProps } from './DeliverySummary.types'

const ToolBarFooterSection: React.FC<ToolBarFooterSectionProps> = ({
    totalPrice,
    discountedTotalPrice,
    currency,
    isTermsAccepted,
    loading,
    tableNumber,
    deliveryType,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const hasDiscount = React.useMemo(() => {
        return totalPrice !== discountedTotalPrice
    }, [totalPrice, discountedTotalPrice])

    const formattedDiscountedTotalPrice = React.useMemo(() => {
        return CurrencyFormatter.format(
            discountedTotalPrice,
            i18n.getLocale(),
            currency,
        )
    }, [discountedTotalPrice, currency])

    const formattedTotalPrice = React.useMemo(() => {
        return CurrencyFormatter.format(totalPrice, i18n.getLocale(), currency)
    }, [currency, totalPrice])

    const isButtonDisabled = React.useMemo(() => {
        if (deliveryType === 'table') return !isTermsAccepted || !tableNumber

        return !isTermsAccepted
    }, [isTermsAccepted, tableNumber, deliveryType])

    return (
        <ToolBar className="absolute bottom-0 w-full justify-center">
            <div className="flex w-full items-center justify-between xl:w-[486px]">
                <ToolBar.Left className="space-y-[2px]">
                    <h3 className="text-body-md-r text-semantic-content-inkMedium">
                        {t(
                            '@order.DeliverySummary.tool_bar_footer_total_amount_label',
                        )}
                    </h3>
                    <div className="flex items-center gap-[2px]">
                        <span className="text-body-lg-b text-semantic-content-ink">
                            {formattedDiscountedTotalPrice}
                        </span>
                        {hasDiscount ? (
                            <span className="text-body-md-r text-semantic-content-ink line-through">
                                {formattedTotalPrice}
                            </span>
                        ) : null}
                    </div>
                </ToolBar.Left>
                <ToolBar.Right className="flex-grow xl:flex-grow-0">
                    <Button
                        className="w-full xl:w-auto xl:px-16"
                        children={t(
                            '@order.DeliverySummary.tool_bar_footer_pay_button',
                        )}
                        disabled={isButtonDisabled}
                        loading={loading}
                        onClick={onSubmit}
                    />
                </ToolBar.Right>
            </div>
        </ToolBar>
    )
}
export default ToolBarFooterSection
