import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { filter, map, slice } from 'lodash'
import { CampaignItem, Sheet, Tabs } from '@components'
import campaignApi, { type CampaignNewsListItem } from '@campaign/api'
import { CampaignDetail } from '@campaign/views'
import type {
    CampaignSegmentedListProps,
    CampaignSegmentedListStaticProperties,
    CampaignSegmentedListTabKey,
} from './CampaignSegmentedList.types'
import { useWindowSize } from '@core/hooks'
import CampaignSegmentedListLoader from './CampaignSegmentedListLoader'

const CampaignSegmentedList: React.FC<CampaignSegmentedListProps> &
    CampaignSegmentedListStaticProperties = ({ companyId }) => {
    const { t } = useTranslation()
    const ScreenWidth = useWindowSize().width

    const [activeTab, setActiveTab] =
        React.useState<CampaignSegmentedListTabKey>('payment_count_gift')
    const [open, setOpen] = React.useState<boolean>(false)
    const [selectedCampaign, setSelectedCampaign] = React.useState<
        number | null
    >(null)

    const campaignNewsListQuery = campaignApi.useCampaignNewsListQuery(
        { page: 1, company_id: companyId, campaign_types: undefined },
        { refetchOnMountOrArgChange: true },
    )

    const campaignNewsListData = React.useMemo(() => {
        return campaignNewsListQuery.data?.data ?? []
    }, [campaignNewsListQuery.data?.data])

    const depositGiftCampaignNewsList = React.useMemo(() => {
        return filter(
            campaignNewsListData,
            (campaignNews: CampaignNewsListItem) =>
                campaignNews.campaign.type === 'deposit_gift' ||
                campaignNews.campaign.type === 'cross_marketing_deposit_gift',
        )
    }, [campaignNewsListData])

    const paymentCountGiftCampaignNewsList = React.useMemo(() => {
        return filter(
            campaignNewsListData,
            (campaignNews: CampaignNewsListItem) =>
                campaignNews.campaign.type === 'payment_count_gift' ||
                campaignNews.campaign.type === 'payment_discount',
        )
    }, [campaignNewsListData])

    const filtered = React.useMemo(() => {
        if (activeTab === 'deposit_gift') return depositGiftCampaignNewsList

        if (activeTab === 'payment_count_gift')
            return paymentCountGiftCampaignNewsList

        return []
    }, [
        activeTab,
        depositGiftCampaignNewsList,
        paymentCountGiftCampaignNewsList,
    ])

    const segmentedTabItems = React.useMemo(() => {
        const tabs: { key: CampaignSegmentedListTabKey; label: string }[] = []

        if (depositGiftCampaignNewsList.length) {
            tabs.push({
                key: 'deposit_gift',
                label: t(
                    '@campaign.CampaignSegmentedList.segmented_section_topup_title',
                ),
            })
        }

        if (paymentCountGiftCampaignNewsList.length) {
            tabs.push({
                key: 'payment_count_gift',
                label: t(
                    '@campaign.CampaignSegmentedList.segmented_section_capture_title',
                ),
            })
        }

        return tabs
    }, [
        depositGiftCampaignNewsList.length,
        paymentCountGiftCampaignNewsList.length,
        t,
    ])

    React.useEffect(() => {
        setActiveTab(segmentedTabItems[0]?.key ?? null)
    }, [segmentedTabItems])

    if (campaignNewsListQuery.isLoading)
        return <CampaignSegmentedListLoader limit={3} />

    if (!segmentedTabItems.length && !filtered.length) return null

    return (
        <React.Fragment>
            <Tabs
                defaultValue={segmentedTabItems[0].key}
                value={activeTab}
                onValueChange={value =>
                    setActiveTab(value as CampaignSegmentedListTabKey)
                }
                className="w-full">
                <Tabs.List className="flex w-full gap-8">
                    {map(slice(segmentedTabItems, 0, 3), (tab, key) => (
                        <Tabs.Trigger key={key} value={tab.key}>
                            {tab.label}
                        </Tabs.Trigger>
                    ))}
                </Tabs.List>
                {map(segmentedTabItems, (tab, key) => (
                    <Tabs.Content
                        key={key}
                        value={tab.key}
                        className="duration-300 animate-in fade-in zoom-in-95">
                        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                            {map(slice(filtered, 0, 3), (campaign, key) => {
                                const Comp =
                                    key == 0 &&
                                    ScreenWidth > 1280 &&
                                    campaign.background_image
                                        ? CampaignItem.Card
                                        : CampaignItem.Box
                                return (
                                    <Comp
                                        key={key}
                                        className={
                                            key == 0 &&
                                            ScreenWidth > 1280 &&
                                            campaign.background_image
                                                ? 'col-span-2 xl:col-span-3'
                                                : 'w-full max-w-full'
                                        }
                                        type={campaign.campaign.point_earn_type}
                                        image={
                                            campaign.background_image
                                                ? campaign.background_image
                                                : ''
                                        }
                                        percent={
                                            campaign.campaign.point_percent
                                        }
                                        currency={campaign.company.currency}
                                        point={campaign.campaign.point_amount}
                                        isUsed={
                                            !campaign.campaign.multiple_usage &&
                                            campaign.campaign.user_used
                                        }
                                        description={campaign.title}
                                        actionButtonClick={() => {
                                            setOpen(!open)
                                            setSelectedCampaign(campaign.id)
                                        }}
                                    />
                                )
                            })}
                        </div>
                    </Tabs.Content>
                ))}
            </Tabs>

            <Sheet open={open} onOpenChange={setOpen}>
                <Sheet.Content className="overflow-y-scroll border-none px-0 py-0 pt-12 sm:px-0">
                    <Sheet.Header className="px-10">
                        <Sheet.Title className="text-semantic-content-inkInverse">
                            {t('@campaign.CampaignDetail.route_header_title')}
                        </Sheet.Title>
                    </Sheet.Header>
                    <CampaignDetail campaignId={selectedCampaign} />
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}

CampaignSegmentedList.displayName = 'CampaignSegmentedList'

CampaignSegmentedList.Loader = CampaignSegmentedListLoader

export default CampaignSegmentedList
