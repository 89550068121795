import { map } from 'lodash'
import baseApi from '@core/api'
import type {
    BalanceHistoryDetailsData,
    BalanceHistoryDetailsResponse,
    BalanceHistoryListData,
    BalanceHistoryListResponse,
    GiftCardCancelData,
    GiftCardCancelResponse,
    GiftCardFindUserData,
    GiftCardFindUserResponse,
    GiftCardMakeData,
    GiftCardMakeResponse,
    GiftCardMySentData,
    GiftCardMySentHistoryData,
    GiftCardMySentHistoryResponse,
    GiftCardMySentResponse,
    GiftCardSentToMeData,
    GiftCardSentToMeHistoryData,
    GiftCardSentToMeHistoryResponse,
    GiftCardSentToMeResponse,
    PointCouponApplyData,
    PointCouponApplyResponse,
    WalletByCompanyData,
    WalletByCompanyResponse,
    WalletDetailsData,
    WalletDetailsResponse,
    WalletLastEarnedDepositGiftPointsData,
    WalletLastEarnedDepositGiftPointsResponse,
    WalletPointWonCampaignData,
    WalletPointWonCampaignResponse,
    WalletPointWonListData,
    WalletPointWonListResponse,
    WalletPointWonStatsData,
    WalletPointWonStatsResponse,
} from './WalletApi.types'
import WalletApiUtils from './WalletApi.utils'

const walletApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        walletDetails: builder.query<WalletDetailsResponse, WalletDetailsData>({
            query: data => ({
                url: '/wallet/details/' + data.wallet_id,
                method: 'GET',
            }),
            providesTags: ['WalletDetail'],
            transformResponse: WalletApiUtils.transformWalletDetailsResponse,
        }),
        walletByCompany: builder.query<
            WalletByCompanyResponse,
            WalletByCompanyData
        >({
            query: data => ({
                url: `/wallet/by-company/${data.company_id}`,
                method: 'GET',
            }),
        }),
        balanceHistoryList: builder.query<
            BalanceHistoryListResponse,
            BalanceHistoryListData
        >({
            query: data => {
                const filters = data.filters ? Object.values(data.filters) : []

                const balanceFilters = map(
                    filters,
                    (item, index) => 'balance_filters[' + index + ']=' + item,
                ).join('&')

                return {
                    url: `/wallet/balance-history/list?${balanceFilters}`,
                    method: 'GET',
                    params: data,
                }
            },

            serializeQueryArgs: ({
                endpointName,
                // eslint-disable-next-line no-unused-vars
                queryArgs: { page, ...queryArgs },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.paginate = newData.meta.paginate
            },
            providesTags: ['BalanceHistoryList'],
            keepUnusedDataFor: 1,
        }),
        balanceHistoryDetails: builder.query<
            BalanceHistoryDetailsResponse,
            BalanceHistoryDetailsData
        >({
            query: data => ({
                url: `/wallet/balance-history/details/${data.id}`,
                method: 'GET',
            }),
            providesTags: ['BalanceHistoryDetail'],
        }),
        walletLastEarnedDepositGiftPoints: builder.query<
            WalletLastEarnedDepositGiftPointsResponse,
            WalletLastEarnedDepositGiftPointsData
        >({
            query: data => ({
                url: `/wallet/${data.wallet_id}/last-earned-deposit-gift-points`,
                method: 'GET',
            }),
            providesTags: ['WalletDetail'],
        }),
        walletPointWonCampaign: builder.query<
            WalletPointWonCampaignResponse,
            WalletPointWonCampaignData
        >({
            query: data => ({
                url: '/wallet/point/won/campaign',
                method: 'GET',
                params: data,
            }),
        }),
        pointCouponApply: builder.mutation<
            PointCouponApplyResponse,
            PointCouponApplyData
        >({
            query: data => ({
                url: '/wallet/point/coupon-apply',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_p, error) => (error ? [] : ['WalletDetail']),
        }),
        walletPointWonStats: builder.query<
            WalletPointWonStatsResponse,
            WalletPointWonStatsData
        >({
            query: data => ({
                url: '/wallet/point/won/stats',
                method: 'POST',
                body: data,
            }),
        }),
        walletPointWonList: builder.query<
            WalletPointWonListResponse,
            WalletPointWonListData
        >({
            query: data => ({
                url: '/wallet/point/won/list',
                method: 'POST',
                body: data,
            }),
            serializeQueryArgs: ({
                endpointName,
                queryArgs: {
                    // eslint-disable-next-line no-unused-vars
                    page,
                    ...queryArgs
                },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
        giftCardMake: builder.mutation<GiftCardMakeResponse, GiftCardMakeData>({
            query: data => ({
                url: '/wallet/gift-card/make',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) =>
                error ? [] : ['GiftCardMySent', 'WalletDetail'],
        }),
        giftCardFindUser: builder.mutation<
            GiftCardFindUserResponse,
            GiftCardFindUserData
        >({
            query: data => ({
                url: '/wallet/gift-card/find-user',
                method: 'POST',
                body: data,
            }),
        }),
        giftCardMySent: builder.query<
            GiftCardMySentResponse,
            GiftCardMySentData
        >({
            query: data => ({
                url: '/wallet/gift-card/my-sent',
                method: 'GET',
                params: data,
            }),
            providesTags: ['GiftCardMySent'],
            serializeQueryArgs: ({
                endpointName,
                // eslint-disable-next-line no-unused-vars
                queryArgs: { page, ...queryArgs },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.companies.push(...newData.meta.companies)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
        giftCardSentToMe: builder.query<
            GiftCardSentToMeResponse,
            GiftCardSentToMeData
        >({
            query: data => ({
                url: '/wallet/gift-card/sent-to-me',
                method: 'GET',
                params: data,
            }),
            serializeQueryArgs: ({
                endpointName,
                // eslint-disable-next-line no-unused-vars
                queryArgs: { page, ...queryArgs },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.companies.push(...newData.meta.companies)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
        giftCardMySentHistory: builder.query<
            GiftCardMySentHistoryResponse,
            GiftCardMySentHistoryData
        >({
            query: data => ({
                url: '/wallet/gift-card/my-sent-history',
                method: 'GET',
                params: data,
            }),
            serializeQueryArgs: ({
                endpointName,
                // eslint-disable-next-line no-unused-vars
                queryArgs: { page, ...queryArgs },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.companies.push(...newData.meta.companies)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
        giftCardSentToMeHistory: builder.query<
            GiftCardSentToMeHistoryResponse,
            GiftCardSentToMeHistoryData
        >({
            query: data => ({
                url: '/wallet/gift-card/sent-to-me-history',
                method: 'GET',
                params: data,
            }),
            serializeQueryArgs: ({
                endpointName,
                // eslint-disable-next-line no-unused-vars
                queryArgs: { page, ...queryArgs },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.companies.push(...newData.meta.companies)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
        giftCardCancel: builder.mutation<
            GiftCardCancelResponse,
            GiftCardCancelData
        >({
            query: data => ({
                url: '/wallet/gift-card/cancel',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) => (error ? [] : ['GiftCardMySent']),
        }),
    }),
    overrideExisting: true,
})

export default walletApi
