import * as React from 'react'
import { useMap } from 'react-leaflet'
import type { MapReadyHandlerProps } from './Map.types'

const MapReadyHandler: React.FC<MapReadyHandlerProps> = ({ onMapReady }) => {
    const map = useMap()

    React.useEffect(
        () => {
            map.whenReady(() =>
                onMapReady?.([map.getCenter().lat, map.getCenter().lng]),
            )
        },
        // eslint-disable-next-line
        [map],
    )

    return null
}

export default MapReadyHandler
