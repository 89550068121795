import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Switch } from '@components'
import i18n from '@core/translations'
import type { WalletCardRedeemItemProps } from './WalletCard.types'
import { cn } from '@core/utils'
import AvatarIcon from '../Avatar/AvatarIcon'

const WalletCardRedeemItem: React.FC<WalletCardRedeemItemProps> = ({
    icon,
    title,
    amount,
    value,
    currency,
    pointSwitchProps,
    AvatarIconStyle,
    onChangeValue,
}) => {
    const { t } = useTranslation()

    const formattedAmount = React.useMemo(() => {
        if (!currency) return amount

        return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
    }, [amount, currency])

    return (
        <div className="flex items-center justify-between">
            <div className="flex gap-3">
                <AvatarIcon
                    name={icon}
                    size={24}
                    className={cn(
                        'h-10 w-10 bg-solid-yellow-1 fill-solid-yellow-5',
                        AvatarIconStyle,
                    )}
                />

                <div className="flex flex-col">
                    <span className="text-body-sm-r text-semantic-content-ink">
                        {title}
                    </span>
                    <span className="text-body-lg-b text-semantic-content-ink">
                        {formattedAmount}
                    </span>
                </div>
            </div>

            <div className="flex items-center gap-2">
                <span className="text-label-md font-normal text-semantic-content-ink">
                    {t('content_config.WalletCard.placeholder_redeem')}
                </span>
                <Switch
                    checked={value}
                    onCheckedChange={onChangeValue}
                    {...pointSwitchProps}
                />
            </div>
        </div>
    )
}
export default WalletCardRedeemItem
