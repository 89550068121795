import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge, IconButton } from '@components'
import i18n from '@core/translations'
import type { EarnedItemPointProps } from './EarnedItem.types'
import { cn } from '@core/utils'
import EarnedItemRow from './EarnedItemRow'

const EarnedItemPoint: React.FC<EarnedItemPointProps> = ({
    icon = 'ticket',
    title,
    amount,
    balance,
    isExpired,
    isRevoked,
    createdDate,
    expireDate,
    currency,
    className,
    ...props
}) => {
    const { t } = useTranslation()

    const formattedUploadDate = React.useMemo(() => {
        return dayjs(createdDate).format('DD.MM.YYYY')
    }, [createdDate])

    const formattedExpireDate = React.useMemo(() => {
        return dayjs(expireDate).format('DD.MM.YYYY HH:mm')
    }, [expireDate])

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
    }, [amount, currency])

    const formattedBalance = React.useMemo(() => {
        return CurrencyFormatter.format(balance, i18n.getLocale(), currency)
    }, [balance, currency])

    return (
        <div className={cn('flex gap-3', className)} {...props}>
            <IconButton
                as="div"
                name={icon}
                className="shrink-0 bg-semantic-background-primary after:content-none"
                color="var(--content-ink-medium)"
            />

            <div className="flex-grow space-y-2 border-b border-semantic-background-tertiary pb-5 pt-4">
                <EarnedItemRow
                    title={title}
                    titleClassName="!text-body-md-b"
                    className="pb-1"
                    LayoutRightComponent={
                        isRevoked || isExpired || !balance ? (
                            <Badge
                                className="shrink-0"
                                children={
                                    isRevoked
                                        ? t('content_config.EarnedItem.revoked')
                                        : balance
                                          ? t(
                                                'content_config.EarnedItem.expired',
                                            )
                                          : t(
                                                'content_config.EarnedItem.no_point',
                                            )
                                }
                                variant="ink"
                                size="tiny"
                                disabled
                            />
                        ) : undefined
                    }
                />
                <EarnedItemRow
                    title={t('content_config.EarnedItem.amount')}
                    value={formattedAmount}
                />
                <EarnedItemRow
                    title={t('content_config.EarnedItem.balance')}
                    value={formattedBalance}
                />
                {!isRevoked && !isExpired && balance ? (
                    <React.Fragment>
                        <EarnedItemRow
                            title={t('content_config.EarnedItem.created_date')}
                            value={formattedUploadDate}
                        />
                        <EarnedItemRow
                            title={t('content_config.EarnedItem.expire_date')}
                            value={formattedExpireDate}
                        />
                    </React.Fragment>
                ) : null}
            </div>
        </div>
    )
}
export default EarnedItemPoint
