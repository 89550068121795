import * as React from 'react'
import { IconButton } from '@components'
import type { HeaderModulesMoreProps } from './Header.types'

const HeaderModulesMore: React.FC<HeaderModulesMoreProps> = ({
    onOpenChange,
    ...props
}) => {
    return (
        <IconButton
            name="more"
            variant="secondary-on-dark"
            className="!h-12 !w-12 shrink-0 !rounded-[13px] border border-tint-white-5 bg-tint-white-10"
            onClick={() => onOpenChange(true)}
            {...props}
        />
    )
}
export default HeaderModulesMore
