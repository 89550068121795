export default {
    BranchMap: {
        route_header_title: 'Şubeler',
        branch_detail_action_button: 'Tüm Şubeleri Göster',
        branch_detail_layout_right_button: 'Şubeyi Seç',
        branch_list_layout_right_button: 'Şubeyi Seç',
        branch_list_bottom_sheet_header: 'ŞUBELER',
        branch_list_bottom_sheet_header_button: 'Haritada Göster',
        location_permission_toast_message:
            'Konum izni vererek yakındakileri\ngörmek ister misin?',
        location_provider_error_toast_message: 'Cihazın konum ayarı kapalı',
        location_permission_toast_chip_label: 'İzin Ver',
    },
}
