import * as React from 'react'
import { useLocation } from 'react-router'
import { filter } from 'lodash'
import type { CompanyModules } from '@company/api'
import walletApi from '@wallet/api'
import { WalletDetailLoader } from '@wallet/components'
import EarnedSection from '@wallet/views/WalletDetail/EarnedSection.tsx'
import CampaignSection from './CampaignSection'
import HistorySection from './HistorySection'
import OrdersSection from './OrdersSection'
import WalletDetailHeader from './WalletDetailHeader'
import './WalletDetail.css'

const WalletDetail = () => {
    const location = useLocation().state
    const walletId =
        location?.walletId || localStorage.getItem('user.wallet_id')

    const walletDetailsQuery = walletApi.useWalletDetailsQuery(
        { wallet_id: walletId },
        { refetchOnMountOrArgChange: true },
    )

    const wallet = React.useMemo(
        () => walletDetailsQuery.data?.data,
        [walletDetailsQuery.data],
    )

    const modules = React.useMemo(() => {
        return filter(
            walletDetailsQuery.data?.meta.waas_modules_sort_list,
            item => item.status,
        )
    }, [walletDetailsQuery.data?.meta])

    const deliveryModules = React.useMemo(() => {
        return walletDetailsQuery.data?.data.company.modules as CompanyModules
    }, [walletDetailsQuery.data?.data.company.modules])

    if (walletDetailsQuery.isLoading) return <WalletDetailLoader />

    if (!wallet) return null

    return (
        <React.Fragment>
            <WalletDetailHeader
                title={wallet.company.name}
                balance={wallet.balance}
                point={wallet.point}
                currency={wallet.company.currency}
                walletId={walletId}
                companyId={wallet.company_id}
                modules={modules}
                deliveryModules={deliveryModules}
            />
            <EarnedSection
                walletId={walletId}
                currency={wallet.company.currency}
            />
            <OrdersSection walletId={walletId} />
            <CampaignSection
                walletId={walletId}
                companyId={wallet.company_id}
                currency={wallet.company.currency}
            />
            <HistorySection walletId={walletId} />
        </React.Fragment>
    )
}

export default WalletDetail
