import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type { AdditionalNoteSectionProps } from './OrderDetail.types'

const AdditionalNoteSection: React.FC<AdditionalNoteSectionProps> = ({
    note,
}) => {
    const { t } = useTranslation()

    if (!note) return null

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.additional_note_section_title')}
            </h3>
            <div className="rounded-[20px] bg-semantic-background-primary px-4 py-6">
                <p className="text-body-md-b text-semantic-content-inkMedium">
                    {note}
                </p>
            </div>
        </div>
    )
}
export default AdditionalNoteSection
