import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { Button, Map, Sheet, ToolBar } from '@components'
import baseApi from '@core/api'
import type { AddressMapProps } from './AddressMap.types'
import AddressMarker from './AddressMarker'

const AddressMap: React.FC<AddressMapProps> = ({
    onStateChange,
    onStepperChange,
}) => {
    const { t } = useTranslation()

    const [draggablePosition, setDraggablePosition] = React.useState<
        [number, number] | null
    >(null)

    const [reverseLocationQuery, reverseLocationResult] =
        baseApi.useLazyReverseLocationQuery()

    const address = React.useMemo(() => {
        return reverseLocationResult.data?.data
    }, [reverseLocationResult.data?.data])

    const handleRegionChangeComplete = React.useCallback(
        async (regionResult: [number, number]) => {
            setDraggablePosition([regionResult[0], regionResult[1]])

            await reverseLocationQuery({
                'location[lat]': regionResult[0],
                'location[lng]': regionResult[1],
            })
        },
        [reverseLocationQuery],
    )

    const handleComplete = React.useCallback(() => {
        onStateChange({ address: address!, region: draggablePosition! })

        onStepperChange('address')
    }, [address, draggablePosition, onStateChange, onStepperChange])

    return (
        <React.Fragment>
            <Sheet.Header className="px-6 pt-12">
                <Sheet.Title
                    children={t('@user.AddressMap.route_header_title')}
                />
                <Sheet.Description
                    children={t('@user.AddressMap.route_header_description')}
                />
            </Sheet.Header>

            <Map
                center={[41.0082, 28.9784]}
                zoom={13}
                className="h-[calc(100vh-289px)]"
                onMapReady={handleRegionChangeComplete}>
                <AddressMarker
                    address={address}
                    loading={reverseLocationResult.isFetching}
                    draggablePosition={draggablePosition}
                    onRegionChangeComplete={debounce(
                        handleRegionChangeComplete,
                        500,
                    )}
                />
            </Map>

            <ToolBar className="sticky bottom-0 left-0 w-full">
                <Button
                    type="submit"
                    className="w-full"
                    disabled={!address || !draggablePosition}
                    children={t('@user.AddressMap.submit_location_button')}
                    onClick={handleComplete}
                />
            </ToolBar>
        </React.Fragment>
    )
}
export default AddressMap
