export default {
    DeliveryOption: {
        bottom_sheet_section_bar_title: 'Select Delivery',
        bottom_sheet_section_bar_description:
            'Select the delivery method for your order',
    },
    MyOrders: {
        header_title: 'My Orders',
        header_description:
            'You can access your orders and check the status of your orders.',
        header_filter_all: 'All',
        header_filter_active: 'Active Orders',
        header_filter_past: 'Past Orders',
        empty_sheet_title: 'No Order Found',
        empty_sheet_description: 'No previous orders found',
        empty_sheet_description_filtered:
            'No orders found matching the filters',
        right_info_title: 'My Orders',
        right_info_description:
            'On the My Orders screen, you can track summary information about the orders you have previously created. Your orders are displayed as cards along with status information, wallet usage, images of purchased products, and their amounts. When you click on an order card, you can view detailed information about the order.',
    },
    OrderLocation: {
        alert_error_title: 'Oh No!',
        alert_error_message:
            'Unfortunately, the Merchant does not serve the selected address',
        alert_error_action_button: 'Choose Another Address',
        alert_error_action_reject_button: 'Cancel',
        address_list_section_title: 'My Registered Addresses',
        address_list_section_bar_title: 'Set Location',
        address_list_section_bar_description:
            'You can add or delete addresses to access the product list',
        footer_forward_button: 'Next',
    },
    DeliverySummary: {
        route_header_title: 'Make Payment',
        right_info_title: 'Payment Summary',
        right_info_description:
            'On the payment summary screen, you can view all the details of your order. This screen includes the quantity and amount of the products you added to the cart, the delivery address you selected, additional costs such as delivery fee differences and bag fees, if applicable. You can also provide special instructions to the courier, such as "Leave at my door" or "Do not ring the bell." During the payment process, your balance and points are displayed, and if you wish, you can use your points to complete the payment.',
        additional_note_section_title: 'ADD NOTE',
        additional_note_placeholder: 'You can write your order note here',
        agreement_section_link_text_highlighted:
            'Pre-Information Form and Distance Sales Agreement',
        agreement_section_link_text: 'I have read and accept',
        agreement_link_page_title: 'Pre-Information Form',
        delivery_location_section_title: 'DELIVERY ADDRESS',
        delivery_location_section_description:
            'Your products will be delivered from the branch',
        leave_order_at_door_label: 'Leave Order at the Door',
        do_not_ring_doorbell_label: 'Do Not Ring the Doorbell',
        delivery_method_section_title: 'DELIVERY METHOD',
        delivery_method_address: 'Delivery to Address',
        delivery_method_takeaway: 'Takeaway',
        delivery_method_table: 'Order to Table',
        toast_payment_success_message:
            'Your order has been successfully placed',
        toast_point_usage_sufficient_message:
            'You do not have any points available for redeeming',
        payment_summary_section_title: 'PAYMENT INFORMATION',
        payment_summary_products_title: 'Products',
        payment_summary_subtotal_label: 'Subtotal',
        payment_summary_paid_point_label: 'Points Used',
        payment_summary_delivery_amount_label: 'Delivery Fee',
        table_section_title: 'YOUR TABLE NUMBER',
        table_section_text_field_message:
            'Please enter your table number for order confirmation',
        payment_summary_discount_label: 'Discount',
        wallet_section_title: 'WALLET',
        tool_bar_footer_total_amount_label: 'Total Amount',
        tool_bar_footer_pay_button: 'Pay',
        toast_success_message: 'Balance load was successful',
        toast_error_message: 'You need to load your balance to make a payment',
        direction_chip_title: 'Directions',
    },
    OrderBranchMap: {
        route_header_title: 'Branch Map',
        route_header_description:
            'Select the nearest branch to create your order.',
    },
    OrderDetail: {
        route_header_title: 'Order Detail',
        route_header_description:
            'You can view all details about the content and status of your order on this page.',
        additional_note_section_title: 'ORDER NOTE',
        branch_section_title: 'SELLER / BRANCH',
        delivery_address_section_title: 'DELIVERY ADDRESS',
        delivery_detail_section_title: 'DELIVERY DETAILS',
        refund_detail_section_title: 'REFUND DETAILS',
        delivery_type_label: 'Delivery Type',
        order_no_label: 'Order No',
        order_date_label: 'Order Date',
        delivery_date_label: 'Delivery Date',
        delivery_type_address: 'Home Delivery',
        delivery_type_table: 'Order to Table',
        delivery_type_takeaway: 'Takeaway',
        leave_at_door: 'Leave at Door',
        do_not_ring_bell: 'Do Not Ring the Bell',
        status_section_title: 'ORDER STATUS',
        payment_summary_section_title: 'PAYMENT INFORMATION',
        payment_summary_products_label: 'Products',
        payment_summary_paid_amount_label: 'Total Amount',
        payment_summary_gift_point_label: 'Points Used',
        payment_summary_delivery_amount_label: 'Delivery Fee',
        payment_summary_discount_amount_label: 'Discount Amount',
        products_section_title: 'PRODUCTS IN YOUR CART',
    },
    OrderRefund: {
        route_header_title: 'Order Cancellation',
        toast_cancel_success_message:
            'Your order has been successfully canceled',
        cancel_reason_section_title: 'REASON FOR CANCELLATION',
        cancel_reason_comment_title: 'CANCELLATION DESCRIPTION',
        cancel_reason_comment_placeholder:
            'Enter your cancellation description',
        cancel_order_button: 'Cancel Order',
    },
    Products: {
        route_header_title: 'Products',
        route_header_description: 'Select your products and create your order',
        right_info_title: 'How Do Products Work?',
        right_info_description:
            'Products are listed by category. You can view the products available for order by selecting the category you are interested in. You can use the + button in the product list to specify the quantity of the product you want to add to the cart. After adding products to the cart, you can see the quantity of added products and the total amount at the bottom of the page. If the + button is no longer visible, it means the maximum cart addition limit for the selected product has been reached.',
    },
}
