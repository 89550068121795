import i18n from '@core/translations'
import type { DeliveryType } from '@order/api'
import type {
    GetDeliveryDetailsFunction,
    GetOrderLocationPropsFunction,
} from './DeliverySummary.types'
import DeliveryLocationSectionFooter from './DeliveryLocationSectionFooter'

export const getOrderLocationProps: GetOrderLocationPropsFunction = props => {
    if (props.userAddress && props.deliveryType === 'address') {
        return {
            title: props.userAddress.title,
            description: props.userAddress.full_address,
            latitude: props.userAddress.lat,
            longitude: props.userAddress.lng,
            showMap: true,
            FooterComponentProps: { className: 'py-0' },
            FooterComponent: (
                <DeliveryLocationSectionFooter
                    leaveOrderAtDoor={props.leaveOrderAtDoor}
                    ringDoorbell={props.ringDoorbell}
                    onChangeState={props.onChangeState}
                />
            ),
        }
    }

    return {
        title: `${props.company.name}, ${props.branch.name}`,
        description: i18n.t(
            '@order.DeliverySummary.delivery_location_section_description',
        ),
    }
}

export const getDeliveryDetails: GetDeliveryDetailsFunction = (
    type: DeliveryType,
) => {
    if (type === 'address')
        return {
            leftIcon: 'delivery-scooter',
            title: i18n.t('@order.DeliverySummary.delivery_method_address'),
            width: 0,
        }
    if (type === 'takeaway')
        return {
            leftIcon: 'delivery-people',
            title: i18n.t('@order.DeliverySummary.delivery_method_takeaway'),
            width: undefined,
        }

    return {
        leftIcon: 'restaurant-sector',
        title: i18n.t('@order.DeliverySummary.delivery_method_table'),
        width: undefined,
    }
}
