import { CreditCardFormatter } from '@macellan/formatter'
import type {
    GetCreditCardMaskFunction,
    GetCVCMaskFunction,
    GetDateTimeMaskFunction,
    GetOnlyNumbersMaskFunction,
} from './Input.types'

export const getOnlyNumbersMask: GetOnlyNumbersMaskFunction = maskLength => {
    return `[${'#'.repeat(maskLength)}]`
}

export const getDateTimeMask: GetDateTimeMaskFunction = (pattern: string) => {
    const chars = ['D', 'M', 'Y', 'h', 'm', 's']
    chars.forEach(item => (pattern = pattern?.replaceAll(item, '#')))
    return pattern.replace(/(#+)/g, '$1')
}

export const getCreditCardMask: GetCreditCardMaskFunction = (value: string) => {
    const issuer = CreditCardFormatter.getIssuer(value)

    if (issuer?.name === 'amex') return '[####] [######] [#####]'

    return '#### #### #### ####'
}

export const getCVCMask: GetCVCMaskFunction = (referenceValue: string) => {
    const cvcLength =
        CreditCardFormatter.getIssuer(referenceValue)?.cvcLength ?? 3
    return getOnlyNumbersMask(cvcLength)
}
