import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import type { CurrencyCode } from '@macellan/formatter'
import type { IconsNameType } from '@components'
import { useAppSelector } from '@core/store'
import type { CompanyModules } from '@company/api'
import orderApi from '@order/api'
import type { WalletModuleName } from '@wallet/api'
import { emitter } from '@core/utils'

export interface WalletModuleItem {
    title?: string
    icon?: IconsNameType
    // eslint-disable-next-line no-unused-vars
    callback?: (params: never) => void
    state?: boolean
}

export interface WalletModuleParams {
    index?: number
    walletId: number
    companyId: number
    currency: CurrencyCode
    deliveryModules: CompanyModules
}

const useWalletModule = (
    name: WalletModuleName,
    params: WalletModuleParams,
) => {
    const t = useTranslation().t
    const navigate = useNavigate()

    const platform = useAppSelector(state => state.baseSlice.platform)

    const [orderMenuQuery] = orderApi.useLazyOrderMenuQuery()

    const handleNavigateProducts = React.useCallback(async () => {
        if (
            name === 'products' &&
            !params.deliveryModules.enable_address_delivery &&
            !params.deliveryModules.enable_table_delivery &&
            !params.deliveryModules.enable_takeaway_delivery
        ) {
            try {
                const result = await orderMenuQuery({
                    wallet_id: params.walletId,
                    delivery_type: undefined,
                    branch_id: undefined,
                }).unwrap()

                navigate('/products', {
                    state: {
                        uri: result.data.url,
                        branchId: result.data.branch_id,
                        companyId: params.companyId,
                        walletId: params.walletId,
                        addressId: undefined,
                        deliveryType: undefined,
                    },
                })
            } catch (e: unknown) {
                const error = e as Error

                if ('status' in error) {
                    if (error.status !== 404) return
                }
            }
            return
        }

        return emitter.emit('OPEN_DELIVERY_OPTION', {
            walletId: params.walletId,
            companyId: params.companyId,
            name: name,
        })
    }, [name, navigate, orderMenuQuery, params])

    return React.useMemo<WalletModuleItem | undefined>(() => {
        if (name === 'payment' && isEqual(platform, 'mobile_waas'))
            return {
                title: t('@wallet.WalletModules.payment'),
                icon: 'wallet',
                callback: () => navigate('/payment-qr'),
            }

        if (name === 'coupon') {
            return {
                title: t('@wallet.CouponApply.coupon_add_button'),
                icon: 'coupon',
                callback: () => emitter.emit('OPEN_COUPON_APPLY'),
            }
        }

        if (name === 'deposit') {
            return {
                title: t('@wallet.WalletModules.deposit'),
                icon: 'plus-bold',
                callback: () =>
                    navigate('/topup', {
                        state: { walletId: params.walletId },
                    }),
            }
        }

        if (name === 'gift_card') {
            return {
                title: t('@wallet.WalletModules.gift_card'),
                icon: 'gift',
                callback: () => navigate('/gift-card'),
            }
        }

        if (name === 'products') {
            return {
                title: t('@wallet.WalletModules.products'),
                icon: 'market',
                callback: handleNavigateProducts,
            }
        }
    }, [name, platform, t, navigate, params.walletId, handleNavigateProducts])
}

export default useWalletModule
