import type { AccountItemProps } from './Account.types'

export const ACCOUNTS_ITEMS: AccountItemProps[] = [
    {
        icon: 'people',
        title: '@user.Account.application_addresses',
        to: '/addresses',
    },
    {
        icon: 'my-orders',
        title: '@user.Account.application_orders',
        to: '/my-orders',
    },
]
