import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@core/utils'

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
            'inline-flex h-[33px] items-center justify-center whitespace-nowrap border-b-[1.6px] border-transparent pb-2 !text-display-sm text-semantic-content-inkLight ring-offset-solid-ink-3 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-semantic-content-ink focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-solid-ink-3 data-[state=active]:text-semantic-content-ink',
            className,
        )}
        {...props}
    />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

export default TabsTrigger
