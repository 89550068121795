import { createBrowserRouter, Navigate, type RouteObject } from 'react-router'
import { Layout } from '@core/layout'
import { CampaignRouter } from '@campaign/router'
import { ErrorRouter } from '@common/router'
import { DepositRouter } from '@deposit/router'
import { OrderRouter } from '@order/router'
import { PaymentRouter } from '@payment/router'
import { WalletRouter } from '@wallet/router'
import { UserRouter } from '@user/router'
import { Initialize } from '@user/views'

const routes: RouteObject[] = [
    {
        path: '/init',
        element: <Initialize />,
    },
    {
        element: <Layout />,
        children: [
            ...CampaignRouter,
            ...DepositRouter,
            ...WalletRouter,
            ...OrderRouter,
            ...PaymentRouter,
            ...UserRouter,
        ], // This is the Auth component
    },
    {
        children: [...ErrorRouter],
    },
    {
        path: '*',
        element: <Navigate to="/404" replace />,
    },
]

export default routes

export const router = createBrowserRouter(routes)
