import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderLocation } from '@components'
import type { DeliveryAddressSectionProps } from './OrderDetail.types'
import { getOrderLocationProps } from './OrderDetail.utils'

const DeliveryAddressSection: React.FC<DeliveryAddressSectionProps> = ({
    userAddress,
    branch,
    deliveryType,
}) => {
    const { t } = useTranslation()

    const orderLocationProps = React.useMemo(() => {
        return getOrderLocationProps({ branch, deliveryType, userAddress })
    }, [branch, deliveryType, userAddress])

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.delivery_address_section_title')}
            </h3>
            <OrderLocation
                showMap
                FooterComponentProps={{
                    className: '!text-body-md-r text-semantic-content-ink',
                }}
                {...orderLocationProps}
            />
        </div>
    )
}
export default DeliveryAddressSection
