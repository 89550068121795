import * as React from 'react'
import { Stepper } from '@components'
import orderApi from '@order/api'
import { OrderRefund } from '@order/views'
import walletApi from '@wallet/api'
import type { OrderDetailProps, OrderDetailStepper } from './OrderDetail.types'
import { getProductPrice } from './OrderDetail.utils'
import AdditionalNoteSection from './AdditionalNoteSection'
import BranchSection from './BranchSection'
import DeliveryAddressSection from './DeliveryAddressSection'
import DeliveryDetailSection from './DeliveryDetailSection'
import OrderDetailHeader from './OrderDetailHeader'
import OrderRefundSection from './OrderRefundSection'
import OrderStatusSection from './OrderStatusSection'
import PaymentSummarySection from './PaymentSummarySection'
import ProductsSection from './ProductsSection'

const OrderDetail: React.FC<OrderDetailProps> = ({
    orderId,
    onLoadingChange,
}) => {
    const [stepper, setStepper] = React.useState<OrderDetailStepper>('details')

    const orderDetailQuery = orderApi.useOrderHistoryDetailQuery(
        { id: orderId },
        { refetchOnMountOrArgChange: true },
    )

    const orderDetail = React.useMemo(() => {
        return orderDetailQuery.data?.data
    }, [orderDetailQuery.data])

    const walletQuery = walletApi.useWalletByCompanyQuery(
        { company_id: orderDetail?.company.id ?? 0 },
        { skip: !orderDetail?.company.id },
    )

    const wallet = React.useMemo(() => {
        return walletQuery.data?.data
    }, [walletQuery.data])

    React.useEffect(
        () => {
            onLoadingChange(orderDetailQuery.isLoading || walletQuery.isLoading)
        },
        // eslint-disable-next-line
        [orderDetailQuery.isLoading, walletQuery.isLoading],
    )

    return (
        <React.Fragment>
            <OrderDetailHeader stepper={stepper} onStepperChange={setStepper} />

            <Stepper activeStep={stepper}>
                <Stepper.Step step="details">
                    {orderDetail && wallet && (
                        <div className="space-y-10">
                            <BranchSection
                                name={wallet.company.name}
                                logo={wallet.company.logo}
                                color={wallet.company.color}
                                branch={orderDetail.branch.name}
                                currency={wallet.company.currency}
                                balance={wallet.balance}
                                point={wallet.point}
                            />
                            <OrderStatusSection
                                deliveryType={orderDetail.delivery_type}
                                status={orderDetail.status}
                                buttonProps={{
                                    onClick: () => setStepper('refund'),
                                }}
                            />
                            <OrderRefundSection
                                refundRequests={
                                    orderDetail.payment.refund_requests
                                }
                                currency={wallet.company.currency}
                            />
                            <DeliveryDetailSection
                                deliveryType={orderDetail.delivery_type}
                                orderNo={orderDetail.order_no}
                                createdAt={orderDetail.created_at}
                                deliveredAt={orderDetail.delivery_at}
                            />
                            <ProductsSection
                                products={orderDetail.products}
                                currency={wallet.company.currency}
                            />
                            <DeliveryAddressSection
                                branch={orderDetail.branch}
                                userAddress={orderDetail.user_address}
                                deliveryType={orderDetail.delivery_type}
                            />
                            <AdditionalNoteSection note={orderDetail.note} />
                            <PaymentSummarySection
                                totalPrice={orderDetail.final_total}
                                productPrice={getProductPrice(
                                    orderDetail.products,
                                )}
                                paidPrice={orderDetail.paid_price}
                                paidPoint={orderDetail.paid_point}
                                discountPoint={
                                    orderDetail.payment.discount_point
                                }
                                currency={wallet.company.currency}
                                deliveryAmount={orderDetail.delivery_amount}
                                deliveryDiscount={orderDetail.delivery_discount}
                                deliveryType={orderDetail.delivery_type}
                            />
                        </div>
                    )}
                </Stepper.Step>
                <Stepper.Step step="refund">
                    <OrderRefund
                        orderId={orderId}
                        onStepperChange={setStepper}
                    />
                </Stepper.Step>
            </Stepper>
        </React.Fragment>
    )
}
export default OrderDetail
