export default {
    Account: {
        application_addresses: 'Adreslerim',
        application_orders: 'Siparişlerim',
    },
    AddressClosest: {
        info_sheet_title: 'Konumuna daha yakın bir adres mevcut!',
        info_sheet_description:
            'Bulunduğun konuma daha yakın {{address}} adında bir adresin mevcut. Bu adresi kullanmak\nister misin?',
        info_sheet_accept_button: 'En Yakın Adresi Kullan',
        info_sheet_reject_button: 'Mevcut Adres İle Devam Et',
        alert_error_title: 'Hay Aksi!',
        alert_error_message:
            'Maalesef Üye İş Yeri, seçtiğin adrese hizmet vermemektedir',
        alert_error_action_button: 'Başka Adres Seç',
        alert_error_action_reject_button: 'Vazgeç',
    },
    AddressCreate: {
        route_header_title: 'Yeni Adres Ekle',
        route_header_description:
            'Ürün listelerine ulaşmak için adres ekleyebilir ya da adresleri düzenleyebilirsiniz.',
        success_toast_message: 'Adresin başarıyla eklendi',
        check_box_title: 'Varsayılan adresim olarak belirle',
        form_label_address_title: 'Adres Başlığı',
        form_label_street: 'Sokak/Cadde',
        form_label_building_number: 'Bina No',
        form_label_floor: 'Kat',
        form_label_door_number: 'Daire No',
        form_label_directions: 'Adres Tarifi',
        form_placeholder_first_name: 'Adınız',
        form_placeholder_last_name: 'Soyadınız',
        form_placeholder_phone: 'Cep Telefonu',
        row_title_contact_information: 'İLETİŞİM BİLGİLERİ',
        dropdown_field_label: 'İl, İlçe, Mahalle',
        dropdown_field_city_modal_header_title: 'İl Seç',
        dropdown_field_district_modal_header_title: 'İlçe Seç',
        dropdown_field_neighborhoods_modal_header_title: 'Mahalle Seç',
        submit_button: 'Kaydet',
        alert_error_title: 'Hay Aksi!',
        alert_error_message:
            'Maalesef Üye İş Yeri, seçtiğin adrese hizmet vermemektedir',
        alert_error_action_button: 'Başka Adres Seç',
        alert_error_action_reject_button: 'Vazgeç',
    },
    Addresses: {
        right_info_title: 'Adreslerim nasıl çalışır?',
        right_info_description:
            'Adreslerim alanında, Adrese Teslim siparişleriniz için teslimat adreslerinizi kaydedebilirsiniz. Adreslerinizi daha kolay tanıyabilmek için isim verebilirsiniz. Bir adres eklerken il, ilçe, mahalle, açık adres, bina numarası, kapı numarası ve kat bilgilerini girebilirsiniz. Eklediğiniz adreslerden birini ön tanımlı adres olarak belirleyebilir, mevcut adreslerinizi silebilir veya yeni adresler ekleyebilirsiniz. Sistemde en fazla 10 adres kaydedebilirsiniz.',
        address_list_section_title: 'Kayıtlı Adreslerim',
        address_list_section_bar_title: 'Adreslerim',
        address_list_section_bar_description:
            'Ürün listesine ulaşmak için adres ekleyebilir ya da silebilirsin',
    },
    AddressMap: {
        route_header_title: 'Adresini Haritadan Seç',
        route_header_description:
            'Hızlı ve doğru adres eklemek için konumunuzu harita üzerinden belirleyin.',
        submit_location_button: 'Bu Konumu Kullan',
        search_field_placeholder: 'Mahalle, sokak veya cadde ara',
    },
    AddressList: {
        success_toast_message: 'Adres bilgin başarıyla silindi',
        alert_delete_address_title: 'Adres Silme İşlemi',
        alert_delete_address_message:
            'Seçilen adresi silmek istediğine emin misin?',
        alert_delete_action_button: 'Adresi Sil',
        alert_delete_action_cancel_button: 'Vazgeç',
        list_empty_component_title: 'Adres Bulunamadı',
        list_empty_component_description:
            'Kayıtlı adresin olduğunda burada listelenecektir',
        address_list_footer_button: 'Yeni Adres Ekle',
        toast_provider_error_message: 'Maksimum adres sınırına ulaşıldı ',
    },
}
