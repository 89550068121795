import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { omit } from 'lodash'
import { Button, Input, Row, Sheet, ToolBar } from '@components'
import baseApi from '@core/api'
import userApi from '@user/api'
import type {
    AddressCreateForm,
    AddressCreateProps,
} from './AddressCreate.types'
import { addressValidationSchema } from './AddressCreate.validations'
import AddressDropdownFields from './AddressDropdownFields'

const AddressCreate: React.FC<AddressCreateProps> = ({
    state,
    onOpenChange,
}) => {
    const { t } = useTranslation()

    const meResult = baseApi.useMeQuery(undefined)
    const [addressCreate, addressCreateResult] =
        userApi.useAddressCreateMutation()

    const region = React.useMemo(() => {
        return state.region
    }, [state.region])

    const userInfo = React.useMemo(() => {
        return meResult.data?.data
    }, [meResult.data?.data])

    const handleSubmit = React.useCallback(
        async (formValues: AddressCreateForm) => {
            const [cityId, districtId, neighborhoodId] = formValues.address

            if (!cityId || !districtId || !neighborhoodId) return

            const values = omit(formValues, ['address'])

            await addressCreate({
                ...values,
                city_id: cityId,
                district_id: districtId,
                neighborhood_id: neighborhoodId,
                location: {
                    lat: formValues.location?.lat ?? region[0],
                    lng: formValues.location?.lng ?? region[1],
                },
            }).unwrap()

            return onOpenChange(false)
        },
        [addressCreate, onOpenChange, region],
    )

    const formik = useFormik({
        initialValues: {
            title: '',
            first_name: '',
            last_name: '',
            address: [
                state.address.city_id,
                state.address.district_id,
                state.address.neighborhood_id,
            ],
            street: state.address.street ?? '',
            phone: '',
            building_number: '',
            floor: '',
            door_number: '',
            directions: '',
            is_default: false,
            location: {
                lat: region[0],
                lng: region[1],
            },
        },
        validationSchema: addressValidationSchema,
        onSubmit: handleSubmit,
    })

    React.useEffect(
        () => {
            if (userInfo) {
                formik.setValues({
                    ...formik.values,
                    first_name: userInfo.first_name,
                    last_name: userInfo.last_name,
                    phone: userInfo.phone,
                })
            }
        },
        // eslint-disable-next-line
        [userInfo],
    )

    return (
        <React.Fragment>
            <Sheet.Header className="px-6 pt-12">
                <Sheet.Title
                    children={t('@user.AddressCreate.route_header_title')}
                />
                <Sheet.Description
                    children={t('@user.AddressCreate.route_header_description')}
                />
            </Sheet.Header>

            <form className="space-y-7" onSubmit={formik.handleSubmit}>
                <div className="space-y-4 px-6">
                    <Input
                        autoFocus={true}
                        autoCapitalize="words"
                        label={t(
                            '@user.AddressCreate.form_label_address_title',
                        )}
                        error={!!formik.errors.title && !!formik.touched.title}
                        helperText={
                            formik.touched.title && formik.errors.title
                                ? formik.errors.title
                                : undefined
                        }
                        value={formik.values.title}
                        onBlur={formik.handleBlur('title')}
                        onChange={formik.handleChange('title')}
                    />

                    <AddressDropdownFields
                        cityId={formik.values.address[0]}
                        districtId={formik.values.address[1]}
                        neighborhoodId={formik.values.address[2]}
                        onChangeValue={(value, location) => {
                            formik.setFieldValue('address', value)
                            formik.setFieldValue('location', location)
                        }}
                    />

                    <Input
                        label={t('@user.AddressCreate.form_label_street')}
                        error={
                            !!formik.errors.street && !!formik.touched.street
                        }
                        helperText={
                            formik.touched.street && formik.errors.street
                                ? formik.errors.street
                                : undefined
                        }
                        value={formik.values.street}
                        onBlur={formik.handleBlur('street')}
                        onChange={formik.handleChange('street')}
                    />

                    <div className="grid grid-cols-3 gap-1 overflow-hidden">
                        <Input
                            label={t(
                                '@user.AddressCreate.form_label_building_number',
                            )}
                            error={
                                !!formik.errors.building_number &&
                                !!formik.touched.building_number
                            }
                            helperText={
                                formik.touched.building_number &&
                                formik.errors.building_number
                                    ? formik.errors.building_number
                                    : undefined
                            }
                            inputClassName="w-full"
                            value={formik.values.building_number}
                            onBlur={formik.handleBlur('building_number')}
                            onChange={formik.handleChange('building_number')}
                        />
                        <Input
                            pattern="[0-9]*"
                            label={t('@user.AddressCreate.form_label_floor')}
                            error={
                                !!formik.errors.floor && !!formik.touched.floor
                            }
                            helperText={
                                formik.touched.floor && formik.errors.floor
                                    ? formik.errors.floor
                                    : undefined
                            }
                            inputClassName="w-full"
                            value={formik.values.floor}
                            onBlur={formik.handleBlur('floor')}
                            onChange={formik.handleChange('floor')}
                        />
                        <Input
                            autoCapitalize="words"
                            label={t(
                                '@user.AddressCreate.form_label_door_number',
                            )}
                            error={
                                !!formik.errors.door_number &&
                                !!formik.touched.door_number
                            }
                            helperText={
                                formik.touched.door_number &&
                                formik.errors.door_number
                                    ? formik.errors.door_number
                                    : undefined
                            }
                            inputClassName="w-full"
                            value={formik.values.door_number}
                            onBlur={formik.handleBlur('door_number')}
                            onChange={formik.handleChange('door_number')}
                        />
                    </div>

                    <Input
                        label={t('@user.AddressCreate.form_label_directions')}
                        error={
                            !!formik.errors.directions &&
                            !!formik.touched.directions
                        }
                        helperText={
                            formik.touched.directions &&
                            formik.errors.directions
                                ? formik.errors.directions
                                : undefined
                        }
                        value={formik.values.directions}
                        onBlur={formik.handleBlur('directions')}
                        onChange={formik.handleChange('directions')}
                    />
                </div>

                <hr className="border-semantic-background-tertiary" />

                <Row className="space-y-2 px-6">
                    <Row.Title
                        title={t(
                            '@user.AddressCreate.row_title_contact_information',
                        )}
                    />

                    <div className="grid grid-cols-2 gap-1 pt-2">
                        <Input
                            placeholder={t(
                                '@user.AddressCreate.form_placeholder_first_name',
                            )}
                            error={
                                !!formik.errors.first_name &&
                                !!formik.touched.first_name
                            }
                            helperText={
                                formik.touched.first_name &&
                                formik.errors.first_name
                                    ? formik.errors.first_name
                                    : undefined
                            }
                            value={formik.values.first_name}
                            onBlur={formik.handleBlur('first_name')}
                            onChange={formik.handleChange('first_name')}
                        />
                        <Input
                            placeholder={t(
                                '@user.AddressCreate.form_placeholder_last_name',
                            )}
                            error={
                                !!formik.errors.last_name &&
                                !!formik.touched.last_name
                            }
                            helperText={
                                formik.touched.last_name &&
                                formik.errors.last_name
                                    ? formik.errors.last_name
                                    : undefined
                            }
                            value={formik.values.last_name}
                            onBlur={formik.handleBlur('last_name')}
                            onChange={formik.handleChange('last_name')}
                        />
                    </div>

                    <Input
                        type="number"
                        pattern="[0-9]*"
                        placeholder={t(
                            '@user.AddressCreate.form_placeholder_phone',
                        )}
                        error={!!formik.errors.phone && !!formik.touched.phone}
                        helperText={
                            formik.touched.phone && formik.errors.phone
                                ? formik.errors.phone
                                : undefined
                        }
                        value={formik.values.phone}
                        onBlur={formik.handleBlur('phone')}
                        onChange={formik.handleChange('phone')}
                    />
                </Row>

                <ToolBar className="sticky bottom-0 left-0 w-full">
                    <Button
                        type="submit"
                        className="w-full"
                        loading={addressCreateResult.isLoading}
                        disabled={!formik.isValid || !formik.dirty}
                        children={t('@user.AddressCreate.submit_button')}
                        onClick={() => formik.handleSubmit()}
                    />
                </ToolBar>
            </form>
        </React.Fragment>
    )
}

export default AddressCreate
