import * as React from 'react'
import { useWalletModule } from '@wallet/hooks'
import type { HeaderModulesProps } from './Header.types.ts'
import { ModuleButton } from './HeaderModules.utils'

const HeaderModules: React.FC<HeaderModulesProps> = ({
    name,
    params,
    ...props
}) => {
    const module = useWalletModule(name, params)

    if (!module) return null

    return <ModuleButton module={module} params={params} {...props} />
}

export default HeaderModules
