import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Container, SegmentedControl } from '@components'
import type { OrderType } from '@order/api'
import OrderListSection from './OrderListSection'

const MyOrders: React.FC = () => {
    const { t } = useTranslation()

    const state = useLocation().state
    const navigate = useNavigate()

    const _WALLET_ID = localStorage.getItem('user.wallet_id') || 0

    const [orderFilterType, setOrderFilterType] =
        React.useState<OrderType>('all')

    return (
        <Container
            title={t('@order.MyOrders.header_title')}
            goBackProps={{
                onClick: () => (!state?.go_home ? navigate(-1) : navigate('/')),
            }}>
            <Container.Left
                name={t('@order.MyOrders.header_title')}
                description={t('@order.MyOrders.header_description')}
                LayoutRightComponent={<React.Fragment />}>
                <Container.Content className="lg:min-h-[720px]">
                    <SegmentedControl
                        value={orderFilterType}
                        defaultValue={orderFilterType}
                        onValueChange={value =>
                            setOrderFilterType(value as OrderType)
                        }>
                        <SegmentedControl.List>
                            <SegmentedControl.Trigger value="all">
                                {t('@order.MyOrders.header_filter_all')}
                            </SegmentedControl.Trigger>
                            <SegmentedControl.Trigger value="active">
                                {t('@order.MyOrders.header_filter_active')}
                            </SegmentedControl.Trigger>
                            <SegmentedControl.Trigger value="past">
                                {t('@order.MyOrders.header_filter_past')}
                            </SegmentedControl.Trigger>
                        </SegmentedControl.List>

                        <SegmentedControl.Content value="all" className="mt-10">
                            <OrderListSection
                                walletId={_WALLET_ID as number}
                                orderFilterType={orderFilterType}
                            />
                        </SegmentedControl.Content>
                        <SegmentedControl.Content
                            value="active"
                            className="mt-10">
                            <OrderListSection
                                walletId={_WALLET_ID as number}
                                orderFilterType={orderFilterType}
                            />
                        </SegmentedControl.Content>
                        <SegmentedControl.Content
                            value="past"
                            className="mt-10">
                            <OrderListSection
                                walletId={_WALLET_ID as number}
                                orderFilterType={orderFilterType}
                            />
                        </SegmentedControl.Content>
                    </SegmentedControl>
                </Container.Content>
            </Container.Left>
            <Container.Right
                title={t('@order.MyOrders.right_info_title')}
                description={t('@order.MyOrders.right_info_description')}
            />
        </Container>
    )
}
export default MyOrders
