import * as React from 'react'
import { Button } from '@components'
import type { CampaignItemBoxProps } from './CampaignItem.types'
import { useFormatCurrency } from './CampaignItem.hooks'
import { useContentConfig } from '@core/hooks'
import { cn } from '@core/utils'

const CampaignItemBox: React.FC<CampaignItemBoxProps> = ({
    type,
    isUsed,
    point,
    showPercentSuffix = true,
    percent,
    currency,
    description,
    className,
    actionButtonClick,
}) => {
    const content = useContentConfig()
    const { currencySymbol, formattedText } = useFormatCurrency(point, currency)

    const formattedPercent = React.useMemo(() => {
        return percent ? `${percent}%` : ''
    }, [percent])

    const suffixLabel = React.useMemo(() => {
        return percent && showPercentSuffix
            ? content.campaignItem.percentSuffix
            : content.campaignItem.pointSuffix
    }, [
        content.campaignItem.percentSuffix,
        content.campaignItem.pointSuffix,
        percent,
        showPercentSuffix,
    ])

    return (
        <div
            data-is-used={isUsed}
            className={cn('campaign-item-box', className)}>
            <div className="campaign-item-card__content">
                {type === 'percentage' && (
                    <div className="campaign-item-card__content__point">
                        <span>{formattedPercent}</span>
                        <span>{suffixLabel}</span>
                    </div>
                )}
                {type === 'fixed' && (
                    <div className="campaign-item-card__content__point">
                        <span>{currencySymbol + formattedText}</span>
                        <span>{suffixLabel}</span>
                    </div>
                )}
                <p>{description}</p>
            </div>
            <Button
                className="w-full"
                children={
                    isUsed
                        ? content.campaignItem.disabledButtonText
                        : content.campaignItem.detailsButtonText
                }
                variant={!isUsed ? 'primary' : 'used'}
                rounded="pill"
                size="tiny"
                onClick={actionButtonClick}
            />
        </div>
    )
}
CampaignItemBox.displayName = 'CampaignItemBox'

export default CampaignItemBox
