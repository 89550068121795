import * as React from 'react'
import type { SettingsModulesProps } from './Settings.types'
import SettingsModulesList from './SettingsModulesList'

const SettingsModules: React.FC<SettingsModulesProps> = ({
    currency,
    walletId,
    companyId,
    modules,
    deliveryModules,
    company,
    isSettings,
}) => {
    if (isSettings) return null

    return (
        <SettingsModulesList
            currency={currency}
            walletId={walletId}
            companyId={companyId}
            modules={modules}
            deliveryModules={deliveryModules}
            company={company}
        />
    )
}
export default SettingsModules
