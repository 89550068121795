import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Container } from '@components'
import type { DeliverySummaryContainerProps } from './DeliverySummary.types'

const DeliverySummaryContainer: React.FC<DeliverySummaryContainerProps> = ({
    title,
    description,
    content,
    children,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Container
            title={t('@order.DeliverySummary.route_header_title')}
            goBackProps={{
                onClick: () => navigate(-1),
            }}
            ContentProps={{ className: 'overflow-hidden' }}>
            <Container.Left
                name={title}
                description={description}
                LayoutRightComponent={<React.Fragment />}
                className="relative">
                <Container.Content className="lg:min-h-[720px]">
                    {content}
                </Container.Content>
                {children}
            </Container.Left>
            <Container.Right
                title={t('@order.DeliverySummary.right_info_title')}
                description={t('@order.DeliverySummary.right_info_description')}
            />
        </Container>
    )
}
export default DeliverySummaryContainer
