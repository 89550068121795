import * as React from 'react'
import type { RewardChartTableListProps } from './RewardChart.types'
import RewardChartTableListItem from './RewardChartTableListItem'

const RewardChartTableList: React.FC<RewardChartTableListProps> = ({
    amountList,
    currency,
    ...props
}) => {
    const totalBalance = React.useMemo(() => {
        if (!amountList.length) return 0

        const total = amountList.reduce(
            (value, currentValue) => value + currentValue,
            0,
        )
        return total
    }, [amountList])

    return (
        <div className="flex flex-grow justify-end gap-1" {...props}>
            {amountList.map((item, index) => (
                <RewardChartTableListItem
                    key={index.toString()}
                    balance={item}
                    totalBalance={totalBalance}
                    currency={currency}
                />
            ))}
        </div>
    )
}

export default RewardChartTableList
