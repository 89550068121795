import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Switch } from '@components'
import type { WalletCardPayingProps } from './WalletCard.types'
import { useFormatCurrency } from './WalletCard.hooks'
import { cn } from '@core/utils'

const WalletCardPaying: React.FC<WalletCardPayingProps> = ({
    title,
    currency,
    amount,
    points,
    logo,
    color,
    disabledPointChecked,
    isPointSwitch = true,
    isPointChecked,
    pointBadgeProps,
    handlePointChecked,
}) => {
    const { t } = useTranslation()

    const { currencySymbol, formattedAmount, formattedPoint } =
        useFormatCurrency(amount, points, currency)

    return (
        <div>
            <div
                className="flex items-center justify-between rounded-[20px] px-6 py-7"
                style={{ backgroundColor: color }}>
                <div className="flex items-center gap-4">
                    <img
                        src={logo}
                        alt={title}
                        className="h-12 w-12 rounded-full object-cover"
                    />
                    <div className="space-y-1">
                        <h4 className="text-body-lg-b text-semantic-content-inkInverse">
                            {title}
                        </h4>
                        <Badge
                            variant="yellow"
                            size="tiny"
                            {...pointBadgeProps}
                            className={cn('w-fit', pointBadgeProps?.className)}>
                            {typeof points === 'number'
                                ? `${t('content_config.WalletCard.placeholders_point')}: ${currencySymbol}${formattedPoint}`
                                : pointBadgeProps?.children}
                        </Badge>
                    </div>
                </div>
                {amount ? (
                    <span className="shrink-0 text-display-md-b text-semantic-content-inkInverse">{`${currencySymbol}${formattedAmount}`}</span>
                ) : null}
            </div>
            {isPointSwitch && (
                <div className="mt-4 flex items-center justify-between">
                    <span className="text-body-sm-r text-semantic-content-ink">
                        {t('@payment.Capture.wallet_switch_title')}
                    </span>
                    <Switch
                        disabled={disabledPointChecked}
                        checked={isPointChecked}
                        onCheckedChange={handlePointChecked}
                    />
                </div>
            )}
        </div>
    )
}
export default WalletCardPaying
