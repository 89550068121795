import * as React from 'react'
import { Marker, Tooltip, useMapEvents } from 'react-leaflet'
import { isNil } from 'lodash'
import { Map, Spinner } from '@components'
import type { AddressMarkerProps } from './AddressMap.types'

const AddressMarker: React.FC<AddressMarkerProps> = ({
    address,
    loading,
    draggablePosition,
    onRegionChangeComplete,
}) => {
    const label = React.useMemo(() => {
        if (!address) return

        const { street, neighborhood, district } = address
        if (street) return `${street}, ${neighborhood}`

        return `${district}, ${neighborhood}`
    }, [address])

    const MapEvents = () => {
        useMapEvents({
            click(e) {
                onRegionChangeComplete?.([e.latlng.lat, e.latlng.lng])
            },
        })
        return null
    }

    if (isNil(draggablePosition)) return null

    return (
        <React.Fragment>
            <MapEvents />
            <Marker
                draggable={true}
                position={draggablePosition}
                icon={Map.CustomMarker}
                eventHandlers={{
                    dragend: e => {
                        const marker = e.target

                        onRegionChangeComplete?.([
                            marker.getLatLng().lat,
                            marker.getLatLng().lng,
                        ])
                    },
                }}>
                <Tooltip
                    permanent={true}
                    direction="top"
                    className="flex min-w-40 justify-center !bg-semantic-content-inkInverse text-body-md-r opacity-100"
                    offset={[0, -45]}>
                    {loading ? <Spinner size={14} /> : label}
                </Tooltip>
            </Marker>
        </React.Fragment>
    )
}
export default AddressMarker
