import baseApi from '@core/api'
import type {
    OrderCancelData,
    OrderCancelReasonsListResponse,
    OrderCancelResponse,
    OrderHistoryData,
    OrderHistoryDetailData,
    OrderHistoryDetailResponse,
    OrderHistoryResponse,
    OrderMenuData,
    OrderMenuResponse,
    OrderPayData,
    OrderPayResponse,
    OrderSummaryData,
    OrderSummaryResponse,
} from './OrderApi.types'
import { emitter } from '@core/utils'

const orderApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        orderHistoryList: builder.query<OrderHistoryResponse, OrderHistoryData>(
            {
                providesTags: ['OrderHistory'],
                query: data => ({
                    url: '/order/history/list',
                    method: 'GET',
                    params: data,
                }),
                serializeQueryArgs: ({
                    endpointName,
                    // eslint-disable-next-line no-unused-vars
                    queryArgs: { page, ...queryArgs },
                }) => {
                    return { endpointName, ...queryArgs }
                },
                merge: (currentData, newData, { arg }) => {
                    if (!arg.page || arg.page === 1) return newData

                    currentData.data.push(...newData.data)
                    currentData.meta.paginate = newData.meta.paginate
                },
            },
        ),
        orderHistoryDetail: builder.query<
            OrderHistoryDetailResponse,
            OrderHistoryDetailData
        >({
            providesTags: ['OrderHistoryDetail'],
            query: data => ({
                url: `/order/history/details/${data.id}`,
                method: 'GET',
            }),
        }),
        orderMenu: builder.query<OrderMenuResponse, OrderMenuData>({
            query: data => ({
                url: '/order/menu',
                method: 'GET',
                params: data,
            }),
        }),
        orderSummary: builder.query<OrderSummaryResponse, OrderSummaryData>({
            query: data => ({
                url: `/order/summary`,
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (e: unknown) {
                    const error = e as { error: { status: number } }

                    if (error.error.status === 412) {
                        emitter.emit('ORDER_SUMMARY_ERROR', error.error)
                    }
                }
            },
            providesTags: ['OrderSummary'],
        }),
        pay: builder.mutation<OrderPayResponse, OrderPayData>({
            query: data => ({
                url: '/order/pay',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_r, error) => (error ? [] : ['WalletDetail']),
        }),
        orderCancelReasonsList: builder.query<
            OrderCancelReasonsListResponse,
            void
        >({
            query: () => ({
                url: '/order/cancel/reason-list',
                method: 'GET',
            }),
        }),
        orderCancel: builder.mutation<OrderCancelResponse, OrderCancelData>({
            query: data => ({
                url: '/order/cancel',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_r, error) =>
                error
                    ? []
                    : [
                          'BalanceHistoryDetail',
                          'OrderHistory',
                          'OrderHistoryDetail',
                          'WalletDetail',
                      ],
        }),
    }),
    overrideExisting: true,
})

export default orderApi
