import * as React from 'react'
import { Avatar } from '@components'
import type { AddressItemEmptyProps } from './AddressItem.types'
import { cn } from '@core/utils'

const AddressItemEmpty: React.FC<AddressItemEmptyProps> = ({
    title,
    description,
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'flex items-center gap-4 rounded-[20px] border border-semantic-background-tertiary px-4 py-[18px]',
                className,
            )}
            {...props}>
            <Avatar.Icon
                name="warning"
                size={24}
                className="h-12 w-12 fill-semantic-content-inkLight"
            />
            <div className="flex-1 space-y-1">
                <h3 className="text-body-lg-b text-semantic-content-ink">
                    {title}
                </h3>
                <p className="text-body-md-r text-semantic-content-inkMedium">
                    {description}
                </p>
            </div>
        </div>
    )
}

AddressItemEmpty.displayName = 'AddressItemEmpty'

export default AddressItemEmpty
