import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { EmptySheet, OrderCard, Sheet } from '@components'
import orderApi from '@order/api'
import { OrderUtils } from '@order/utils'
import { OrderDetail } from '@order/views'
import type { OrderListSectionProps } from './MyOrders.types'
import { usePagination } from '@core/hooks'
import { cn } from '@core/utils'

const OrderListSection: React.FC<OrderListSectionProps> = ({
    walletId,
    orderFilterType,
}) => {
    const { t } = useTranslation()

    const [openSheetId, setOpenSheetId] = React.useState<number | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    const paginate = usePagination(
        orderApi.useOrderHistoryListQuery,
        { wallet_id: walletId, type: orderFilterType },
        { refetchOnMountOrArgChange: true },
    )

    const hasMore = React.useMemo(() => {
        return paginate.meta?.paginate.has_more
    }, [paginate.meta?.paginate.has_more])

    if (!paginate.data || !paginate.meta) return null

    return (
        <React.Fragment>
            <InfiniteScroll
                dataLength={paginate.data.length}
                hasMore={hasMore}
                next={paginate.more}
                loader={<div>loading</div>}
                className={cn(
                    'space-y-2 duration-300 animate-in fade-in zoom-in-95',
                )}>
                {map(paginate.data, (order, index) => (
                    <Sheet
                        key={order.id}
                        open={openSheetId === order.id}
                        onOpenChange={isOpen =>
                            setOpenSheetId(isOpen ? order.id : null)
                        }>
                        <Sheet.Trigger className="w-full" asChild>
                            <div onClick={() => setOpenSheetId(order.id)}>
                                <OrderCard
                                    key={index}
                                    title={order.company.name}
                                    status={order.status}
                                    addressTitle={OrderUtils.getAddressTitle(
                                        order,
                                    )}
                                    fullAddress={OrderUtils.getFullAddress(
                                        order,
                                    )}
                                    date={order.created_at}
                                    logo={order.company.logo}
                                    price={order.final_total}
                                    currency={order.company.currency}
                                    images={OrderUtils.prepareOrderImages(
                                        order.products,
                                    )}
                                />
                            </div>
                        </Sheet.Trigger>

                        <Sheet.Content
                            loading={loading}
                            className="overflow-y-scroll">
                            <OrderDetail
                                orderId={order.id}
                                onLoadingChange={setLoading}
                            />
                        </Sheet.Content>
                    </Sheet>
                ))}

                {paginate.data.length === 0 && !paginate.query.isFetching && (
                    <EmptySheet
                        icon="info"
                        title={t('@order.MyOrders.empty_sheet_title')}
                        description={
                            orderFilterType === 'all'
                                ? t('@order.MyOrders.empty_sheet_description')
                                : t(
                                      '@order.MyOrders.empty_sheet_description_filtered',
                                  )
                        }
                        className="max-w-full pb-8 pt-16"
                    />
                )}
            </InfiniteScroll>
        </React.Fragment>
    )
}
export default OrderListSection
