import L from 'leaflet'

const createClusterCustomIcon = function (cluster: L.MarkerCluster) {
    return L.divIcon({
        html: `<span class="bg-semantic-content-brand text-display-md-b h-12 w-12 rounded-full text-white flex items-center justify-center">${cluster.getChildCount()}</span>`,
        className: 'custom-marker-cluster',
        iconSize: L.point(33, 33, true),
    })
}

export default createClusterCustomIcon
