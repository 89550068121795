import * as React from 'react'
import { Button } from '@components'
import type { DeliveryOptionItemProps } from './DeliveryOption.types.ts'
import { cn } from '@core/utils'

const DeliveryOptionItem: React.FC<DeliveryOptionItemProps> = ({
    title,
    className,
    ...props
}) => {
    return (
        <Button
            variant="secondary"
            className={cn('w-full flex-col gap-3 py-5', className)}
            children={title}
            {...props}
        />
    )
}
export default DeliveryOptionItem
