import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { find, isEmpty, map } from 'lodash'
import { AddressItem, IconButton } from '@components'
import userApi from '@user/api'
import type { AddressListProps } from './AddressList.types'
import { useNotification } from '@core/hooks'
import { cn } from '@core/utils'
import AddressListLoader from './AddressListLoader'

const AddressList: React.FC<AddressListProps> = ({
    actionType = 'selectable',
    value,
    onChangeValue,
    itemProps,
    ListEmptyComponentProps,
    className,
    ...props
}) => {
    const { t } = useTranslation()
    const { alert, toast } = useNotification()

    const addressListResult = userApi.useAddressListQuery(undefined)
    const [addressDelete] = userApi.useAddressDeleteMutation()

    const data = React.useMemo(() => {
        return addressListResult.data?.data ?? []
    }, [addressListResult.data?.data])

    const defaultItem = React.useMemo(() => {
        return find(data, item => item.is_default === true)
    }, [data])

    const deleteAddress = React.useCallback(
        async (id: number) => {
            await addressDelete({ id: id }).unwrap()

            toast({
                variant: 'success',
                icon: 'check-circle',
                message: t('@user.AddressList.success_toast_message'),
                duration: 5000,
            })
        },
        [addressDelete, t, toast],
    )

    const showDeleteAlert = React.useCallback(
        (id: number) => {
            alert({
                title: t('@user.AddressList.alert_delete_address_title'),
                message: t('@user.AddressList.alert_delete_address_message'),
                actions: [
                    {
                        children: t(
                            '@user.AddressList.alert_delete_action_button',
                        ),
                        loading: true,
                        onClick: () => deleteAddress(id),
                    },
                    {
                        variant: 'secondary',
                        children: t(
                            '@user.AddressList.alert_delete_action_cancel_button',
                        ),
                    },
                ],
            })
        },
        [alert, deleteAddress, t],
    )

    React.useEffect(() => {
        if (!defaultItem) return
        onChangeValue?.(defaultItem)
    }, [defaultItem, onChangeValue])

    if (addressListResult.isLoading) return <AddressListLoader />

    return (
        <div
            className={cn(
                'space-y-2 duration-300 animate-in fade-in zoom-in-95',
                className,
            )}
            {...props}>
            {map(data, item => (
                <AddressItem
                    key={item.id.toString()}
                    title={item.title}
                    description={item.full_address}
                    value={
                        actionType === 'selectable'
                            ? value?.id === item.id
                            : undefined
                    }
                    favorite={actionType === 'editable' && item.is_default}
                    onChangeValue={() => onChangeValue?.(item)}
                    onClick={() => onChangeValue?.(item)}
                    LayoutRightComponent={
                        actionType === 'editable' ? (
                            <IconButton
                                variant="ink-inverse"
                                name="delete"
                                size="small"
                                onClick={() => showDeleteAlert(item.id)}
                            />
                        ) : undefined
                    }
                    {...itemProps}
                />
            ))}

            {isEmpty(data) && !addressListResult.isLoading ? (
                <AddressItem.Empty
                    title={t('@user.AddressList.list_empty_component_title')}
                    description={t(
                        '@user.AddressList.list_empty_component_description',
                    )}
                    {...ListEmptyComponentProps}
                />
            ) : null}
        </div>
    )
}
export default AddressList
