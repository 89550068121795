import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Button } from '@components'
import { ACCOUNTS_ITEMS } from './Account.constants'

const Account: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className="space-y-2">
            {map(ACCOUNTS_ITEMS, (item, index) => (
                <Button
                    key={index}
                    className="w-full flex-col gap-3 py-5"
                    children={t(item.title)}
                    variant="secondary"
                    leftIcon={item.icon}
                    onClick={() => navigate(item.to)}
                />
            ))}
        </div>
    )
}
export default Account
