import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Sheet } from '@components'
import { MasterPassService } from '@deposit/services'
import { TopUpAnotherCard } from '@deposit/views'
import type { OtherDepositMethodSectionProps } from './TopUp.types'
import { cn } from '@core/utils'
import DepositMethodContent from './OtherDepositMethodContent'

const OtherDepositMethodSection: React.FC<OtherDepositMethodSectionProps> = ({
    walletId,
    companyId,
    amount,
    enabledMasterPass,
    enabledMasterPassDP,
    isCustomAmount,
}) => {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (enabledMasterPass) return

        MasterPassService.checkMasterPass()
    }, [enabledMasterPass])

    if (!enabledMasterPassDP || companyId == 222) return null

    return (
        <Row className={cn('mt-12 space-y-4', !enabledMasterPass && 'mt-0')}>
            <Row.Title
                title={t('@deposit.TopUp.other_payment_method_section_title')}
                className={cn(!enabledMasterPass && 'hidden')}
            />

            <DepositMethodContent
                enabledMasterPass={enabledMasterPass}
                walletId={walletId}
                amount={amount}
                isCustomAmount={isCustomAmount}
                setOpen={setOpen}
            />

            {enabledMasterPass && (
                <Sheet open={open} onOpenChange={setOpen}>
                    <Sheet.Content className="overflow-y-scroll">
                        <Sheet.Header>
                            <Sheet.Title>
                                {t(
                                    '@deposit.TopUpAnotherCard.content_header_title',
                                )}
                            </Sheet.Title>
                            <Sheet.Description>
                                {t(
                                    '@deposit.TopUpAnotherCard.content_header_description',
                                )}
                            </Sheet.Description>
                        </Sheet.Header>
                        <TopUpAnotherCard
                            walletId={walletId}
                            amount={amount}
                            isCustomAmount={isCustomAmount}
                            setOpen={setOpen}
                        />
                    </Sheet.Content>
                </Sheet>
            )}
        </Row>
    )
}
export default OtherDepositMethodSection
