import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedControl } from '@components'
import { Account } from '@user/views'
import type { SettingsTabsProps } from './Settings.types'
import SettingsModulesList from './SettingsModulesList'

const SettingsTabs: React.FC<SettingsTabsProps> = ({
    currency,
    walletId,
    companyId,
    modules,
    deliveryModules,
    company,
    isSettings,
}) => {
    const { t } = useTranslation()

    if (!isSettings) return null

    return (
        <SegmentedControl defaultValue="feature" className="space-y-8">
            <SegmentedControl.List>
                <SegmentedControl.Trigger value="feature">
                    {t('@wallet.WalletDetail.feature_title')}
                </SegmentedControl.Trigger>
                <SegmentedControl.Trigger value="settings">
                    {t('@wallet.WalletDetail.settings_title')}
                </SegmentedControl.Trigger>
            </SegmentedControl.List>

            <SegmentedControl.Content value="feature">
                <SettingsModulesList
                    currency={currency}
                    walletId={walletId}
                    companyId={companyId}
                    modules={modules}
                    deliveryModules={deliveryModules}
                    company={company}
                />
            </SegmentedControl.Content>
            <SegmentedControl.Content value="settings">
                <Account />
            </SegmentedControl.Content>
        </SegmentedControl>
    )
}
export default SettingsTabs
