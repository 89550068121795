import { find } from 'lodash'
import i18n from '@core/translations'
import type {
    GetAddressDropdownFieldSelectedItemFunction,
    GetAddressDropdownFieldStepperTitleFunction,
} from './AddressCreate.types'

export const getAddressDropdownFieldStepperTitle: GetAddressDropdownFieldStepperTitleFunction =
    stepper => {
        switch (stepper) {
            case 'city':
                return i18n.t(
                    '@user.AddressCreate.dropdown_field_city_modal_header_title',
                )
            case 'districts':
                return i18n.t(
                    '@user.AddressCreate.dropdown_field_district_modal_header_title',
                )
            case 'neighborhoods':
                return i18n.t(
                    '@user.AddressCreate.dropdown_field_neighborhoods_modal_header_title',
                )
            default:
                return ''
        }
    }

export const getAddressDropdownFieldSelectedItem: GetAddressDropdownFieldSelectedItemFunction =
    (data, id) => {
        if (!id) return

        const selected = find(data, item => item.id === id)

        if (!selected) return undefined

        return {
            key: selected.id.toString(),
            title: selected.name,
            text: selected.name,
            location: { lat: selected.lat, lng: selected.lng },
        }
    }
