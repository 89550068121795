import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MasterPassSDK } from '@macellan/masterpass-sdk'
import { Button, PinInput, Sheet } from '@components'
import { useAppDispatch, useAppSelector } from '@core/store'
import { useCompletePurchase } from '@deposit/hooks'
import { MasterPassService } from '@deposit/services'
import { setMpAccountType } from '@deposit/slice'
import type { MasterPassOTPContentProps } from './MasterPassOTP.types'
import { useNotification } from '@core/hooks'

const MasterPassOTPContent: React.FC<MasterPassOTPContentProps> = ({
    emitterEvent,
    setOpen,
    setView,
    update,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()
    const dispatch = useAppDispatch()
    const handleCompletePurchase = useCompletePurchase()

    const [pinValue, setPinValue] = React.useState<string>('')

    const [visibleHelpButton, setVisibleHelpButton] =
        React.useState<boolean>(false)

    const { mpResendOTPLoading, mpValidateTransactionLoading } = useAppSelector(
        state => state.depositSlice,
    )

    const mpResendOTP = React.useCallback(() => {
        MasterPassService.resendOtp()
            .then(() => {
                toast({
                    variant: 'default',
                    icon: 'asterisk',
                    message: t(
                        '@deposit.MasterPassOTP.resend_otp_toast_message',
                    ),
                    duration: 5000,
                })
            })
            .catch(error => {
                toast({
                    variant: 'error',
                    icon: 'warning',
                    message: error.data.message,
                    duration: 5000,
                })
            })
            .finally(() => {
                setVisibleHelpButton(true)
            })
    }, [t, toast])

    const handleSubmitValue = React.useCallback(
        (value: string) => {
            MasterPassService.validateTransaction(value)
                .then(async result => {
                    if (
                        emitterEvent?.otpType === 'purchase' &&
                        emitterEvent?.depositId
                    ) {
                        if (
                            result.responseCode !==
                                MasterPassSDK.Constants.ResponseCodes.SUCCESS &&
                            result.responseCode !==
                                MasterPassSDK.Constants.ResponseCodes
                                    .SUCCESS_EMPTY
                        ) {
                            toast({
                                variant: 'error',
                                icon: 'warning',
                                message: t(
                                    'socket_context.payment_event.deposit.toast_error_message',
                                ),
                                duration: 5000,
                            })
                            MasterPassService.fail(
                                emitterEvent?.depositId,
                                result,
                            )

                            handleCompletePurchase(
                                false,
                                'masterpass',
                                emitterEvent?.redirect,
                            )
                            update?.(false)
                            return setOpen(false)
                        }

                        await MasterPassService.makeDeposit({
                            depositId: emitterEvent?.depositId,
                            token: result.token,
                        })

                        handleCompletePurchase(
                            true,
                            'masterpass',
                            emitterEvent?.redirect,
                        )

                        update?.(true)
                        return setOpen(false)
                    }

                    const message =
                        emitterEvent?.otpType === 'link'
                            ? t('@deposit.MasterPassOTP.success_type_link')
                            : t('@deposit.MasterPassOTP.success_type_register')
                    toast({
                        variant: 'success',
                        icon: 'check-circle',
                        message: message,
                        duration: 5000,
                    })

                    dispatch(setMpAccountType('registered'))

                    const mpCards = await MasterPassService.listCards()

                    if (mpCards.length) {
                        await MasterPassService.selectCard(mpCards[0])
                    }

                    return setOpen(false)
                })
                .catch(error => {
                    toast({
                        variant: 'error',
                        icon: 'warning',
                        message: error.data.message,
                        duration: 5000,
                    })
                })
                .finally(() => {
                    setPinValue('')
                })
        },
        [
            emitterEvent,
            t,
            toast,
            dispatch,
            setOpen,
            handleCompletePurchase,
            update,
        ],
    )

    return (
        <React.Fragment>
            <Sheet.Header>
                <Sheet.Title>
                    {t('@deposit.MasterPassOTP.route_header_title')}
                </Sheet.Title>
                <Sheet.Description>
                    {t('@deposit.MasterPassOTP.content_header_description')}
                </Sheet.Description>
            </Sheet.Header>
            <PinInput
                className="h-[72px]"
                value={pinValue}
                inputLength={6}
                onChangeValue={setPinValue}
                onSubmitValue={handleSubmitValue}
            />
            <div className="mt-6 flex items-center justify-between">
                <Button
                    children={t('@deposit.MasterPassOTP.resend_otp_button')}
                    size="tiny"
                    leftIcon="notification"
                    rounded="pill"
                    disabled={
                        mpValidateTransactionLoading || mpResendOTPLoading
                    }
                    onClick={mpResendOTP}
                />

                {visibleHelpButton ? (
                    <Button
                        children={t('@deposit.MasterPassOTP.help_button_text')}
                        variant="link"
                        size="tiny"
                        onClick={() => setView('help')}
                    />
                ) : null}
            </div>
        </React.Fragment>
    )
}
export default MasterPassOTPContent
