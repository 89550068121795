export default {
    CouponApply: {
        route_header_title: 'Apply Coupon',
        content_header_description:
            'Enter the coupon code below to earn points',
        coupon_add_button: 'Apply Coupon',
        coupon_toast_message:
            'Coupon worth {{amount}} points successfully applied',
        coupon_input_placeholder: 'Enter your coupon code',
    },
    AccountActivitySectionList: {
        empty_sheet_title: 'No Account Activity',
        empty_sheet_description: 'No account activity found',
    },
    WalletDetail: {
        header_title: 'BALANCE',
        header_point: 'Points: {{point}}',
        help: 'Help',
        help_description: 'Facing an issue?',
        read_qr: 'QR Pay',
        menu_earnings_title: 'My Earnings',
        menu_earnings_subtitle:
            'Contribution of Macellan SuperApp to your budget',
        menu_brand_title: 'About the Brand',
        menu_brand_subtitle: 'Menu, campaigns, branches, and more',
        wallet_history_title: 'Wallet Transactions',
        wallet_history_subtitle: 'Last 5 transactions',
        wallet_history_all: 'View All',
        agreement_updated:
            'Terms and conditions of {{wallet}} wallet have been updated',
        passive_status_toast_message:
            'The {{wallet}} wallet has been deactivated',
        wallet_benefits_title: 'What are the benefits of the wallet?',
        orders_title: 'Orders',
        orders_subtitle: 'View your recent orders',
        orders_badge_label_all: 'All Orders',
        feature_title: 'Features',
        settings_title: 'Settings',
    },
    WalletModules: {
        route_header_title: 'All Transactions',
        payment: 'Make Payment',
        deposit: 'Load Balance',
        coupon: 'Apply Coupon',
        wallet_share: 'Share Wallet',
        gift_card: 'Gift Card',
        ticket_system: 'My Tickets',
        branches: 'Branches',
        history: 'Account Activities',
        help: 'Help',
        agreement: 'Wallet Agreement',
        wallet_visibility: 'Wallet Visibility',
        products: 'Products',
    },
    AccountActivities: {
        content_header_title: 'Account Activities',
        content_header_description:
            'Access all account activities easily from here',
        search_field_placeholder: 'Filter',
    },
    AccountActivitiesFilter: {
        content_header_title: 'Filter Activities',
        content_header_description:
            'Apply the necessary filters and easily access the results',
        categories: 'CATEGORIES',
        filters: 'FILTERS',
        date: 'DATE RANGE',
        started_date: 'START DATE',
        end_date: 'END DATE',
        submit: 'Show Results',
        balance: 'Balance',
        refund: 'Refund',
        payment_refund: 'Spending Refund',
        deposit_refund: 'Load Refund',
        cash_deposit_refund: 'Cash Load Refund',
        capture: 'Balance Spending',
        top_up: 'Balance Load',
        date_time_place_holder: 'e.g., 07/07/2023',
    },
    AccountActivityDetail: {
        content_header_title: 'Transaction Details',
        content_header_description:
            'Access all transaction details easily from here',
        refund_reason: 'Refund Reason',
        customer_comment: 'Customer Comment',
        company_comment: 'Company Comment',
        reference_number: 'Request No',
        refund_button_title: 'Request Refund',
        branch_name: 'Branch Name',
        cashier: 'Cashier',
        total_earned_points: 'Total Earned Points',
        point_group: 'Point Group',
        earned_points: 'Earned Points',
        earned_wallet: 'Earned Wallet',
        top_up: 'Load',
        capture: 'Capture',
        process_type: 'Process Type',
        spent_amount: 'Spent Amount',
        payment_point: 'Payment Point',
        order_id: 'Order ID',
        date: 'Date',
        discount_amount: 'Discount Amount',
        amount_after_discount: 'Amount After Discount',
        spent_point: 'Spent Points',
        spent_balance: 'Spent Balance',
        wallet_type: 'Wallet Type',
        shared_wallet: 'Shared Wallet',
        sharer: 'Sharer',
        shared: 'Shared',
        expire_date: 'Expiration Date',
        description: 'Description',
        gift_card: 'Gift Card',
        undefined_user: 'Undefined User',
        loaded_amount: 'Loaded Amount',
        bank_amount: 'Bank Amount',
        point_deducted_balance: 'Point Deducted from Balance',
        total_revoked_point: 'Revoked Point',
        reference_code: 'Reference Code',
        loaded_type: 'Load Type',
        cash: 'Cash Load',
        dcb: 'Reflect on Invoice',
        card_number: 'Card Number',
        payment_refund: 'Payment Refund',
        deposit_refund: 'Deposit Refund',
        cash_deposit_refund: 'Cash Deposit Refund',
        refunded_amount: 'Refunded Amount',
        refunded_point: 'Refunded Point',
        transaction_details: 'Transaction Details',
        branch_details: 'Branch Details',
        additional_details: 'Additional Details',
        soft_play_details: 'Soft Play Details',
        bank_transfer: 'Bank Transfer',
        payment_refund_history: 'EXPENSE HISTORY',
        payment_refund_history_spent_amount: 'Spent Amount',
        soft_play_child: 'Child',
        soft_play_duration: 'Duration',
        soft_play_price: 'Price',
        installment_count: 'Number of Installments',
        total_installment_amount: 'Total Installment Amount',
    },
    GiftCard: {
        route_header_title: 'Gift Card',
        content_header_title: 'How does gift card delivery work?',
    },
    GiftCardDetail: {
        section_bar_title: 'Sharing Details',
        section_bar_description:
            'Access all transaction details easily from here',
        cancel_gift_card: 'Cancel',
        detail_section_title: 'TRANSACTION DETAILS',
        detail_section_name_surname: 'Name Surname',
        detail_section_anonymous: 'Unknown Person',
        detail_section_phone_number: 'Phone Number',
        detail_section_deposit: 'Shared Balance',
        detail_section_limitless: 'Limitless',
        detail_section_description: 'Description',
        detail_section_expire_date: 'Expiration Date',
        gift_card_cancel_toast_message: 'Your gift card has been canceled',
        gift_card_cancel_alert_title: 'Gift Card Cancellation',
        gift_card_cancel_alert_message:
            'Do you want to disable the\ngift card?',
        gift_card_cancel_alert_approval_button: 'Cancel Card',
        gift_card_cancel_alert_reject_button: 'Dismiss',
    },
    GiftCardMake: {
        route_header_title: 'Share',
        alert_title: 'Transaction Information',
        balance_alert_message:
            'The total amount you specified for Gift Card sharing cannot exceed the available wallet balance',
        balance_alert_top_up_button: 'Load Balance',
        balance_alert_back_button: 'Set New Amount',
        submit_alert_title: 'Send Gift Card',
        submit_alert_message:
            'Points associated with the sent gift card will not be refunded to your account if they are not spent by the expiration date.',
        submit_alert_approve_button: 'Send',
        submit_alert_approve_hidden_button: 'Send and Do Not Show Again',
        submit_alert_reject_button: 'Cancel',
        toast_success_message: 'Wallet shared successfully',
        form_wallet_field_section_title: 'Wallet Selection',
        form_wallet_field_placeholder: 'Select Wallet',
        form_balance_section_title: 'Shared Balance Amount',
        form_date_picker_section_title: 'Last Share Date',
        form_date_picker_placeholder: 'Select Date',
        form_description_section_title: 'Description',
        form_description_placeholder: 'e.g., Happy Birthday',
        form_person_field_section_title: 'Shared with People',
        form_person_field_footer: 'Add Person',
        form_person_field_unknown_user: 'Unknown Person',
        form_submit_button: 'Share Wallet',
        validation_phones_min:
            'The number of people to be shared with must be at least 1',
        click_to_add_person: 'Click to add person',
        add_person_description:
            'Enter the number of the person you want to send the Gift Card to',
        directory: 'DIRECTORY',
    },
    GiftCardMySent: {
        route_header_title: 'My Shares',
        empty_sheet_title: 'No Shares Found',
        empty_sheet_description: 'No shared gift card found',
    },
    GiftCardSentToMe: {
        route_header_title: 'Shared with Me',
        empty_sheet_title: 'No Shares Found',
        empty_sheet_description: 'No gift card shared with you found',
    },
    GiftCardHistoryTabs: {
        route_header_title: 'Sharing History',
    },
    GiftCardMySentHistory: {
        route_header_title: 'My Shares',
        empty_sheet_title: 'No Shares Found',
        empty_sheet_description: 'No shared gift card found',
    },
    GiftCardSentToMeHistory: {
        route_header_title: 'Shared with Me',
        empty_sheet_title: 'No Shares Found',
        empty_sheet_description: 'No gift card shared with you found',
    },
    GiftCardHistoryDetail: {
        section_bar_title: 'Sharing Details',
        section_bar_description:
            'Access all transaction details easily from here',
        detail_section_title: 'TRANSACTION DETAILS',
        detail_section_name_surname: 'Name Surname',
        detail_section_anonymous: 'Unknown Person',
        detail_section_phone_number: 'Phone Number',
        detail_section_deposit: 'Shared Balance',
        detail_section_limitless: 'Limitless',
        detail_section_description: 'Description',
        detail_section_status: 'Status',
        detail_section_status_expired: 'Expired',
        detail_section_status_cancel: 'Canceled',
        detail_section_status_finished: 'Completed',
        detail_section_expire_date: 'Expiration Date',
    },
    GiftCardInfo: {
        info_first_description:
            'With the Digital Gift Card, you can send points to your loved ones for their preferred wallet. You can send gift cards to multiple people at the same time.\nThe specified amount will be deducted from your balance as soon as the Digital Gift Card is sent.',
        info_second_description:
            "If the recipient does not make any purchases within 1 hour, you can cancel the transfer.\nIf the specified transfer period expires and there are still points left on the recipient's side, those points will be deleted and the remaining balance will be credited back to your account.",
        footer_button_continue: 'Continue',
        footer_button_close: 'Close',
    },
    Earned: {
        route_header_title: 'My Earnings',
        right_info_title: 'My Earnings',
        right_info_description:
            'On the My Earnings page, you can view the past and current details of all the points you have earned so far. You can find information such as the type of point earning (Coupon, Campaign, Invite a Friend, etc.), the expiration date, and the current status on this screen.',
        points_section_title: 'My Points',
        points_section_balance_title: 'Available Points',
        points_section_amount_title: 'Earned Points',
        points_section_spent_title: 'Spent Points',
        gift_card_title: 'Gift Card',
        empty_sheet_title: 'No Earnings',
        empty_sheet_description:
            'You can earn more by using the app for your expenses',
        earned_all: 'All Earnings',
    },
    EarnedPoints: {
        route_header_title: 'My Points',
        list_header_title: 'MY EARNINGS HISTORY',
    },
}
