import CampaignTranslations from '@campaign/translations'
import CommonTranslations from '@common/translations'
import CompanyTranslations from '@company/translations'
import DepositTranslations from '@deposit/translations'
import OrderTranslations from '@order/translations'
import PaymentTranslations from '@payment/translations'
import WalletTranslations from '@wallet/translations'
import UserTranslations from '@user/translations'

export default {
    '@campaign': CampaignTranslations.en,
    '@company': CompanyTranslations.en,
    '@deposit': DepositTranslations.en,
    '@order': OrderTranslations.en,
    '@user': UserTranslations.en,
    '@payment': PaymentTranslations.en,
    '@wallet': WalletTranslations.en,
    '@common': CommonTranslations.en,
    'socket_context': {
        refund_request_event: {
            alert_title: 'Refund Request',
            alert_message: 'Do you approve the refund of {{amount}}?',
            alert_action_submit: 'Accept',
            alert_action_reject: 'Reject',
            alert_action_submit_toast: 'Refund successfully processed',
            alert_action_reject_toast: 'Refund process canceled',
        },
        payment_event: {
            toast_error_message: 'Payment Failed',
            toast_no_balance_message: 'Insufficient balance for payment',
            toast_expire_message: 'Payment has expired',
            toast_device_offline_message: 'Connection could not be established',
            toast_callback_no: 'Device is not responding',
            toast_discount_point_change:
                'Transaction canceled due to changes in the discount',
        },
    },
    'context': {
        TopUpProvider: {
            alert_success_title: 'Balance loading was successful',
            toast_success_message:
                'You can check wallet transactions to view the transaction details',
            alert_success_action: 'Close',
        },
    },
    'content_config': {
        Alert: {
            close_timer: 'Will close in {{time}} sec.',
        },
        Sidebar: {
            hello: 'Hello',
            my_transactions: 'MY TRANSACTIONS',
            return_to_site: 'Return to Site',
            wallet: 'Wallet',
            account_activity: 'Account Activity',
            campaigns: 'Campaigns',
            gift_card: 'Gift Card',
        },
        Footer: {
            about_us: 'What is Macellan Wallet?',
            customer_service: 'Customer Service & Contact',
            faq: 'Frequently Asked Questions',
            copyright: `Macellan SuperApp © ${new Date().getFullYear()}. All rights reserved.`,
        },
        EarnedItem: {
            amount: 'Earned Points',
            balance: 'Usable Points',
            created_date: 'Load Date',
            expire_date: 'Expiration Date',
            expired: 'Expired',
            no_point: 'Out of Points',
            revoked: 'Revoked',
            package_amount: 'Subscription Amount',
            package_created_date: 'Usage Date',
        },
        CampaignItem: {
            details_button_text: 'Details',
            disabled_button_text: 'Used',
            remaining_time: 'Remaining Time',
            day: 'Day',
            point_suffix: 'points gift',
            percent_suffix: 'discount',
        },
        HistoryItem: {
            refund: 'Cancellation / Refund',
            topUp: 'Balance Load',
            capture: 'Balance Spending',
            refund_approval: 'Refund Approval Pending',
            refund_reject: 'Refund Rejected',
        },
        RewardChart: {
            table_type_title: 'LOAD AND EARN',
            progress_type_title: 'SPEND AND EARN',
            progress_type_description: 'spending remaining',
            table_type_description:
                'Total points earned for using Macellan SuperApp',
        },
        WalletCard: {
            placeholders_point: 'Points',
            placeholder_gift_point: 'Gift Point',
            placeholder_redeem: 'Redeem',
        },
        OrderStatus: {
            label_order_order_received: 'Order Received',
            label_order_preparing: 'Preparing',
            label_order_on_the_way: 'On the Way',
            label_order_at_address: 'At Address',
            label_order_ready_at_site: 'Ready at Store',
            label_order_delivered: 'Delivered',
            label_order_cancel: 'Canceled',
            label_order_undelivered: 'Not Delivered',
            cancel_order: 'Cancel Order',
        },
        OrderCard: {
            details_button_text: 'Details',
            price_label: 'Price',
        },
        Map: {
            branches: 'Branches',
            show_on_map: 'Show on Map',
            directions: 'Directions',
            geolocation_not_supported:
                'Your browser does not support location services.',
            geolocation_default_error: 'Location could not be retrieved.',
            geolocation_permission_denied:
                'Location permission denied. Please enable location permission in your browser settings.',
            geolocation_position_unavailable:
                'Location information is unavailable. Please make sure your location services are enabled.',
            geolocation_timeout:
                'The location request timed out. Please try again or check your internet connection.',
        },
    },
    'hooks': {
        useTopUp: {
            message: 'Add a payment method.',
        },
        useDeliveryOption: {
            enable_address_delivery_title: 'Delivery to Address',
            enable_takeaway_delivery_title: 'Takeaway',
            enable_table_delivery_title: 'Order to Table',
            enable_table_product_delivery_title: 'Branch Menu & Order to Table',
            enable_products_title: 'Branch Menu',
        },
    },
    'validation': {
        error_message: {
            /* Mixed Validation Messages */
            mixed_required: 'This field is required.',
            mixed_default: 'The entered value is not of the correct type',
            mixed_one_of: 'You entered an invalid value',
            /* String Validation Messages */
            string_length: 'This field must be {{length}} characters long',
            string_min: 'This field must be at least {{min}} characters long',
            string_max: 'This field must be at most {{max}} characters long',
            string_email: 'The entered value must be in email format',
            string_matches: 'Enter a value in the correct format',
            string_url: 'The entered value must be in URL format',
            /* Number Validation Messages */
            number_min: 'The entered number cannot be less than {{min}}',
            number_max: 'The entered number cannot be greater than {{max}}',
            number_less_than:
                'The entered number must be greater than {{less}}',
            number_more_than: 'The entered number must be less than {{more}}',
            number_positive: 'The entered value must be greater than zero',
            number_negative: 'The entered value must be less than zero',
            number_integer: 'The entered value must be an integer',
            /* Date Validation Messages */
            date_min: 'The entered date cannot be earlier than {{min}}',
            date_max: 'The entered date cannot be later than {{max}}',
            /* Array Validation Messages */
            array_length: 'The specified number of elements must be {{length}}',
            array_min:
                'The specified number of elements must be at least {{min}}',
            array_max:
                'The specified number of elements must be at most {{max}}',
        },
    },
}
