import * as React from 'react'
import { map } from 'lodash'
import { Badge } from '@components'
import type { OrderRefundReasonListProps } from './OrderRefund.types'

const OrderRefundReasonList: React.FC<OrderRefundReasonListProps> = ({
    reasons,
    activeReason,
    onClick,
}) => {
    return (
        <div className="flex flex-wrap gap-2">
            {map(reasons, item => (
                <Badge
                    type="button"
                    key={item.value}
                    style={{ borderWidth: 1 }}
                    variant={
                        activeReason?.value === item.value
                            ? 'brand-tonal'
                            : 'outlined'
                    }
                    size="medium"
                    children={item.label}
                    onClick={() => onClick(item)}
                />
            ))}
        </div>
    )
}
export default OrderRefundReasonList
