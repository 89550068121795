import * as React from 'react'
import { Alerter, type AlertProps, Toaster, type ToastProps } from '@components'
import { emitter } from '@core/utils'

const NOTIFICATION_LIMIT = 1
const NOTIFICATION_REMOVE_DELAY = 100

interface NotificationAlertProps extends AlertProps {
    id?: string
    open?: boolean
    type?: 'alert' | 'toast'
}

interface NotificationToastProps extends ToastProps {
    id?: string
    open?: boolean
    type?: 'alert' | 'toast'
}

const actionTypes = {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    DISMISS: 'DISMISS',
    REMOVE: 'REMOVE',
} as const

type ActionType = typeof actionTypes

type Action =
    | {
          type: ActionType['ADD']
          notification: NotificationAlertProps | NotificationToastProps
      }
    | {
          type: ActionType['UPDATE']
          notification: Partial<NotificationAlertProps | NotificationToastProps>
      }
    | { type: ActionType['DISMISS']; notificationId?: string }
    | { type: ActionType['REMOVE']; notificationId: string }

interface State {
    notifications: NotificationAlertProps[] | NotificationToastProps[]
}

let count = 0

function genId() {
    count = (count + 1) % Number.MAX_SAFE_INTEGER
    return count.toString()
}

const notificationTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

const addToRemoveQueue = (id: string) => {
    if (notificationTimeouts.has(id)) {
        return
    }

    const timeout = setTimeout(() => {
        notificationTimeouts.delete(id)
        dispatch({
            type: 'REMOVE',
            notificationId: id,
        })
    }, NOTIFICATION_REMOVE_DELAY)

    notificationTimeouts.set(id, timeout)
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'ADD':
            return {
                ...state,
                notifications: [
                    action.notification,
                    ...state.notifications,
                ].slice(0, NOTIFICATION_LIMIT),
            }
        case 'UPDATE':
            return {
                ...state,
                notifications: state.notifications.map(n =>
                    n.id === action.notification.id
                        ? { ...n, ...action.notification }
                        : n,
                ),
            }
        case 'DISMISS': {
            const { notificationId } = action

            if (notificationId) {
                addToRemoveQueue(notificationId)
            } else {
                state.notifications.forEach(notification => {
                    addToRemoveQueue(notification.id as string)
                })
            }
            return {
                ...state,
                notifications: state.notifications.map(n =>
                    n.id === action.notificationId ? { ...n, open: false } : n,
                ),
            }
        }
        case 'REMOVE':
            return {
                ...state,
                notifications: state.notifications.filter(
                    n => n.id !== action.notificationId,
                ),
            }
        default:
            return state
    }
}

// eslint-disable-next-line no-unused-vars
const listeners: Array<(state: State) => void> = []

let memoryState: State = { notifications: [] }

function dispatch(action: Action) {
    memoryState = reducer(memoryState, action)
    listeners.forEach(listener => {
        listener(memoryState)
    })
}

const notification = ({
    ...props
}: NotificationAlertProps | NotificationToastProps) => {
    const id = genId()

    const update = (props: NotificationAlertProps | NotificationToastProps) =>
        dispatch({
            type: 'UPDATE',
            notification: { ...props, id },
        })
    const dismiss = () => dispatch({ type: 'DISMISS', notificationId: id })

    dispatch({
        type: 'ADD',
        notification: { ...props, id, open: true },
    })

    return {
        id: id,
        dismiss,
        update,
    }
}

const useNotification = () => {
    const [state, setState] = React.useState<State>(memoryState)

    React.useEffect(() => {
        listeners.push(setState)
        return () => {
            const index = listeners.indexOf(setState)
            if (index > -1) {
                listeners.splice(index, 1)
            }
        }
    }, [state])

    emitter.on('ADD_TOAST', (options: NotificationToastProps) => {
        notification({ ...options, type: 'toast' })
    })

    return {
        ...state,
        alert: (props: NotificationAlertProps) =>
            notification({ ...props, type: 'alert' }),
        toast: (props: NotificationToastProps) =>
            notification({ ...props, type: 'toast' }),
        dismiss: (id?: string) =>
            dispatch({ type: 'DISMISS', notificationId: id }),
    }
}

const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    return (
        <React.Fragment>
            {children}
            <Toaster />
            <Alerter />
        </React.Fragment>
    )
}

export { notification, NotificationProvider, useNotification }
