import * as React from 'react'
import type { OrderRefundSectionItemInfoProps } from './OrderDetail.types'

const OrderRefundSectionItemInfo: React.FC<OrderRefundSectionItemInfoProps> = ({
    title,
    comment,
}) => {
    return (
        <div className="flex items-center justify-between space-y-2">
            <h3 className="text-body-md-r text-semantic-content-inkMedium">
                {title}
            </h3>
            <p className="text-body-md-b text-semantic-content-ink">
                {comment}
            </p>
        </div>
    )
}
export default OrderRefundSectionItemInfo
