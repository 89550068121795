import * as React from 'react'
import type { TextareaProps } from './Textarea.types'
import { cn } from '@core/utils'

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, ...props }, ref) => {
        return (
            <textarea
                className={cn(
                    'flex min-h-[152px] w-full rounded-[13px] bg-semantic-background-primary p-4 !text-body-lg-r text-semantic-content-ink shadow-sm placeholder:text-semantic-content-inkMedium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)

export default Textarea
