import { yup } from '@core/middlewares'

export const addressValidationSchema = yup.object().shape({
    title: yup.string().required().min(2),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    address: yup.array().of(yup.number().required()).length(3).required(),
    street: yup.string(),
    phone: yup.string().required(),
    building_number: yup.string().required(),
    floor: yup.number(),
    door_number: yup.string(),
    directions: yup.string(),
})
