import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox } from '@components'
import type { AgreementSectionProps } from './DeliverySummary.types'

const AgreementSection: React.FC<AgreementSectionProps> = ({
    checked,
    onChangeChecked,
}) => {
    const { t } = useTranslation()

    return (
        <CheckBox
            checked={checked}
            onChange={event =>
                onChangeChecked({
                    type: 'SET_TERMS_ACCEPTED',
                    payload: event.target.checked,
                })
            }>
            <a
                href="https://macellan.app/on-bilgilendirme-formu-siparis"
                target="_blank"
                className="mr-[2px] text-link-sm text-semantic-content-link underline">
                {t(
                    '@order.DeliverySummary.agreement_section_link_text_highlighted',
                )}
            </a>
            <span className="text-body-sm-r text-semantic-content-ink">
                {t('@order.DeliverySummary.agreement_section_link_text')}
            </span>
        </CheckBox>
    )
}
export default AgreementSection
