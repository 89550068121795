import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Map } from '@components'
import companyApi from '@company/api'
import type { BranchMapProps } from './BranchMap.types'
import { useNavigationMenuByDeliveryType } from './BranchMap.hooks'

const BranchMap: React.FC<BranchMapProps> = ({
    walletId,
    companyId,
    deliveryType,
}) => {
    const { t } = useTranslation()
    const navigateToProducts = useNavigationMenuByDeliveryType()

    const mapLocationsQuery = companyApi.useMapListQuery(
        {
            company_id: companyId,
            delivery_type: undefined,
            has_active_menu: 1,
        },
        { refetchOnMountOrArgChange: true },
    )

    const markers = React.useMemo(() => {
        return mapLocationsQuery.data?.data.map(item => ({
            id: item.id,
            position: [
                item.address.location.lat,
                item.address.location.lng,
            ] as [number, number],
            logo: item.company.logo,
            title: item.name,
            phone: item.phone,
            address: item.address.open_address,
            LayoutRightComponent: (
                <Button
                    size="tiny"
                    rounded="pill"
                    onClick={() =>
                        navigateToProducts(
                            companyId,
                            deliveryType,
                            walletId,
                            item.id,
                        )
                    }>
                    {t('@company.BranchMap.branch_list_layout_right_button')}
                </Button>
            ),
        }))
    }, [
        companyId,
        deliveryType,
        mapLocationsQuery.data?.data,
        navigateToProducts,
        t,
        walletId,
    ])

    if (!markers) return null

    return <Map markers={markers} className="!h-[calc(100vh-152px)]" />
}
export default BranchMap
