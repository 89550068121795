import * as React from 'react'
import { map } from 'lodash'
import { Button } from '@components'
import { useWalletModule } from '@wallet/hooks'
import type { SettingsModulesListsProps } from './Settings.types'

const SettingsModulesList: React.FC<SettingsModulesListsProps> = ({
    walletId,
    companyId,
    currency,
    modules,
    deliveryModules,
    company,
}) => (
    <div className="flex flex-col gap-2">
        {map(modules, (module, index) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const modules = useWalletModule(module.name, {
                index: index,
                walletId: walletId,
                companyId: companyId,
                currency: currency,
                deliveryModules: deliveryModules,
            })

            if (!modules) return null

            return (
                <Button
                    key={index}
                    className="w-full flex-col gap-3 py-5"
                    children={modules.title}
                    variant="secondary"
                    leftIcon={modules.icon}
                    onClick={modules.callback}
                    disabled={company.is_wallet_frozen}
                />
            )
        })}
    </div>
)
export default SettingsModulesList
