import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { first } from 'lodash'
import { Button, Row, Textarea } from '@components'
import orderApi, { type OrderCancelReason } from '@order/api'
import type { OrderRefundForm, OrderRefundProps } from './OrderRefund.types'
import { useNotification } from '@core/hooks'
import OrderRefundReasonList from './OrderRefundReasonList'

const OrderRefund: React.FC<OrderRefundProps> = ({
    orderId,
    onStepperChange,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()

    const orderCancelReasonListQuery = orderApi.useOrderCancelReasonsListQuery(
        undefined,
        { refetchOnMountOrArgChange: true },
    )
    const [cancelOrder, cancelOrderResult] = orderApi.useOrderCancelMutation()

    const handleSubmit = React.useCallback(
        async (values: OrderRefundForm) => {
            await cancelOrder({
                order_id: orderId,
                reason: values.reason.value,
                comment: values.comment,
            }).unwrap()

            toast({
                variant: 'success',
                icon: 'check-circle',
                message: t('@order.OrderRefund.toast_cancel_success_message'),
                duration: 5000,
            })

            return onStepperChange('details')
        },
        [cancelOrder, onStepperChange, orderId, t, toast],
    )

    const reasons = React.useMemo(() => {
        return orderCancelReasonListQuery.data?.data || []
    }, [orderCancelReasonListQuery.data])

    const defaultReason: OrderCancelReason = React.useMemo(() => {
        if (reasons.length === 1) return first(reasons)!

        return {
            label: '',
            value: '',
        }
    }, [reasons])

    const formik = useFormik({
        initialValues: {
            order_id: orderId,
            reason: defaultReason,
            comment: '',
        },
        onSubmit: handleSubmit,
    })

    React.useEffect(
        () => {
            formik.setFieldValue('reason', defaultReason)
        },
        // eslint-disable-next-line
        [defaultReason],
    )

    React.useEffect(
        () => {
            return () => onStepperChange('details')
        },
        // eslint-disable-next-line
        [],
    )

    if (!orderCancelReasonListQuery.data) return null

    return (
        <form className="space-y-11">
            <Row className="space-y-6">
                <Row.Title
                    title={t('@order.OrderRefund.cancel_reason_section_title')}
                />

                <OrderRefundReasonList
                    reasons={reasons}
                    activeReason={formik.values.reason}
                    onClick={reason => formik.setFieldValue('reason', reason)}
                />
            </Row>

            <Row className="space-y-6">
                <Row.Title
                    title={t('@order.OrderRefund.cancel_reason_comment_title')}
                />

                <Textarea
                    placeholder={t(
                        '@order.OrderRefund.cancel_reason_comment_placeholder',
                    )}
                    value={formik.values.comment}
                    onBlur={formik.handleBlur('comment')}
                    onChange={e =>
                        formik.setFieldValue('comment', e.target.value)
                    }
                />
            </Row>

            <Button
                type="submit"
                children={t('@order.OrderRefund.cancel_order_button')}
                className="w-full"
                disabled={!formik.isValid || !formik.dirty}
                loading={cancelOrderResult.isLoading}
                onClick={() => formik.handleSubmit()}
            />
        </form>
    )
}
export default OrderRefund
