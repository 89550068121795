export type StatusCodes = number[] | '*'

export interface IgnoredEndpoint {
    pattern: string | RegExp
    statusCodes: StatusCodes
}

const ignoredEndpoints: IgnoredEndpoint[] = [
    {
        pattern: '/waas/login/send-sms',
        statusCodes: [404],
    },
    {
        pattern: /^\/wallet\/by-company\/\d+$/,
        statusCodes: [404],
    },
    {
        pattern: /^\/waas\/app-start\/.+$/,
        statusCodes: [404],
    },
    {
        pattern: '/deposit/installment/info',
        statusCodes: [406, 412],
    },
]

export default {
    ignoredEndpoints: ignoredEndpoints,
}
