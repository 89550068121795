import type {
    DeliverySummaryAction,
    DeliverySummaryState,
} from './DeliverySummary.types'

export const INITIAL_STATE: DeliverySummaryState = {
    isLeaveAtDoor: false,
    isDontRingDoorbell: false,
    isUsingPoints: false,
    additionalNote: '',
    tableNumber: '',
    isTermsAccepted: false,
}

export const deliverySummaryReducer = (
    state: DeliverySummaryState,
    action: DeliverySummaryAction,
): DeliverySummaryState => {
    switch (action.type) {
        case 'SET_LEAVE_AT_DOOR':
            return { ...state, isLeaveAtDoor: action.payload }
        case 'SET_DONT_RING_DOORBELL':
            return { ...state, isDontRingDoorbell: action.payload }
        case 'SET_USING_POINTS':
            return { ...state, isUsingPoints: action.payload }
        case 'SET_ADDITIONAL_NOTE':
            return { ...state, additionalNote: action.payload }
        case 'SET_TABLE_NUMBER':
            return { ...state, tableNumber: action.payload }
        case 'SET_TERMS_ACCEPTED':
            return { ...state, isTermsAccepted: action.payload }
        default:
            return state
    }
}
