import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Container } from '@components'
import type { AddressesContainerProps } from './Addresses.types'

const AddressesContainer: React.FC<AddressesContainerProps> = ({
    children,
}) => {
    const { t } = useTranslation()
    const state = useLocation().state
    const navigate = useNavigate()

    return (
        <Container
            title={t('@user.Addresses.address_list_section_bar_title')}
            goBackProps={{
                onClick: () => (!state?.go_home ? navigate(-1) : navigate('/')),
            }}>
            <Container.Left
                name={t('@user.Addresses.address_list_section_title')}
                description={t(
                    '@user.Addresses.address_list_section_bar_description',
                )}
                LayoutRightComponent={<React.Fragment />}>
                <Container.Content className="lg:min-h-[720px]">
                    {children}
                </Container.Content>
            </Container.Left>
            <Container.Right
                title={t('@user.Addresses.right_info_title')}
                description={t('@user.Addresses.right_info_description')}
            />
        </Container>
    )
}
export default AddressesContainer
