import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Sheet, Stepper } from '@components'
import { AddressList } from '@user/components'
import { AddressCreate, AddressMap } from '@user/views'
import type { AddressesState, AddressesStepper } from './Addresses.types'
import AddressesContainer from './AddressesContainer'

const Addresses: React.FC = () => {
    const { t } = useTranslation()

    const [isSheetOpen, setIsSheetOpen] = React.useState<boolean>(false)
    const [stepper, setStepper] = React.useState<AddressesStepper>('map')
    const [state, setState] = React.useState<AddressesState | null>(null)

    React.useEffect(() => {
        if (isSheetOpen) return undefined

        setStepper('map')
        setState(null)
    }, [isSheetOpen])

    return (
        <React.Fragment>
            <AddressesContainer>
                <AddressList actionType="editable" />

                <Button
                    variant="secondary"
                    leftIcon="plus-bold"
                    className="mt-4 w-full"
                    children={t('@user.AddressList.address_list_footer_button')}
                    onClick={() => setIsSheetOpen(true)}
                />
            </AddressesContainer>

            <Sheet
                open={isSheetOpen}
                onOpenChange={open => setIsSheetOpen(open)}>
                <Sheet.Content className="overflow-y-scroll !px-0 py-0">
                    <Stepper activeStep={stepper}>
                        <Stepper.Step step="map">
                            <AddressMap
                                onStateChange={setState}
                                onStepperChange={setStepper}
                            />
                        </Stepper.Step>

                        <Stepper.Step step="address">
                            <AddressCreate
                                state={state!}
                                onOpenChange={setIsSheetOpen}
                            />
                        </Stepper.Step>
                    </Stepper>
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}
export default Addresses
