import type { RouteObject } from 'react-router'
import {
    AccountActivities,
    Earned,
    GiftCard,
    WalletDetail,
} from '@wallet/views'

export const WalletRouter: RouteObject[] = [
    {
        path: '/',
        element: <WalletDetail />,
    },
    {
        path: '/account-activities',
        element: <AccountActivities />,
    },
    {
        path: '/gift-card',
        element: <GiftCard />,
    },
    {
        path: '/earned',
        element: <Earned />,
    },
]
