import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Formik, type FormikProps } from 'formik'
import { debounce } from 'lodash'
import { Button, CheckBox, Input } from '@components'
import { useAppSelector } from '@core/store'
import { AmountSection } from '@deposit/components'
import {
    useCompletePurchase,
    useMasterPassLink,
    useTopUpAnotherCard,
} from '@deposit/hooks'
import walletApi from '@wallet/api'
import type {
    TopUpAnotherCardForm,
    TopUpAnotherCardProps,
} from './TopUpAnotherCard.types'
import { useNotification } from '@core/hooks'
import { cardValidationSchema } from './TopUpAnotherCard.validations'
import InstallmentSection from './InstallmentSection'

const TopUpAnotherCard: React.FC<TopUpAnotherCardProps> = ({
    walletId,
    amount,
    isCustomAmount,
    setOpen,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()

    const MasterPassLink = useMasterPassLink()

    const handleCompletePurchase = useCompletePurchase()

    const { mpCards, mpAccountType } = useAppSelector(
        state => state.depositSlice,
    )

    const formikRef = React.useRef<FormikProps<TopUpAnotherCardForm>>(null)

    const [bin, setBin] = React.useState<string>('')
    const [installmentCount, setInstallmentCount] = React.useState<
        number | null
    >(null)

    const [isAmount, setIsCustomAmount] =
        React.useState<boolean>(isCustomAmount)

    const walletDetailsQuery = walletApi.useWalletDetailsQuery(
        { wallet_id: walletId },
        { refetchOnMountOrArgChange: true },
    )

    const wallet = React.useMemo(() => {
        return walletDetailsQuery.data?.data
    }, [walletDetailsQuery.data?.data])

    const showMaximumumCardError = React.useCallback(() => {
        toast({
            variant: 'error',
            icon: 'warning',
            message: t('@deposit.TopUpAnotherCard.max_number_of_cards_error'),
        })
    }, [t, toast])

    const handleAccountLink = React.useCallback(() => {
        if (mpCards?.length > 6) return showMaximumumCardError()

        setOpen(false)
        debounce(MasterPassLink, 300)()

        formikRef.current?.setFieldValue('saveCard', true)
    }, [MasterPassLink, mpCards?.length, setOpen, showMaximumumCardError])

    const handleOnChangeSaveCardValue = React.useCallback(
        (value: boolean) => {
            if (mpCards?.length > 6) return showMaximumumCardError()

            if (mpAccountType === 'unlinked') return handleAccountLink()

            formikRef.current?.setFieldValue('saveCard', value)
        },
        [
            handleAccountLink,
            mpAccountType,
            mpCards?.length,
            showMaximumumCardError,
        ],
    )

    React.useEffect(() => {
        formikRef.current?.setFieldValue('amount', amount, false)
    }, [amount])

    const purchase = useTopUpAnotherCard(
        { walletId, installmentCount },
        handleCompletePurchase,
    )

    if (!wallet) return null

    return (
        <React.Fragment>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    amount: amount,
                    creditCardNo: '',
                    expireDate: '',
                    cvcNo: '',
                    saveCard: false,
                    name: '',
                    agreement: false,
                }}
                validationSchema={cardValidationSchema}
                onSubmit={purchase.init}>
                {formik => (
                    <div>
                        <AmountSection
                            walletId={walletId}
                            amount={formik.values.amount}
                            onChangeAmount={amount =>
                                formik.setFieldValue('amount', amount)
                            }
                            isVisibleCustomAmount={isAmount}
                            setCustomAmount={setIsCustomAmount}
                        />
                        <div className="my-8 space-y-4">
                            <Input.Mask
                                label={t(
                                    '@deposit.TopUpAnotherCard.form_label_credit_card_no',
                                )}
                                error={
                                    formik.touched.creditCardNo &&
                                    !!formik.errors.creditCardNo
                                }
                                helperText={
                                    formik.touched.creditCardNo
                                        ? formik.errors.creditCardNo
                                        : undefined
                                }
                                maskType="credit-card"
                                placeholder="•••• •••• •••• ••••"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={formik.values.creditCardNo}
                                onChange={e => {
                                    setBin(
                                        e.target.value
                                            .replace(/\s/g, '')
                                            .slice(0, 6),
                                    )

                                    formik.handleChange('creditCardNo')(e)
                                }}
                            />
                            <div className="grid grid-cols-2 gap-6 overflow-hidden">
                                <Input.Mask
                                    label={t(
                                        '@deposit.TopUpAnotherCard.form_label_expire_date',
                                    )}
                                    error={
                                        formik.touched.expireDate &&
                                        !!formik.errors.expireDate
                                    }
                                    helperText={
                                        formik.touched.expireDate
                                            ? formik.errors.expireDate
                                            : undefined
                                    }
                                    maskType="date-time"
                                    maskOptions={{
                                        dateTimePattern: 'MM/YY',
                                    }}
                                    placeholder={t(
                                        '@deposit.TopUpAnotherCard.form_placeholder_expire_date',
                                    )}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    value={formik.values.expireDate}
                                    onChange={formik.handleChange('expireDate')}
                                />
                                <Input
                                    label={t(
                                        '@deposit.TopUpAnotherCard.form_label_cvc_no',
                                    )}
                                    error={
                                        formik.touched.cvcNo &&
                                        !!formik.errors.cvcNo
                                    }
                                    helperText={
                                        formik.touched.cvcNo
                                            ? formik.errors.cvcNo
                                            : undefined
                                    }
                                    placeholder={t(
                                        '@deposit.TopUpAnotherCard.form_placeholder_cvc_no',
                                    )}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    value={formik.values.cvcNo}
                                    onChange={formik.handleChange('cvcNo')}
                                />
                            </div>
                        </div>
                        <CheckBox
                            checked={formik.values.saveCard}
                            onChange={event =>
                                handleOnChangeSaveCardValue(
                                    event.target.checked,
                                )
                            }>
                            {t(
                                '@deposit.TopUpAnotherCard.form_save_card_title',
                            )}
                        </CheckBox>
                        {formik.values.saveCard ? (
                            <div className="mt-8 space-y-4 animate-in fade-in zoom-in-95">
                                <Input
                                    label={t(
                                        '@deposit.TopUpAnotherCard.form_label_name',
                                    )}
                                    error={
                                        formik.touched.name &&
                                        !!formik.errors.name
                                    }
                                    helperText={
                                        formik.touched.name
                                            ? formik.errors.name
                                            : undefined
                                    }
                                    type="text"
                                    autoCapitalize="words"
                                    placeholder={t(
                                        '@deposit.TopUpAnotherCard.form_label_name',
                                    )}
                                    value={formik.values.name}
                                    onChange={formik.handleChange('name')}
                                />
                                <CheckBox
                                    checked={formik.values.agreement}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            'agreement',
                                            !formik.values.agreement,
                                        )
                                    }>
                                    <Trans
                                        i18nKey={
                                            '@deposit.TopUpAnotherCard.form_agreement_checkbox_title'
                                        }
                                        components={[
                                            <a
                                                className="text-link-sm text-semantic-content-link underline"
                                                target="_blank"
                                                href={t(
                                                    '@deposit.TopUpAnotherCard.mp_user_agreement_url',
                                                )}
                                            />,
                                        ]}
                                    />
                                </CheckBox>
                            </div>
                        ) : null}
                        <InstallmentSection
                            walletId={wallet.id}
                            amount={formik.values.amount}
                            currency={wallet.company.currency}
                            bin={bin.length === 6 ? parseFloat(bin) : undefined}
                            onChangeCount={setInstallmentCount}
                        />

                        <Button
                            type="submit"
                            disabled={!formik.isValid}
                            loading={purchase.loading}
                            className="mt-8 w-full"
                            onClick={() => formik.handleSubmit()}>
                            {t('@deposit.TopUpAnotherCard.form_submit_button')}
                        </Button>
                    </div>
                )}
            </Formik>
        </React.Fragment>
    )
}
export default TopUpAnotherCard
