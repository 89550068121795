import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { find, map, slice } from 'lodash'
import { Sheet } from '@components'
import { useAppSelector } from '@core/store'
import { DeliveryOption } from '@order/views'
import { Header, WalletSettingsModules } from '@wallet/components'
import { WalletSettingsTabs } from '@wallet/components'
import type { WalletDetailHeaderProps } from './WalletDetail.types'
import { emitter } from '@core/utils'
import CouponApply from '../CouponApply/CouponApply'

const WalletDetailHeader: React.FC<WalletDetailHeaderProps> = ({
    title,
    balance,
    point,
    currency,
    walletId,
    companyId,
    modules,
    deliveryModules,
}) => {
    const { t } = useTranslation()

    const company = useAppSelector(state => state.baseSlice.company)

    const [open, setOpen] = React.useState<boolean>(false)

    const isSettings = React.useMemo(
        () => find(modules, { name: 'products' })?.status ?? false,
        [modules],
    )

    React.useEffect(() => {
        emitter.on('CLOSE_WALLET_MODULE', () => setOpen(false))

        return () => {
            emitter.off('CLOSE_WALLET_MODULE', () => setOpen(false))
        }
    }, [])

    return (
        <React.Fragment>
            <Header>
                <Header.Top title={title} />
                <div className="flex justify-between">
                    <div className="wallet-detail-header__content">
                        <Header.MoneyWrapper
                            balance={balance}
                            point={point}
                            currency={currency}
                        />
                        <div className="flex gap-1 lg:gap-2">
                            <div className="flex items-start gap-1 lg:gap-4">
                                <div className="grid grid-cols-[repeat(auto-fit,minmax(80px,1fr))] gap-1 lg:flex lg:gap-4">
                                    {map(
                                        slice(modules, 0, 3),
                                        (module, index) => (
                                            <Header.Modules
                                                key={index}
                                                name={module.name}
                                                params={{
                                                    index: index,
                                                    walletId: walletId,
                                                    companyId: companyId,
                                                    currency: currency,
                                                    deliveryModules:
                                                        deliveryModules,
                                                }}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                            <Header.ModulesMore
                                disabled={company.is_wallet_frozen}
                                onOpenChange={setOpen}
                            />
                        </div>
                    </div>
                    {/*<Header.Onboarding />*/}
                </div>
            </Header>

            <CouponApply walletId={walletId} currency={currency} />
            <DeliveryOption />

            <Sheet open={open} onOpenChange={open => setOpen(open)}>
                <Sheet.Content>
                    <Sheet.Header>
                        <Sheet.Title
                            children={t(
                                '@wallet.WalletModules.route_header_title',
                            )}
                        />
                        <Sheet.Description
                            className="sr-only"
                            children={t(
                                '@wallet.WalletModules.route_header_title',
                            )}
                        />
                    </Sheet.Header>

                    <WalletSettingsTabs
                        walletId={walletId}
                        companyId={companyId}
                        currency={currency}
                        modules={modules}
                        deliveryModules={deliveryModules}
                        company={company}
                        isSettings={isSettings}
                    />

                    <WalletSettingsModules
                        walletId={walletId}
                        companyId={companyId}
                        currency={currency}
                        modules={modules}
                        deliveryModules={deliveryModules}
                        company={company}
                        isSettings={isSettings}
                    />
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}

export default WalletDetailHeader
