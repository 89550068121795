export default {
    Account: {
        application_addresses: 'My Addresses',
        application_orders: 'My Orders',
    },
    AddressClosest: {
        info_sheet_title: 'There is a closer address available!',
        info_sheet_description:
            'There is an address named {{address}} that is closer to your current location. Would you like to use this address?',
        info_sheet_accept_button: 'Use Nearest Address',
        info_sheet_reject_button: 'Continue With Current Address',
        alert_error_title: 'Oh No!',
        alert_error_message:
            'Unfortunately, the Merchant does not serve the selected address',
        alert_error_action_button: 'Choose Another Address',
        alert_error_action_reject_button: 'Cancel',
    },
    AddressCreate: {
        route_header_title: 'Add New Address',
        route_header_description:
            'You can add or edit addresses to access product lists.',
        success_toast_message: 'Address added successfully',
        check_box_title: 'Set as my default address',
        form_label_address_title: 'Address Title',
        form_label_street: 'Street',
        form_label_building_number: 'Building No',
        form_label_floor: 'Floor',
        form_label_door_number: 'Apartment No',
        form_label_directions: 'Directions',
        form_placeholder_first_name: 'First Name',
        form_placeholder_last_name: 'Last Name',
        form_placeholder_phone: 'Mobile Phone',
        row_title_contact_information: 'CONTACT INFORMATION',
        dropdown_field_label: 'City, District, Neighborhood',
        dropdown_field_city_modal_header_title: 'Select City',
        dropdown_field_district_modal_header_title: 'Select District',
        dropdown_field_neighborhoods_modal_header_title: 'Select Neighborhood',
        submit_button: 'Save',
        alert_error_title: 'Oh No!',
        alert_error_message:
            'Unfortunately, the Merchant does not serve the selected address',
        alert_error_action_button: 'Choose Another Address',
        alert_error_action_reject_button: 'Cancel',
    },
    Addresses: {
        right_info_title: 'How Do My Addresses Work?',
        right_info_description:
            'In the My Addresses section, you can save delivery addresses for your "Delivery to Address" orders. You can give a name to your addresses to identify them more easily. When adding an address, you can enter details such as city, district, neighborhood, street address, building number, door number, and floor. You can set one of your added addresses as the default address, delete existing addresses, or add new addresses. You can save a maximum of 10 addresses in the system.',
        address_list_section_title: 'My Registered Addresses',
        address_list_section_bar_title: 'My Addresses',
        address_list_section_bar_description:
            'You can add or delete addresses to access the product list',
    },
    AddressMap: {
        route_header_title: 'Select Your Address on the Map',
        route_header_description:
            'Quickly and accurately add your address by selecting your location on the map.',
        submit_location_button: 'Use This Location',
        search_field_placeholder: 'Search for neighborhood, street, or avenue',
    },
    AddressList: {
        success_toast_message: 'Address information successfully deleted',
        alert_delete_address_title: 'Address Deletion Process',
        alert_delete_address_message:
            'Are you sure you want to delete the selected address?',
        alert_delete_action_button: 'Delete Address',
        alert_delete_action_cancel_button: 'Cancel',
        list_empty_component_title: 'No Address Found',
        list_empty_component_description:
            'Your registered address will be listed here when you have one',
        address_list_footer_button: 'Add New Address',
        toast_provider_error_message: 'Maximum address limit reached',
    },
}
