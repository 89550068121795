import * as React from 'react'
import { useTranslation } from 'react-i18next'

interface GeolocationState {
    location: [number, number] | null
    error: string | null
    isLoading: boolean
}

interface GeolocationOptions {
    enableHighAccuracy?: boolean
    timeout?: number
    maximumAge?: number
    autoGetLocation?: boolean
}

export const useGeolocation = ({
    enableHighAccuracy = false,
    timeout = 15000,
    maximumAge = 60000,
    autoGetLocation = true,
}: GeolocationOptions = {}) => {
    const { t } = useTranslation()

    const [state, setState] = React.useState<GeolocationState>({
        location: null,
        error: null,
        isLoading: autoGetLocation,
    })

    const getLocation = React.useCallback(() => {
        if (!navigator.geolocation)
            return setState(prev => ({
                ...prev,
                error: t('content_config.Map.geolocation_not_supported'),
                isLoading: false,
            }))

        setState(prev => ({ ...prev, isLoading: true, error: null }))

        navigator.geolocation.getCurrentPosition(
            position => {
                const coords: [number, number] = [
                    position.coords.latitude,
                    position.coords.longitude,
                ]

                setState(prev => ({
                    ...prev,
                    location: coords,
                    isLoading: false,
                }))
            },
            err => {
                let errorMessage = t(
                    'content_config.Map.geolocation_default_error',
                )

                switch (err.code) {
                    case err.PERMISSION_DENIED:
                        errorMessage = t(
                            'content_config.Map.geolocation_permission_denied',
                        )
                        break
                    case err.POSITION_UNAVAILABLE:
                        errorMessage = t(
                            'content_config.Map.geolocation_position_unavailable',
                        )
                        break
                    case err.TIMEOUT:
                        errorMessage = t(
                            'content_config.Map.geolocation_timeout',
                        )
                        break
                }

                setState(prev => ({
                    ...prev,
                    error: errorMessage,
                    isLoading: false,
                }))
            },
            {
                enableHighAccuracy,
                timeout,
                maximumAge,
            },
        )
    }, [enableHighAccuracy, timeout, maximumAge, t])

    React.useEffect(() => {
        if (autoGetLocation) {
            getLocation()
        }
    }, [autoGetLocation, getLocation])

    return {
        ...state,
        getLocation,
    }
}

export default useGeolocation
