import * as React from 'react'
import { IconButton } from '@components'
import type { OrderStatusSingleProps } from './OrderStatus.types'
import { getOrderStatusSingleProps } from './OrderStatus.utils'
import { useTheme } from '@core/utils'

const OrderStatusSingle: React.FC<OrderStatusSingleProps> = ({
    status,
    customVariant,
}) => {
    const theme = useTheme().colors

    const iconButtonProps = React.useMemo(
        () => getOrderStatusSingleProps(status, customVariant, theme),
        [status, customVariant, theme],
    )

    return (
        <IconButton
            as="div"
            className="after:hover:bg-transparent"
            {...iconButtonProps}
        />
    )
}
export default OrderStatusSingle
