import * as React from 'react'
import type { ContainerLeftProps } from './Container.types.ts'
import { cn } from '@core/utils'
import ContainerPaying from './ContainerPaying'

const ContainerLeft: React.FC<ContainerLeftProps> = ({
    name,
    description,
    currency,
    amount,
    point,
    LayoutRightComponent,
    children,
    className,
    ...props
}) => {
    return (
        <div className={cn('flex-grow', className)} {...props}>
            <ContainerPaying
                name={name}
                description={description}
                currency={currency as string}
                amount={amount as number}
                point={point as number}
                LayoutRightComponent={LayoutRightComponent}
            />
            {children}
        </div>
    )
}

export default ContainerLeft
