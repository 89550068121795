import * as React from 'react'
import { useMap } from 'react-leaflet'
import { useTranslation } from 'react-i18next'
import { Button, IconButton } from '@components'
import type { MapPopupProps } from './Map.types.ts'

const MapPopup: React.FC<MapPopupProps> = ({ content, setActivePopup }) => {
    const { t } = useTranslation()
    const map = useMap()

    if (!content) return null

    return (
        <div className="absolute bottom-0 left-0 z-[1000] w-full space-y-[10px] p-6 duration-300 animate-in fade-in zoom-in-95 lg:max-w-[520px]">
            <div className="flex items-center justify-between">
                <span className="text-overline-md text-semantic-content-inkLight">
                    {t('content_config.Map.branches')}
                </span>
                <Button
                    variant="ink-inverse"
                    size="tiny"
                    rounded="pill"
                    children={t('content_config.Map.show_on_map')}
                    onClick={() => {
                        setActivePopup(null)
                        map.flyTo(content.position)
                    }}
                />
            </div>

            <div className="rounded-[20px] bg-semantic-background-inkInverse p-6">
                <div className="mb-3 flex items-center gap-3">
                    <img
                        src={content.logo}
                        alt={content.title}
                        className="h-12 w-12 rounded-full border-[1.6px] border-semantic-background-tertiary"
                    />
                    <h3 className="text-display-sm text-semantic-content-ink">
                        {content.title}
                    </h3>
                </div>

                <p className="mb-6 text-body-sm-r text-semantic-content-inkMedium">
                    {content.address}
                </p>

                <div className="flex items-center justify-between">
                    <div className="flex gap-3">
                        <IconButton
                            variant="ink-tonal"
                            name="call"
                            size="small"
                            onClick={() =>
                                window.open(`tel:${content.phone}`, '_blank')
                            }
                        />
                    </div>

                    {content.LayoutRightComponent &&
                        content.LayoutRightComponent}
                </div>
            </div>
        </div>
    )
}

export default MapPopup
