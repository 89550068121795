import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderStatus } from '@components'
import type { OrderStatusSectionProps } from './OrderDetail.types'

const OrderStatusSection: React.FC<OrderStatusSectionProps> = ({
    ...props
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.status_section_title')}
            </h3>
            <OrderStatus {...props} />
        </div>
    )
}
export default OrderStatusSection
