import * as React from 'react'
import {
    MapContainer,
    type MapContainerProps,
    Marker,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { Button, Icon, Skeleton } from '@components'
import LOCATION_IMG from '@core/assets/images/location.png'
import MARKER_IMG from '@core/assets/images/marker.png'
import type { MapProps, MapStaticProperties, MarkerProps } from './Map.types'
import { useGeolocation } from '@core/hooks'
import { cn } from '@core/utils'
import { mapStyles } from './Map.styles'
import createClusterCustomIcon from './MapClusterIcon'
import MapPopup from './MapPopup'
import MapReadyHandler from './MapReadyHandler'
import 'leaflet/dist/leaflet.css'

import L from 'leaflet'

export const customIcon = new L.Icon({
    iconUrl: MARKER_IMG,
    iconSize: new L.Point(32, 38),
    iconAnchor: new L.Point(16, 38),
})

const userLocationIcon = new L.Icon({
    iconUrl: LOCATION_IMG,
    iconSize: new L.Point(100, 100),
})

const Map: React.FC<MapProps> & MapStaticProperties = ({
    center,
    zoom = 13,
    markers = [],
    preview = false,
    onMapReady,
    className,
    children,
    ...props
}) => {
    const map = React.useRef<L.Map>(null)

    const [activePopup, setActivePopup] = React.useState<MarkerProps | null>(
        null,
    )

    const { location: userLocation, isLoading } = useGeolocation()

    const centerMap = React.useMemo(() => {
        if (preview) return center

        return (userLocation as [number, number]) || center
    }, [center, preview, userLocation])

    const previewProps = React.useMemo((): Partial<MapContainerProps> => {
        if (preview)
            return {
                doubleClickZoom: false,
                closePopupOnClick: false,
                dragging: false,
                trackResize: false,
                touchZoom: false,
                scrollWheelZoom: false,
                boxZoom: false,
                bounceAtZoomLimits: false,
            }

        return {}
    }, [preview])

    if (isLoading)
        return (
            <Skeleton
                className={cn('h-[800px] w-full rounded-none', className)}
            />
        )

    return (
        <div className="relative">
            <MapContainer
                ref={map}
                center={centerMap}
                zoom={zoom}
                maxZoom={18}
                minZoom={6}
                className={cn(
                    'z-30 h-[800px] w-full duration-300 animate-in fade-in',
                    className,
                )}
                zoomControl={false}
                {...previewProps}
                {...props}>
                <MapReadyHandler onMapReady={onMapReady} />
                <TileLayer
                    attribution={mapStyles['google'].attribution}
                    url={mapStyles['google'].url}
                />

                <MarkerClusterGroup
                    iconCreateFunction={createClusterCustomIcon}
                    spiderfyOnMaxZoom={true}
                    zoomToBoundsOnClick={!preview}
                    showCoverageOnHover={false}>
                    {markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={marker.position}
                            icon={customIcon}
                            eventHandlers={{
                                click: e => {
                                    if (preview)
                                        return e.originalEvent.preventDefault()

                                    setActivePopup(marker || null)
                                },
                            }}
                            zIndexOffset={500}
                        />
                    ))}
                </MarkerClusterGroup>

                {userLocation && !preview && (
                    <Marker position={userLocation} icon={userLocationIcon} />
                )}

                <MapPopup
                    content={activePopup}
                    setActivePopup={setActivePopup}
                />

                {children}
            </MapContainer>

            {userLocation && !preview && (
                <Button
                    className="absolute right-6 top-6 z-[1000] w-10 p-0 shadow-2xl"
                    variant="ink-inverse"
                    size="medium"
                    onClick={() => {
                        map.current?.flyTo(userLocation)
                    }}
                    children={<Icon name="navigation" size={20} />}
                />
            )}
        </div>
    )
}

Map.CustomMarker = customIcon

export default Map
