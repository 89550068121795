import * as React from 'react'
import { map } from 'lodash'
import { Sheet, Stepper } from '@components'
import { useAppSelector } from '@core/store'
import type { CompanyModules } from '@company/api'
import { BranchMap } from '@company/views'
import { useDeliveryOption } from '@order/hooks'
import { OrderLocation } from '@order/views'
import walletApi from '@wallet/api'
import type { DeliveryOptionStepper } from './DeliveryOption.types'
import { useDeliveryEvents } from './DeliveryOption.hooks'
import { cn } from '@core/utils'
import DeliveryOptionHeader from './DeliveryOptionHeader'
import DeliveryOptionItem from './DeliveryOptionItem'

const DeliveryOption: React.FC = () => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [stepper, setStepper] =
        React.useState<DeliveryOptionStepper>('option')

    useDeliveryEvents(setOpen, setStepper)

    const walletId = localStorage.getItem('user.wallet_id') || 0
    const company = useAppSelector(state => state.baseSlice.company)

    const walletDetailResult = walletApi.useWalletDetailsQuery({
        wallet_id: walletId as number,
    })

    const modules = React.useMemo(() => {
        return walletDetailResult.data?.data.company.modules as CompanyModules
    }, [walletDetailResult.data?.data])

    const data = useDeliveryOption(modules, {
        name: 'products',
        walletId: +walletId,
        companyId: company.id,
    })

    return (
        <React.Fragment>
            <Sheet open={open} onOpenChange={open => setOpen(open)}>
                <Sheet.Content
                    className={cn(stepper === 'branch_map' && '!px-0')}>
                    <DeliveryOptionHeader
                        stepper={stepper}
                        onStepperChange={setStepper}
                    />

                    <Stepper activeStep={stepper}>
                        <Stepper.Step step="option">
                            <div className="space-y-2">
                                {map(data, (item, index) => (
                                    <DeliveryOptionItem
                                        key={index}
                                        title={item.title}
                                        leftIcon={item.icon}
                                        onClick={item.callback}
                                    />
                                ))}
                            </div>
                        </Stepper.Step>

                        <Stepper.Step step="address">
                            <OrderLocation
                                walletId={+walletId}
                                companyId={company.id}
                                onStepperChange={setStepper}
                            />
                        </Stepper.Step>

                        <Stepper.Step step="branch_map">
                            <BranchMap
                                walletId={+walletId}
                                companyId={company.id}
                                deliveryType="takeaway"
                            />
                        </Stepper.Step>
                    </Stepper>
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}
export default DeliveryOption
