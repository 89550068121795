import * as React from 'react'
import type { EarnedItemRowProps } from './EarnedItem.types'
import { cn } from '@core/utils'

const EarnedItemRow: React.FC<EarnedItemRowProps> = ({
    title,
    value,
    titleClassName,
    valueClassName,
    className,
    LayoutRightComponent,
    ...props
}) => {
    return (
        <div
            className={cn('flex items-center justify-between', className)}
            {...props}>
            <span
                className={cn(
                    '!text-body-sm-r text-semantic-content-ink',
                    titleClassName,
                )}>
                {title}
            </span>
            {LayoutRightComponent ? (
                LayoutRightComponent
            ) : (
                <span
                    className={cn(
                        'shrink-0 !text-body-md-b text-semantic-content-ink',
                        valueClassName,
                    )}>
                    {value}
                </span>
            )}
        </div>
    )
}
export default EarnedItemRow
