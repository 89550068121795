import * as React from 'react'
import { CheckBox, Icon } from '@components'
import type {
    AddressItemProps,
    AddressItemStaticProperties,
} from './AddressItem.types'
import { cn } from '@core/utils'
import AddressItemEmpty from './AddressItemEmpty'
import AddressItemLoader from './AddressItemLoader'

const AddressItem: React.FC<AddressItemProps> & AddressItemStaticProperties = ({
    title,
    description,
    value,
    favorite,
    onChangeValue,
    LayoutRightComponent,
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'flex items-center gap-4 rounded-[20px] bg-semantic-background-primary px-4 py-[18px]',
                className,
            )}
            {...props}>
            {typeof value === 'boolean' && (
                <CheckBox
                    checked={value}
                    size={16}
                    onChange={() => onChangeValue?.()}
                />
            )}
            <div className="flex-1 space-y-1">
                <div className="flex items-center gap-1">
                    <h3 className="text-body-lg-b text-semantic-content-ink">
                        {title}
                    </h3>

                    {favorite && (
                        <Icon
                            name="star"
                            size={16}
                            color="var(--content-ink-light)"
                        />
                    )}
                </div>
                <p className="text-body-md-r text-semantic-content-inkMedium">
                    {description}
                </p>
            </div>
            {LayoutRightComponent && (
                <div className="flex shrink-0 items-center gap-2">
                    {LayoutRightComponent}
                </div>
            )}
        </div>
    )
}

AddressItem.displayName = 'AddressItem'

AddressItem.Empty = AddressItemEmpty
AddressItem.Loader = AddressItemLoader

export default AddressItem
