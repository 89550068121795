import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Container } from '@components'
import walletApi from '@wallet/api'
import { EarnedPointItem } from '@wallet/components'
import { EarnedPoints } from '@wallet/views'

const Earned: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const _WALLET_ID = localStorage.getItem('user.wallet_id') || 0

    const walletDetailsQuery = walletApi.useWalletDetailsQuery({
        wallet_id: _WALLET_ID as number,
    })

    const walletPointWonStatsQuery = walletApi.useWalletPointWonStatsQuery(
        { wallet_id: _WALLET_ID as number },
        { refetchOnMountOrArgChange: true },
    )

    const wallet = React.useMemo(
        () => walletDetailsQuery.data?.data,
        [walletDetailsQuery.data],
    )

    const pointWonStats = React.useMemo(() => {
        return walletPointWonStatsQuery.data?.data
    }, [walletPointWonStatsQuery.data?.data])

    if (!wallet || !pointWonStats) return null

    return (
        <Container
            title={t('@wallet.Earned.route_header_title')}
            goBackProps={{
                onClick: () => navigate(-1),
            }}>
            <Container.Left
                name={wallet.company.name}
                description={t('@deposit.TopUp.balance')}
                currency={wallet.company.currency}
                amount={wallet.balance}
                point={wallet.point}>
                <Container.Content className="lg:min-h-[720px]">
                    <div className="space-y-8">
                        <EarnedPointItem
                            availablePoints={pointWonStats.total_balance}
                            earnedPoints={pointWonStats.total_amounts}
                            spentPoints={pointWonStats.total_spents}
                            currency={wallet.company.currency}
                            className="animate-in fade-in zoom-in-95"
                        />
                        <EarnedPoints
                            walletId={_WALLET_ID as number}
                            currency={wallet.company.currency}
                        />
                    </div>
                </Container.Content>
            </Container.Left>
            <Container.Right
                title={t('@wallet.Earned.right_info_title')}
                description={t('@wallet.Earned.right_info_description')}
            />
        </Container>
    )
}
export default Earned
