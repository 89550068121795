export default {
    DeliveryOption: {
        bottom_sheet_section_bar_title: 'Teslimat Seç',
        bottom_sheet_section_bar_description:
            'Siparişine ait teslimat yöntemi seç',
    },
    MyOrders: {
        header_title: 'Siparişlerim',
        header_description:
            'Siparişlerinize ulaşabilir ve sipariş durumu bilgilsini kontrol edebilirsiniz.',
        header_filter_all: 'Tümü',
        header_filter_active: 'Aktif Siparişlerim',
        header_filter_past: 'Geçmiş Siparişlerim',
        empty_sheet_title: 'Siparişiniz Bulunamadı',
        empty_sheet_description:
            'Daha önceden verilen bir siparişiniz bulunamadı',
        empty_sheet_description_filtered:
            'Filtrelere uygun bir siparişiniz bulunamadı',
        right_info_title: 'Siparişlerim',
        right_info_description:
            'Siparişlerim ekranında, daha önce oluşturduğunuz siparişlerin özet bilgilerini takip edebilirsiniz. Siparişleriniz, durum bilgisi, cüzdan kullanımı, satın alınan ürünlerin görselleri ve tutarlarıyla birlikte kartlar halinde gösterilir. Bir sipariş kartına tıkladığınızda, siparişin detaylı bilgilerini görüntüleyebilirsiniz.',
    },
    OrderLocation: {
        alert_error_title: 'Hay Aksi!',
        alert_error_message:
            'Maalesef Üye İş Yeri, seçtiğin adrese hizmet vermemektedir',
        alert_error_action_button: 'Başka Adres Seç',
        alert_error_action_reject_button: 'Vazgeç',
        address_list_section_title: 'Kayıtlı Adreslerim',
        address_list_section_bar_title: 'Konum Belirle',
        address_list_section_bar_description:
            'Ürün listesine ulaşmak için adres ekleyebilir ya da silebilirsin',
        footer_forward_button: 'İleri',
    },
    DeliverySummary: {
        route_header_title: 'Ödeme Yap',
        right_info_title: 'Ödeme Özeti',
        right_info_description:
            'Ödeme özeti ekranında, siparişinize ait tüm bilgileri görüntüleyebilirsiniz. Sepete eklediğiniz ürünlerin miktarı ve tutarı, seçtiğiniz teslimat adresi, varsa teslimat ücreti farkı, poşet ücreti gibi ek masraflar bu ekranda yer alır. Ayrıca, "Kapıma bırak" veya "Zili çalma" seçenekleriyle kuryeye özel talimat verebilirsiniz. Ödeme aşamasında, bakiyeniz ve puanlarınız görüntülenir, dilerseniz puanlarınızı kullanarak ödeme işlemini tamamlayabilirsiniz.',
        additional_note_section_title: 'NOT EKLE',
        additional_note_placeholder: 'Sipariş notunu buraya yazabilirsin',
        agreement_section_link_text_highlighted:
            'Ön Bilgilendirme Formu ve Mesafeli Satış Sözleşmesi',
        agreement_section_link_text: 'ni okudum ve kabul ediyorum',
        agreement_link_page_title: 'Ön Bilgilendirme Formu',
        delivery_location_section_title: 'TESLİMAT ADRESİ',
        delivery_location_section_description:
            'Ürünleriniz şubeden teslim edilecektir',
        leave_order_at_door_label: 'Siparişi Kapıya Bırak',
        do_not_ring_doorbell_label: 'Zili Çalma',
        delivery_method_section_title: 'TESLİMAT TÜRÜ',
        delivery_method_address: 'Adrese Teslim',
        delivery_method_takeaway: 'Gel-Al',
        delivery_method_table: 'Masaya Sipariş',
        toast_payment_success_message: 'Siparişiniz başarıyla alındı',
        toast_point_usage_sufficient_message:
            'Kullanabileceğiniz bir puanınız bulunmamaktadır',
        payment_summary_section_title: 'ÖDEME BİLGİLERİ',
        payment_summary_products_title: 'Ürünler',
        payment_summary_subtotal_label: 'Ara Toplam',
        payment_summary_paid_point_label: 'Puan Kullanımı',
        payment_summary_delivery_amount_label: 'Teslimat Ücreti',
        table_section_title: 'MASA NUMARANIZ',
        table_section_text_field_message:
            'Sipariş onayı için masa numaranızı giriniz',
        payment_summary_discount_label: 'İndirim',
        wallet_section_title: 'CÜZDAN',
        tool_bar_footer_total_amount_label: 'Toplam Tutar',
        tool_bar_footer_pay_button: 'Öde',
        toast_success_message: 'Bakiye yükleme başarıyla gerçekleşti',
        toast_error_message:
            'Ödeme yapabilmek için bakiye yüklemen gerekmektedir',
        direction_chip_title: 'Yol Tarifi',
    },
    OrderBranchMap: {
        route_header_title: 'Şube Haritası',
        route_header_description:
            'Siparişinizi oluşturmak için en yakın şubeyi seçin.',
    },
    OrderDetail: {
        route_header_title: 'Sipariş Detayı',
        route_header_description:
            'Siparişinizin içerik ve durumu hakkında tüm detayları bu sayfadan görüntüleyebilirsiniz.',
        additional_note_section_title: 'SİPARİŞ NOTU',
        branch_section_title: 'SATICI / ŞUBE',
        delivery_address_section_title: 'TESLİMAT ADRESİ',
        delivery_detail_section_title: 'TESLİMAT DETAYI',
        refund_detail_section_title: 'İADE DETAYLARI',
        delivery_type_label: 'Teslimat Türü',
        order_no_label: 'Sipariş No',
        order_date_label: 'Sipariş Tarihi',
        delivery_date_label: 'Teslim Tarihi',
        delivery_type_address: 'Adrese Teslim',
        delivery_type_table: 'Masaya Sipariş',
        delivery_type_takeaway: 'Gel-Al',
        leave_at_door: 'Kapıya Bırak',
        do_not_ring_bell: 'Zili Çalma',
        status_section_title: 'SİPARİŞ DURUMU',
        payment_summary_section_title: 'ÖDEME BİLGİLERİ',
        payment_summary_products_label: 'Ürünler',
        payment_summary_paid_amount_label: 'Toplam Tutar',
        payment_summary_gift_point_label: 'Puan Kullanımı',
        payment_summary_delivery_amount_label: 'Teslimat Ücreti',
        payment_summary_discount_amount_label: 'İndirim Tutarı',
        products_section_title: 'SEPETİNDEKİ ÜRÜNLER',
    },
    OrderRefund: {
        route_header_title: 'Sipariş İptali',
        toast_cancel_success_message: 'Siparişiniz başarıyla iptal edildi',
        cancel_reason_section_title: 'İPTAL NEDENİ',
        cancel_reason_comment_title: 'İPTAL AÇIKLAMASI',
        cancel_reason_comment_placeholder: 'İptal açıklamanızı girin',
        cancel_order_button: 'İptal Et',
    },
    Products: {
        route_header_title: 'Ürünler',
        route_header_description: 'Ürünlerinizi seçip siparişinizi oluşturun',
        right_info_title: 'Ürünler nasıl çalışır?',
        right_info_description:
            'Ürünler kategori bazında listelenir. İlgilendiğiniz kategoriyi seçerek sipariş verebileceğiniz ürünleri görüntüleyebilirsiniz. Ürün listesindeki + butonunu kullanarak sepete eklemek istediğiniz ürünün miktarını belirleyebilirsiniz. Sepete ürün ekledikten sonra, eklenen ürünlerin miktarını ve toplam tutarı sayfanın en altında görebilirsiniz. + butonu artık görünmüyorsa, seçilen ürün için belirlenen maksimum sepete ekleme sınırına ulaşılmış demektir.',
    },
}
