import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { EarnedItem, EmptySheet } from '@components'
import walletApi from '@wallet/api'
import type { EarnedPointsProps } from './EarnedPoints.types'
import { usePagination } from '@core/hooks'

const EarnedPoints: React.FC<EarnedPointsProps> = ({ walletId, currency }) => {
    const { t } = useTranslation()

    const paginate = usePagination(walletApi.useWalletPointWonListQuery, {
        wallet_id: walletId,
    })

    const hasMore = React.useMemo(() => {
        return paginate.meta?.paginate.has_more
    }, [paginate.meta?.paginate.has_more])

    if (!paginate.data || !paginate.meta) return null

    return (
        <InfiniteScroll
            dataLength={paginate.data.length}
            hasMore={hasMore}
            next={paginate.more}
            loader={<React.Fragment />}
            className="space-y-5 !overflow-hidden duration-300 animate-in fade-in">
            {map(paginate.data, (item, index) => (
                <EarnedItem.Point
                    key={index}
                    title={
                        item.type === 'gift_card'
                            ? t('@wallet.Earned.gift_card_title')
                            : item.type === 'user_coupon'
                              ? t('@wallet.Earned.invite_friend_title')
                              : item.logable.title
                    }
                    amount={item.amount}
                    balance={item.is_expired ? item.expired : item.balance}
                    currency={currency}
                    isExpired={item.is_expired}
                    createdDate={item.created_at}
                    expireDate={item.expire_date}
                    isRevoked={item.is_revoked}
                />
            ))}

            {paginate.data.length === 0 && !paginate.query.isFetching && (
                <EmptySheet
                    icon="info"
                    title={t('@wallet.Earned.empty_sheet_title')}
                    description={t('@wallet.Earned.empty_sheet_description')}
                />
            )}
        </InfiniteScroll>
    )
}
export default EarnedPoints
