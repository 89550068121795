import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Row } from '@components'
import i18n from '@core/translations'
import type {
    AdditionalCost,
    PaymentSummarySectionProps,
} from './DeliverySummary.types'

const PaymentSummarySection: React.FC<PaymentSummarySectionProps> = ({
    products,
    totalPrice,
    discountPoint,
    currency,
    deliveryAmount,
    deliveryDiscount,
    deliveryType,
}) => {
    const { t } = useTranslation()

    const additionalCosts = React.useMemo(() => {
        const items: AdditionalCost[] = []

        if (discountPoint) {
            items.push({
                label: t(
                    '@order.DeliverySummary.payment_summary_discount_label',
                ),
                value: -discountPoint,
                color: 'var(--content-success)',
            })
        }

        return items
    }, [discountPoint, t])

    return (
        <Row className="space-y-3">
            <Row.Title
                title={t(
                    '@order.DeliverySummary.payment_summary_section_title',
                )}
            />

            <div className="space-y-6 divide-y divide-semantic-background-tertiary">
                <div className="space-y-4 pt-6">
                    <h3 className="text-body-md-b text-semantic-content-inkMedium">
                        {t(
                            '@order.DeliverySummary.payment_summary_products_title',
                        )}
                    </h3>

                    <div className="space-y-2">
                        {products.map((product, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between">
                                <h3 className="text-body-lg-r text-semantic-content-ink">
                                    {product.product_name} ({product.qty})
                                </h3>

                                <span className="text-body-lg-r text-semantic-content-ink">
                                    {CurrencyFormatter.format(
                                        product.price,
                                        i18n.getLocale(),
                                        currency,
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex items-center justify-between pt-6">
                    <h3 className="text-body-md-b text-semantic-content-inkMedium">
                        {t(
                            '@order.DeliverySummary.payment_summary_subtotal_label',
                        )}
                    </h3>

                    <span className="text-body-md-b text-semantic-content-inkMedium">
                        {CurrencyFormatter.format(
                            totalPrice,
                            i18n.getLocale(),
                            currency,
                        )}
                    </span>
                </div>

                {deliveryType === 'address' && deliveryAmount ? (
                    <div className="flex items-center justify-between pt-6">
                        <h3 className="text-body-md-b text-semantic-content-inkMedium">
                            {t(
                                '@order.DeliverySummary.payment_summary_delivery_amount_label',
                            )}
                        </h3>

                        {deliveryDiscount === 0 ? (
                            <span className="text-body-md-b text-semantic-content-inkMedium">
                                {CurrencyFormatter.format(
                                    deliveryAmount,
                                    i18n.getLocale(),
                                    currency,
                                )}
                            </span>
                        ) : (
                            <span className="text-body-md-b text-semantic-content-success">
                                {CurrencyFormatter.format(
                                    0,
                                    i18n.getLocale(),
                                    currency,
                                )}
                            </span>
                        )}
                    </div>
                ) : null}

                {additionalCosts.length > 0 ? (
                    <div className="space-y-6 pt-6">
                        {additionalCosts.map((item, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between">
                                <h3 className="text-body-md-b text-semantic-content-inkMedium">
                                    {item.label}
                                </h3>
                                <span
                                    className="text-body-md-b"
                                    style={{ color: item.color }}>
                                    {CurrencyFormatter.format(
                                        item.value,
                                        i18n.getLocale(),
                                        currency,
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </Row>
    )
}
export default PaymentSummarySection
