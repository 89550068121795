import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { OrderItem } from '@components'
import type { ProductsSectionProps } from './OrderDetail.types'

const ProductsSection: React.FC<ProductsSectionProps> = ({
    products,
    currency,
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.products_section_title')}
            </h3>
            <div className="space-y-6 rounded-[20px] bg-semantic-background-primary px-4 py-6">
                {map(products, product => (
                    <OrderItem
                        key={product.menu_product_variation_id}
                        title={product.product_name}
                        amount={product.qty}
                        currency={currency}
                        image={product.product_img}
                        price={product.price}
                    />
                ))}
            </div>
        </div>
    )
}
export default ProductsSection
