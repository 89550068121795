import type { BadgeProps } from '@components'
import i18n from '@core/translations'
import type { IconButtonProps } from '../IconButton/IconButton.types'
import type {
    GetOrderStatusBadgePropertiesFunction,
    GetOrderStatusesFunction,
    GetOrderStatusLabelFunction,
    GetOrderStatusPropertiesFunction,
    GetOrderStatusSinglePropertiesFunction,
    OrderStatusType,
} from './OrderStatus.types'

export const getOrderStatusProperties: GetOrderStatusPropertiesFunction = (
    type,
    theme,
) => {
    switch (type) {
        case 'order_received':
            return {
                backgroundColor: theme.solid.orange['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'basket-check',
            }

        case 'preparing': {
            return {
                backgroundColor: theme.solid.yellow['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'clock',
            }
        }

        case 'on_the_way': {
            return {
                backgroundColor: theme.solid.blue['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'delivery-scooter',
            }
        }

        case 'at_address': {
            return {
                backgroundColor: theme.solid.purple['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'destination',
            }
        }

        case 'ready_at_site': {
            return {
                backgroundColor: theme.solid.purple['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'delivery-people',
            }
        }

        case 'delivered': {
            return {
                backgroundColor: theme.solid.green['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'check-circle',
            }
        }

        case 'undelivered': {
            return {
                backgroundColor: theme.solid.red['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'close-circle',
            }
        }

        case 'cancel': {
            return {
                backgroundColor: theme.solid.red['5'],
                textColor: theme.semantic.content.inkInverse,
                icon: 'close-circle',
            }
        }
    }
}

export const getOrderStatusSingleProps: GetOrderStatusSinglePropertiesFunction =
    (status, variant, theme) => {
        const orderStatusProperties = getOrderStatusProperties(status, theme)

        const iconProps: IconButtonProps = {
            name: orderStatusProperties.icon,
            size: 'medium',
        }

        if (variant === 'idle') {
            return {
                style: {
                    backgroundColor: theme.semantic.content.inkInverse,
                },
                color: theme.semantic.content.ink,
                ...iconProps,
            }
        }

        if (variant === 'disabled') {
            return {
                style: {
                    backgroundColor: 'transparent',
                    borderWidth: 1.6,
                    borderColor: theme.semantic.background.tertiary,
                },
                color: theme.semantic.content.disabled,
                ...iconProps,
            }
        }

        return {
            color: theme.semantic.content.inkInverse,
            style: {
                backgroundColor: orderStatusProperties.backgroundColor,
            },
            ...iconProps,
        }
    }

export const getOrderStatusBadgeProperties: GetOrderStatusBadgePropertiesFunction =
    (status, theme) => {
        const orderStatusProperties = getOrderStatusProperties(status, theme)

        const typeMap: Record<OrderStatusType, BadgeProps['variant']> = {
            order_received: 'orange',
            preparing: 'yellow-5',
            on_the_way: 'blue',
            at_address: 'purple',
            ready_at_site: 'purple',
            delivered: 'green',
            cancel: 'red',
            undelivered: 'red',
        }

        const badgeProps: Partial<BadgeProps> = {
            variant: typeMap[status],
            leftIcon: orderStatusProperties?.icon,
            size: 'small',
        }

        return badgeProps
    }

export const getOrderStatusLabel: GetOrderStatusLabelFunction = status =>
    i18n.t(`content_config.OrderStatus.label_order_${status}`) || ''

export const getOrderStatuses: GetOrderStatusesFunction = deliveryLocation => {
    if (deliveryLocation === 'address')
        return [
            'order_received',
            'preparing',
            'on_the_way',
            'at_address',
            'delivered',
        ]

    return ['order_received', 'preparing', 'ready_at_site', 'delivered']
}
