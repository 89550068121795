import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DepositCard } from '@components'
import { useAppSelector } from '@core/store'
import { useSelectedCard } from '@deposit/hooks'
import { TopUpAnotherCard } from '@deposit/views'
import type { OtherDepositMethodContentProps } from './TopUp.types'

const OtherDepositMethodContent: React.FC<OtherDepositMethodContentProps> = ({
    enabledMasterPass,
    walletId,
    amount,
    isCustomAmount,
    setOpen,
}) => {
    const { t } = useTranslation()

    const selected = useSelectedCard('masterpass')

    const { mpAccountLoading } = useAppSelector(state => state.depositSlice)

    if ((mpAccountLoading || selected.isLoading) && !selected.card) {
        return <DepositCard.Loader logoSize={32} className="h-14" />
    }

    return (
        <React.Fragment>
            {enabledMasterPass ? (
                <DepositCard.Other
                    title={t(
                        '@deposit.TopUp.other_deposit_method_deposit_card_title',
                    )}
                    avatarIcon="credit-card"
                    onClick={() => setOpen(true)}
                />
            ) : (
                <TopUpAnotherCard
                    walletId={walletId}
                    amount={amount}
                    isCustomAmount={isCustomAmount}
                    setOpen={setOpen}
                />
            )}
        </React.Fragment>
    )
}
export default OtherDepositMethodContent
