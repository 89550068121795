import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import i18n from '@core/translations'
import type { PaymentSummarySectionProps } from './OrderDetail.types'

const PaymentSummarySection: React.FC<PaymentSummarySectionProps> = ({
    paidPrice,
    paidPoint,
    discountPoint,
    currency,
    deliveryType,
    deliveryAmount,
    productPrice,
    deliveryDiscount,
}) => {
    const { t } = useTranslation()

    const formattedProductPrice = React.useMemo(
        () =>
            CurrencyFormatter.format(productPrice, i18n.getLocale(), currency),
        [productPrice, currency],
    )

    const formattedPaidPoints = React.useMemo(
        () => CurrencyFormatter.format(paidPoint, i18n.getLocale(), currency),
        [paidPoint, currency],
    )

    const formattedDeliveryAmount = React.useMemo(
        () =>
            CurrencyFormatter.format(
                deliveryAmount ?? 0,
                i18n.getLocale(),
                currency,
            ),
        [currency, deliveryAmount],
    )

    const formattedPaidPrice = React.useMemo(
        () => CurrencyFormatter.format(paidPrice, i18n.getLocale(), currency),
        [paidPrice, currency],
    )

    const formattedDiscountPoint = React.useMemo(
        () =>
            CurrencyFormatter.format(discountPoint, i18n.getLocale(), currency),
        [discountPoint, currency],
    )

    return (
        <div className="space-y-3">
            <h3 className="text-overline-md text-semantic-content-inkLight">
                {t('@order.OrderDetail.payment_summary_section_title')}
            </h3>
            <div className="space-y-6 divide-y divide-semantic-background-tertiary rounded-[20px] border border-semantic-background-tertiary px-4 py-6">
                <div className="space-y-6">
                    <div className="flex items-center justify-between">
                        <h4 className="text-body-md-r text-semantic-content-inkMedium">
                            {t(
                                '@order.OrderDetail.payment_summary_products_label',
                            )}
                        </h4>

                        <p className="text-body-lg-r text-semantic-content-ink">
                            {formattedProductPrice}
                        </p>
                    </div>

                    {deliveryType === 'address' && !deliveryDiscount && (
                        <div className="flex items-center justify-between">
                            <h4 className="text-body-md-r text-semantic-content-inkMedium">
                                {t(
                                    '@order.OrderDetail.payment_summary_delivery_amount_label',
                                )}
                            </h4>

                            <p className="text-body-lg-r text-semantic-content-ink">
                                {formattedDeliveryAmount}
                            </p>
                        </div>
                    )}

                    {paidPoint > 0 && (
                        <div className="flex items-center justify-between">
                            <h4 className="text-body-md-r text-semantic-content-inkMedium">
                                {t(
                                    '@order.OrderDetail.payment_summary_gift_point_label',
                                )}
                            </h4>

                            <p className="text-body-lg-r text-semantic-content-ink">
                                {formattedPaidPoints}
                            </p>
                        </div>
                    )}

                    {discountPoint > 0 && (
                        <div className="flex items-center justify-between">
                            <h4 className="text-body-md-r text-semantic-content-inkMedium">
                                {t(
                                    '@order.OrderDetail.payment_summary_discount_amount_label',
                                )}
                            </h4>

                            <p className="text-body-lg-r text-semantic-content-success">
                                -{formattedDiscountPoint}
                            </p>
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-between pt-6">
                    <h4 className="text-body-md-b text-semantic-content-inkMedium">
                        {t(
                            '@order.OrderDetail.payment_summary_paid_amount_label',
                        )}
                    </h4>

                    <p className="text-body-lg-b text-semantic-content-ink">
                        {formattedPaidPrice}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default PaymentSummarySection
