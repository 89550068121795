import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { isEmpty, map, slice } from 'lodash'
import { OrderCard, SectionBar, Sheet } from '@components'
import orderApi from '@order/api'
import { OrderUtils } from '@order/utils'
import { OrderDetail } from '@order/views'
import type { OrdersSectionProps } from './WalletDetail.types'

const OrdersSection: React.FC<OrdersSectionProps> = ({ walletId }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [openSheetId, setOpenSheetId] = React.useState<number | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    const orderHistoryListQuery = orderApi.useOrderHistoryListQuery(
        { page: 1, wallet_id: walletId, type: 'recently' },
        { refetchOnMountOrArgChange: true },
    )

    const orderHistory = React.useMemo(() => {
        return orderHistoryListQuery.data?.data ?? []
    }, [orderHistoryListQuery.data?.data])

    const orders = React.useMemo(() => {
        return slice(orderHistory, 0, 2)
    }, [orderHistory])

    if (isEmpty(orders)) return null

    return (
        <div className="container my-10 space-y-10">
            <SectionBar
                title={t('@wallet.WalletDetail.orders_title')}
                description={t('@wallet.WalletDetail.orders_subtitle')}
                LayoutRightComponent
                NavigateLink={() => navigate('/my-orders')}
                LayoutRightComponentProps={{
                    children: t('@wallet.WalletDetail.orders_badge_label_all'),
                }}
            />
            <div className="grid grid-cols-1 gap-10 xl:grid-cols-2">
                {map(orders, (order, index) => (
                    <Sheet
                        key={order.id}
                        open={openSheetId === order.id}
                        onOpenChange={isOpen =>
                            setOpenSheetId(isOpen ? order.id : null)
                        }>
                        <Sheet.Trigger className="w-full" asChild>
                            <div onClick={() => setOpenSheetId(order.id)}>
                                <OrderCard
                                    key={order.id}
                                    data-hidden={index === 1}
                                    title={order.company.name}
                                    status={order.status}
                                    addressTitle={OrderUtils.getAddressTitle(
                                        order,
                                    )}
                                    fullAddress={OrderUtils.getFullAddress(
                                        order,
                                    )}
                                    date={order.created_at}
                                    logo={order.company.logo}
                                    price={order.final_total}
                                    currency={order.company.currency}
                                    images={OrderUtils.prepareOrderImages(
                                        order.products,
                                    )}
                                    className="data-[hidden=true]:hidden xl:data-[hidden=true]:block"
                                />
                            </div>
                        </Sheet.Trigger>

                        <Sheet.Content
                            loading={loading}
                            className="overflow-y-scroll">
                            <OrderDetail
                                orderId={order.id}
                                onLoadingChange={setLoading}
                            />
                        </Sheet.Content>
                    </Sheet>
                ))}
            </div>
        </div>
    )
}
export default OrdersSection
